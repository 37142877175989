import React, { useEffect, useState }   from 'react';
import { createStyles, makeStyles }     from '@material-ui/core';

import CircularProgress                 from '@material-ui/core/CircularProgress';
import { NovaContext }                  from '../../../ContextProviders/Nova/NovaContext';
import PropTypes                        from 'prop-types';
import Typography                       from '@material-ui/core/Typography';
import useNovaPostUsersPosEffect        from '../../../Hooks/useNovaPostUsersPos';
import Button from "@material-ui/core/Button";
import * as novaActions from "../../../ContextProviders/Nova/actions";
import useNovaPostUsersCancelOrderEffect from '../../../Hooks/useNovaPostUsersCancelOrder';

const MapBox = React.lazy(() => import('../../Shared/Mapbox/Mapbox'));

const useStyles = makeStyles(theme => createStyles({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignContent: 'center',
        alignItems: 'center'
    },
    spinnerContainer: {
        display         : 'flex',
        flexFlow        : 'column nowrap',
        justifyContent  : 'center',
        alignItems      : 'center',
        alignContent    : 'center',
        height          : '100%'
    },
    layoutContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyItems: 'center',
        alignItems: 'center',
        [theme.breakpoints.up(800)]: {
            flexFlow: 'row nowrap',
        }
    },
    fixedAgentMapContainer: {
        height: 260,
        width: '100%',
        [theme.breakpoints.up(800)]: {
            order: 2,
            height: 460
        }
    },
    fixedAgentContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyItems: 'center',
        alignItems: 'center',
        padding: '10px',
        //paddingBottom: 26,
        [theme.breakpoints.up(800)]: {
            height: 'auto',
            width: 500,
            justifyContent: 'center',
        }
    },
    subtitleCss: {
        fontSize        : 14,
        fontWeight      : 400,
        width           : 312,
        color           : 'dimgray',
        textAlign       : 'center',
        [theme.breakpoints.up(800)]: {
            width: 340,
        }
    }
}));

const FixedAgentConnectionStep = function FixedAgentConnectionStep({ match }) {

    const {
        root,
        spinnerContainer,
        layoutContainer,
        fixedAgentMapContainer,
        fixedAgentContent,
        subtitleCss
    } = useStyles();

    const {
        state,
        dispatch
    }   = React.useContext(NovaContext);

    const {
        order,
        initialUserPosition,
        userSelectedAddress,
        userSelectedAddressCoords,
        fixedProviderPointsDataLoaded,
        fixedProviderPointsData,
        staticPointsData,
        email,
        userPosCode
    } = state;

    const { params: { UID } }                               = match;
    const { channel_prices, currency, country_code }                      = order;
    const AMOUNT_OF_FIXED_POINTS_TO_RETRIEVE                = 10;
    const [ storeOrder, setStoreOrder ]                     = useState(true);
    const [ showCancelOrderSpinner, setShowCancelOrderSpinner ] = useState(false);
    const txStaticPrice                                     = window.sessionStorage.getItem('txStaticPrice');
    const customerCoords                                    = function customerCoords(selectedAddress, selectedAddressCoords, initialPosition) {
        return selectedAddress && selectedAddressCoords
            ? selectedAddressCoords
            : initialPosition.coords
                ? initialPosition.coords
                : { latitude: 0, longitude: 0};
    };
    const consumerLatitude                                  = function consumerLatitude(selectedAddress, selectedAddressCoords, initialPosition, fallbackLatitude) {
        return selectedAddress && selectedAddressCoords
            ? selectedAddressCoords.latitude
            : initialPosition && initialPosition.coords
                ? initialPosition.coords.latitude
                : fallbackLatitude;
    };
    const consumerLongitude                                 = function consumerLongitude(selectedAddress, selectedAddressCoords, initialPosition, fallbackLongitude) {
        return selectedAddress && selectedAddressCoords
            ? selectedAddressCoords.longitude
            : initialPosition && initialPosition.coords
                ? initialPosition.coords.longitude
                : fallbackLongitude;
    };
    const createArrayOfFixedProviderPoints                  = function createArrayOfFixedProviderPoints(payloadArray) {
        const processData = function processData(payloadArrayData) {
            const dataArray = payloadArrayData.map(function payloadArrayMap({ id, name, points, icon })  {
                const providerName = name;
                return points && points.length > 0
                    ? points.map(function pointsArrayMap({ name, provider, address, latitude, longitude, enabled }) {
                        return {
                            providerId: id,
                            providerName,
                            providerIcon: icon,
                            name: name,
                            provider,
                            address,
                            latitude: Number(latitude),
                            longitude: Number(longitude),
                            enabled
                        };
                    })
                    : [];
            });
            return dataArray.reduce(function dataArrayReduce(accumulator, currentValue) {
                return accumulator.concat(currentValue);
            }, []);
        };

        return payloadArray
            ? processData(payloadArray)
            : [];
    };
    const parseArrayOfStaticPointsData                      = function parseArrayOfStaticPointsData(arrayOfStaticPointsData) {
        return arrayOfStaticPointsData.map(function parseArrayOfStaticPointsDataMap({
                                                                                        additional_comments,
                                                                                        registered_latitude,
                                                                                        registered_longitude,
                                                                                        static_address,
                                                                                        venue_image,
                                                                                        venue_name,
                                                                                        working_schedule
                                                                                    }) {
            return {
                additional_comments,
                latitude    : Number(registered_latitude),
                longitude   : Number(registered_longitude),
                address     : static_address,
                venue_image,
                name        : venue_name,
                working_schedule,
                certified   : true
            };
        });
    };
    const getArrayOfClosestMarkers                          = function getArrayOfClosestMarkers(arrayOfData, { latitude, longitude }, amount) {
        return arrayOfData.map(function arrayOfMarkersWithDistanceValuesMap(pointData) {
            const distance = Math.abs(latitude - pointData.latitude) + Math.abs(longitude - pointData.longitude);
            return {
                distance: distance,
                ...pointData
            }
        }).sort(function arrayOfMarkersWithDistanceValues(elem1, elem2) {
            return elem1.distance - elem2.distance
        }).slice(0, amount);
    };
    const arrayOfStaticPointsData                           = parseArrayOfStaticPointsData(staticPointsData);
    //const providersId                                       = JSON.parse(window.localStorage.getItem('fixedProviderPointsData')) ? JSON.parse(window.localStorage.getItem('fixedProviderPointsData')).providersId : undefined;
    const providersData                                     = JSON.parse(window.localStorage.getItem('fixedProviderPointsData')) ? JSON.parse(window.localStorage.getItem('fixedProviderPointsData')).providersData : undefined;
    const fixedProviders                                    = fixedProviderPointsData && fixedProviderPointsData.length > 0 ? fixedProviderPointsData : providersData;
    const arrayOfFixedProviderPointsData                    = createArrayOfFixedProviderPoints(fixedProviders);
    const arrayOfFixedProviderPointsDataWithDistanceValues  = getArrayOfClosestMarkers(arrayOfFixedProviderPointsData, customerCoords(userSelectedAddress, userSelectedAddressCoords, initialUserPosition), AMOUNT_OF_FIXED_POINTS_TO_RETRIEVE);
    const arrayOfStaticPointsDataWithDistanceValues         = getArrayOfClosestMarkers(arrayOfStaticPointsData, customerCoords(userSelectedAddress, userSelectedAddressCoords, initialUserPosition), AMOUNT_OF_FIXED_POINTS_TO_RETRIEVE);
    const nearestFixedProviders                             = [
        ...arrayOfStaticPointsDataWithDistanceValues,
        ...arrayOfFixedProviderPointsDataWithDistanceValues
    ];
    const getCurrency = function getCurrency(aCurrency) {
        return aCurrency ? aCurrency : window.sessionStorage.getItem('currency');
    };

    const getCountryCode = function getCountryCode(aCountryCode) {
        return aCountryCode ? aCountryCode : window.sessionStorage.getItem('countryCode');
    };

    const handleCancelFixedOrderButton    =   function handleCancelFixedOrderButton(event) {
        event.preventDefault();
        setShowCancelOrderSpinner(true);
        dispatch(novaActions.startNovaPostUsersCancelOrderEffect());
        /*        setTimeout(() => {
                    window.location.href = order.return_url
                }, 5000);*/
    };

    useNovaPostUsersPosEffect(UID);
    useNovaPostUsersCancelOrderEffect(UID);
    useEffect(function storedOrderEffect() {
        const currentStoredOrder = JSON.parse(window.localStorage.getItem('storedOrder'));
        if((storeOrder && currentStoredOrder && Object.keys(currentStoredOrder).length > 0 && currentStoredOrder.id !== order.id && order.id && email) || (storeOrder && !currentStoredOrder)) {
            window.localStorage.setItem('storedOrder', JSON.stringify({ id: order.id, email: email}));
            setStoreOrder(false);
        }
    },[storeOrder, order, email]);

    return(
        <section
            className={root}>
            {
                (fixedProviderPointsDataLoaded || fixedProviders) && !showCancelOrderSpinner
                    ?
                    (
                        <section className={layoutContainer}>
                            <section className={fixedAgentMapContainer}>
                                <MapBox
                                    consumerLatitude={consumerLatitude(userSelectedAddress, userSelectedAddressCoords, initialUserPosition, 67.8653844)}
                                    consumerLongitude={consumerLongitude(userSelectedAddress, userSelectedAddressCoords, initialUserPosition, -73.808737)}
                                    fixedAgentStep
                                    nearestFixedProviders={nearestFixedProviders}
                                />
                            </section>
                            <section className={fixedAgentContent}>
                                <Typography style={{
                                    marginTop       : getCurrency(currency) === 'MXN' ? 16 : 32,
                                    fontSize        : 22,
                                    fontWeight      : 400,
                                    color           : 'dimgray'
                                }}>
                                    Para completar la Transacción
                                </Typography>
                                <Typography className={subtitleCss}>
                                    Reúnete con un Socio46 y muestra el QR:
                                </Typography>
                                <Typography style={{
                                    textTransform   : 'uppercase',
                                    fontSize        : 24,
                                    color           : 'dimgray'
                                }}>
                                    {`${order.currency_symbol ? order.currency_symbol : window.sessionStorage.getItem('currencySymbol') ? window.sessionStorage.getItem('currencySymbol') : ''}${channel_prices ? channel_prices.static : Number(txStaticPrice)}`}
                                </Typography>
                                <img
                                    style={{
                                        textAlign   : 'center',
                                        height      : 200,
                                        width       : getCurrency(currency) === 'MXN' ? 160 : 200
                                    }}
                                    src={`https://us-central1-degrees-46.cloudfunctions.net/qr-generator?UID=${UID}`}
                                    alt='código qr'/>
                                {
                                    getCurrency(currency) === 'ARS' && (
                                        <React.Fragment>
                                            <Typography className={subtitleCss} style={{ marginBottom: 6 }}>
                                                {providersData.length > 0 && (`También puedes pagar en ${providersData.map(provider => provider.name).length > 1 ? providersData.map(provider => provider.name).join(', ') : providersData.map(provider => provider.name)[0]}. `)}
                                                <br/>
                                                Solicita pagar una cuenta de PAGO46 SAS e indica el siguiente código: <strong>{ userPosCode }</strong>
                                            </Typography>
                                        </React.Fragment>
                                    )
                                }

                                {
                                    getCurrency(currency) === 'MXN' && (
                                        <React.Fragment>
                                            <Typography className={subtitleCss} style={{ marginBottom: 6, fontSize: 12 }}>
                                                {providersData.length > 0 && (`También puedes pagar en ${providersData.map(provider => provider.name).length > 1 ? providersData.map(provider => provider.name).join(', ') : providersData.map(provider => provider.name)[0]}. `)}
                                                <br/>
                                            </Typography>
                                            <Typography className={subtitleCss} style={{ marginBottom: 6, fontSize: 12 }}>
                                                {providersData.length > 0 && (`Solo selecciona la opción PAGO46 e ingresa el siguiente código: `)}
                                                {providersData.length > 0 && (<strong>{ userPosCode }</strong>)}
                                                <br />
                                                <strong>Recuerda seguir las instrucciones que te enviamos por email.</strong>
                                            </Typography>
                                            <Typography className={subtitleCss} style={{ marginBottom: 6, fontSize: 12 }}>
                                                {providersData.length > 0 && (<strong>¡IMPORTANTE!</strong>)}
                                                {providersData.length > 0 && (' Recuerda que el pago puede demorar hasta 20 minutos en verse reflejado. Los pagos pueden ser realizados hasta las 22:50, todo pago hecho después de esa hora se procesa a las 8AM')}
                                                <br/>
                                            </Typography>
                                        </React.Fragment>
                                    )
                                }
                                {
                                    getCountryCode(country_code) === 'ECU' && (
                                        <React.Fragment>
                                            <Typography className={subtitleCss} style={{ marginBottom: 6, fontSize: 12 }}>
                                                {providersData.length > 0 && (`También puedes pagar en ${providersData.map(provider => provider.name).length > 1 ? providersData.map(provider => provider.name).join(', ') : providersData.map(provider => provider.name)[0]}. `)}
                                                <br/>
                                            </Typography>
                                            {providersData.length > 0 && (
                                                <ol>
                                                    <li>
                                                        <Typography style={{ marginBottom: 4, fontSize: 12 }}>Acércate a un punto {providersData.map(provider => provider.name).length > 1 ? providersData.map(provider => provider.name).join(', ') : providersData.map(provider => provider.name)[0]}.</Typography>
                                                    </li>
                                                    <li>
                                                        <Typography style={{ marginBottom: 4, fontSize: 12 }}>Indica que deseas PAGAR una RECAUDACION de PAGO46  </Typography>
                                                    </li>
                                                    <li>
                                                        <Typography style={{ marginBottom: 4, fontSize: 12 }}>Proporciona éste código de pago: <strong>{ userPosCode }</strong></Typography>
                                                    </li>
                                                    <li>
                                                        <Typography style={{ marginBottom: 4, fontSize: 12 }}>Realiza el pago y recibe el comprobante de tu transacción  </Typography>
                                                    </li>
                                                </ol>
                                            )}
                                            <Typography className={subtitleCss}>
                                                {providersData.length > 0 && (`También puedes seguir las instrucciones que enviamos a tu correo electrónico.`)}
                                            </Typography>
                                        </React.Fragment>
                                    )
                                }
                                {
                                    getCurrency(currency) !== 'ARS' && getCountryCode(country_code) !== 'ECU' && getCurrency(currency) !== 'MXN' && (
                                        <Typography className={subtitleCss}>
                                            {providersData.length > 0 && (`También puedes pagar en ${providersData.map(provider => provider.name).length > 1 ? providersData.map(provider => provider.name).join(', ') : providersData.map(provider => provider.name)[0]}. `)}
                                            <br />
                                            Sigue las instrucciones que enviamos a tu correo electrónico.
                                        </Typography>
                                    )
                                }
                                <section>
                                    <Button
                                        onClick={handleCancelFixedOrderButton}
                                        style={{
                                            background      : 'crimson',
                                            color           : 'white',
                                            textTransform   : 'uppercase',
                                            width           : '100%',
                                            height          : 36,
                                            margin          : '16px auto'
                                        }}
                                        variant='contained'
                                        fullWidth>
                                        cancelar esta orden
                                    </Button>
                                </section>
                            </section>
                        </section>
                    )
                    :
                    (
                        <div style={{ height: 360 }}>
                            <section className={spinnerContainer}>
                                <CircularProgress
                                    style={{
                                        color: '#06BA54'
                                    }}
                                    size={50}
                                    thickness={3}/>
                            </section>
                        </div>
                    )
            }
        </section>
    );
};

FixedAgentConnectionStep.propTypes = {
    match: PropTypes.object.isRequired
};

export default FixedAgentConnectionStep;
