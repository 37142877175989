import 'core-js/es/map';
import 'core-js/es/set';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import Routes from './Nova/Routes';
import { MuiThemeProvider } from '@material-ui/core/styles';
import NovaProvider from './Nova/ContextProviders/Nova/NovaContext';
import theme from './configureTheme';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Sentry from '@sentry/browser';
//import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module'
import { SENTRY_DNS } from './constants'

if(process.env.REACT_APP_BUILD === 'production') {
    const tagManagerArgs = {
        gtmId: 'GTM-5WJVTS6'
    }

    TagManager.initialize(tagManagerArgs);
}

const Loader = ({color}) => (
    <CircularProgress
        style={{
            color: color ? color : 'black'
        }}
        size={50}
        thickness={3}/>
);

if(window.location.hostname !== 'localhost') {
    Sentry.init({
        dsn: SENTRY_DNS,
        environment: process.env.NODE_ENV
    });
}

ReactDOM.render(
    <Suspense fallback={<Loader color='#06BA54'/>}>
        <MuiThemeProvider theme={theme}>
            <NovaProvider>
                <Routes />
            </NovaProvider>
        </MuiThemeProvider>
    </Suspense>,
document.getElementById('root'));
//serviceWorker.unregister();
console.log('v.2.1.4');
