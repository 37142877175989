import React from 'react';
import novaReducer from './reducers/NovaReducer';
import { state as initialState } from './reducers/NovaReducer'
export const NovaContext = React.createContext(initialState );

export default function NovaProvider({ children }){
    const [state, dispatch] = React.useReducer(novaReducer, initialState);
    const value = {state, dispatch};

    return(
        <NovaContext.Provider value={value}>
            { children }
        </NovaContext.Provider>
    );
};