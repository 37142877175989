import React, { useState }  from 'react';
import PropTypes            from 'prop-types';
import TextField            from '@material-ui/core/TextField';
import Typography           from '@material-ui/core/Typography';
import Button               from '@material-ui/core/Button';
import logo46               from '../../../../assets/images/logo_FULL.svg';
import validate             from 'uuid-validate';
import Paper                from '@material-ui/core/Paper/index';
import Fade                 from '@material-ui/core/Fade';

import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => createStyles({
    logoStyles: {
        margin: '0 0 24px 0',
        height: 120,
        width: '100%'
    }
}));

const NoUID = function NoUID({ history }) {

    const { logoStyles }                            = useStyles();
    const [ uid, setUid ]                           = useState(undefined);
    const [ uidError, setUidError ]                 = useState(false);
    const [ uidErrorMessage, setUidErrorMessage]    = useState(undefined);

    const handleOnChangeUidInput = function handleOnChangeUidInput(value) {
        return validate(value)
            ?
                (
                    setUidError(false),
                    setUidErrorMessage(undefined),
                    setUid(value)
                )
            :
                (
                    setUid(undefined),
                    setUidError(true),
                    setUidErrorMessage('Debes ingresar un número de orden válido')
                )
    };

    return(
        <Fade in={true}>
            <Paper
                elevation={6}
                style={{
                    background: 'white',
                    padding: '24px 0 16px 0',
                    maxWidth: 420,
                    margin: '0 auto'
                }}>
                <form
                    style={{
                        display: 'flex',
                        flexFlow: 'column nowrap',
                        alignItems: 'center'

                    }}
                    noValidate
                    autoComplete='off'>
                    <img
                        className={logoStyles}
                        src={logo46}
                        alt='Número de Orden'/>
                    <Typography
                        style={{
                            marginTop: 8,
                            fontSize: 16,
                            width: '80%'
                        }}>
                        Ingresa el número de orden:
                    </Typography>
                    <TextField
                        error={uidError}
                        style={{
                            marginTop: 16,
                            width: '80%'
                        }}
                        required
                        label='Número de Orden'
                        margin='normal'
                        variant='outlined'
                        onChange={event => {
                            event.preventDefault();
                            return handleOnChangeUidInput(event.target.value);
                        }}
                    />
                    <Typography style={{
                        color       : 'red',
                        fontFamily  : 'Roboto',
                        fontSize    : 14,
                        height      : 20,
                        width       : '80%'
                    }}>{ uidErrorMessage }</Typography>
                    <Button
                        onClick={event => {
                            event.preventDefault();
                            return history.push(`/${uid}`)
                        }}
                        color='primary'
                        disabled={!uid}
                        variant='contained'
                        style={{
                            margin: '16px 0px 16px 0',
                            color: 'white',
                            width: '80%',

                        }}>
                        Continuar
                    </Button>
                </form>
            </Paper>
        </Fade>
    );
};

NoUID.propTypes = {
    history: PropTypes.object.isRequired
};

export default NoUID;