// Base url
export const NOVA_SERVER_URL = process.env.REACT_APP_NOVA_SERVER_URL || '';
export const FIRESTORE_COLLECTION = process.env.REACT_APP_FIRESTORE_COLLECTION || '';

// Sentry API DNS
export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS || '';

// Google map API key
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY  || '';

// Firebase API
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY || '';
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '';
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID || '';
