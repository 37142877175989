import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import * as novaActions from '../ContextProviders/Nova/actions';
import { NOVA_SERVER_URL } from '../../constants'

export default function useNovaGetUsersOrderEffect(UID) {
    const {
        state,
        dispatch
    } = useContext(NovaContext);
    const {
        selfpay,
        startNovaGetUsersOrderEffect,
        firestoreDb
    } = state;
    useEffect(function novaGetUsersOrderEffect() {
        function dispatchActions(response) {
            const { status, order, statusText } = response;
            const actions = {
                200: function handle200UsersOrderRequestResponse() {
                    //console.log('200 handle200UsersOrderRequestResponse: ', response);
                    dispatch(novaActions.endNovaGetUsersOrderEffect());
                    dispatch(novaActions.setOrderToState(order));
                    //console.log('email: ', order.email);
                    if(order.email) {
                        dispatch(novaActions.setUserEmail(order.email));
                        dispatch(novaActions.setIncludedUserEmailFlow());
                        dispatch(novaActions.startNovaPostUnverifiedLoginEffect(order.email))
                    } else {
                        dispatch(novaActions.showLoginForm());
                    }
                },
                400: function handle400UsersOrderRequestResponse() {
                    dispatch(novaActions.setLoginFormErrorState({ statusText }));
                    dispatch(novaActions.endNovaGetUsersOrderEffect());
                },
                401: function handle401UsersOrdersRequestResponse() {
                    dispatch(novaActions.endNovaGetUsersOrderEffect());
                    dispatch(novaActions.setInvalidEmailAssociatedToTheOrder('La orden de compra ya se encuentra asignada a otro correo electrónico, por favor vuelva a ingresar con el correo asignado.'));
                }
            };
            return (typeof actions[status] == 'function')
                ?   actions[status]()
                :   console.log('Unable to continue running Nova from novaUsersOrderEffect(): ', response);
        }

        const usersOrderRequest = async function usersOrderRequest() {
            const usersOrderRequestResponse = await fetch(`${NOVA_SERVER_URL}/users/order/${UID}`, {
                method  : 'GET',
                headers : {
                    'Content-Type': 'application/json'
                }
            });
            const { status } = usersOrderRequestResponse;
            return status === 200
                ?   usersOrderRequestResponse.json()
                :   usersOrderRequestResponse;
        };
        if (startNovaGetUsersOrderEffect) usersOrderRequest()
            .then(response => {
                const { status } = response;
                return (typeof status === 'string')
                    ? dispatchActions({ status: 200, order: { ...response }})
                    : dispatchActions(response);
            });
    }, [
        selfpay,
        startNovaGetUsersOrderEffect,
        dispatch,
        firestoreDb,
        UID
    ]);
}