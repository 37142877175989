import React, { useEffect }             from 'react';
import PropTypes                        from 'prop-types';
import UserLocationStep                 from './UserLocationStep';
import { makeStyles, createStyles }     from '@material-ui/core';
import { NovaContext }                  from '../../../ContextProviders/Nova/NovaContext';
import Paper                            from '@material-ui/core/Paper';
import * as novaActions                 from '../../../ContextProviders/Nova/actions';

const OrderStatusWatcher    =   React.lazy(() => import('../../Shared/OrderStatusWatcher/OrderStatusWatcher'));
const HeadStepper           =   React.lazy(() => import('../../Shared/Stepper/Stepper'));

const useStyles = makeStyles(theme => createStyles({
    root: {
        maxWidth: 480,
        margin: '0 auto',
        [theme.breakpoints.up(800)]: {
            maxWidth: 800,
        }
    }
}));

const UserLocationStepContainer = function UserLocationStepContainer({ history, match }) {
    const { root } = useStyles();

    const {
        state,
        dispatch
    }   = React.useContext(NovaContext);

    const {
        userAddress,
        showSwitchSpinner,
        includedUserEmailFlow,
        initialUserPositionSuccess,
        initialUserPositionError,
        isSwitchChecked,
        disableSwitch,
        order
    } = state;

    const { params: { UID } } = match;
    const { merchant, country_code } = order;

    const handleLocationButton = function handleLocationButton() {
        dispatch(novaActions.startNovaPatchOrdersLocationEffect());
        return history.push(`/${UID}/agentSelection`);
    };
    const handleSwitchControl = function handleSwitchControl(event) {
        event.preventDefault();
        const handleGetUserInitialPositionSuccess = function getuserInitialPositionSuccess(position) {
            dispatch(novaActions.setUserInitialPosition(position));
            dispatch(novaActions.setMapboxMapZoom(14));
            if(country_code ? (country_code === 'ARG' || country_code === 'ECU') : window.sessionStorage.getItem('countryCode') && (window.sessionStorage.getItem('countryCode') === 'ARG' || window.sessionStorage.getItem('countryCode') === 'ECU')) {
                dispatch(novaActions.startGmapGeocoderEffect());
            } else {
                dispatch(novaActions.startMapboxReverseGeocoderRequest());
            }
            return dispatch(novaActions.startNovaGetMulticajaPointsEffect());
        };
        const handleGetUserInitialPositionError = function getuserInitialPositionError(error) {
            return dispatch(novaActions.setUserInitialPositionError(error));
        };
        dispatch(novaActions.toggleSwitchSpinner());
        return navigator.geolocation.getCurrentPosition(handleGetUserInitialPositionSuccess, handleGetUserInitialPositionError, {
            enableHighAccuracy: true,
            maximumAge        : 30000,
            timeout           : 27000
        });
    };

    useEffect(function redirectToQrCodeWhenEmptyAllowedNetworks() {
        if(merchant && (merchant.allowed_networks && merchant.allowed_networks.length === 0)) {
            history.push(`/${UID}/qr-code`);
        }
    }, [merchant, history, UID]);

    return(
        <Paper
            className={root}
            elevation={6}>
            <OrderStatusWatcher
                UID={UID}
                history={history}>
                <HeadStepper
                    currentStep={1}/>
                <UserLocationStep
                    handleSwitchControl={handleSwitchControl}
                    handleLocationButton={handleLocationButton}
                    userAddress={userAddress}
                    switchSpinnerMessage='Obteniendo Ubicación...'
                    switchSuccessAccessMessage='Ubicación obtenida, gracias!'
                    switchErrorAccessMessage='No es posible acceder a tu ubicación. Por favor vuelve a darnos acceso desde tu navegador.'
                    switchAccessMessage='Permitir acceso a mí ubicación'
                    showSwitchAccess={includedUserEmailFlow}
                    showSwitchSpinner={showSwitchSpinner}
                    initialUserPositionSuccess={initialUserPositionSuccess}
                    initialUserPositionError={initialUserPositionError}
                    isSwitchChecked={isSwitchChecked}
                    disableSwitch={disableSwitch}
                />
            </OrderStatusWatcher>
        </Paper>
    );
};

UserLocationStepContainer.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};

export default UserLocationStepContainer;