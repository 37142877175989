import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import agentIcon from '../../../../assets/images/agentIcon.svg';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => createStyles({
    root: {
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignContent: 'center',
        alignItems: 'center',
    },
    layoutContainer: {
        width: '100%',
        height: '100%',
    },
    formAgentSelectionContainerStyles: {
        width: '80%',
        margin: '4% 0 4% 0'
    },
    formContainerStyles: {
        position: 'relative',
        height: '88%',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyItems: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    title: {
        fontSize: 20,
        margin: '2% 0 2% 0'
    },
    text: {
        fontSize: 16,
        margin: '0% 0 6% 0',
        fontWeight: 300,
        width: '84%'
    },
    agentDescription: {
        margin: '2% 0',
        width:'100%',
        color: 'rgba(0, 0, 0, 0.6)',
        textAlign: 'left'
    },
    searchAgentButton: {
        color: 'white',
        width: '80%',
        margin: '32px 0'
    },
    agentIconStyle: {
        width: 128,
        height: 128,
        marginTop: '6%'
    },
    agentDescriptionContainer: {
        width: '80%',
        textAlign: 'left'
    },
    agentDescriptionTitleStyles: {
        color: 'black'
    },
    agentDescriptionTextStyles: {
        color: 'gray'
    },
    agentRetributionTitleStyles: {
        color: 'black'
    },
    agentRetributionTextStyles: {
        color: 'gray'
    },
    descriptionContainer: {
        minHeight: 64
    },
    retributionContainer: {
        marginTop: '2%'
    },
    spinnerContainerStyles: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        justifyItems: 'center'
    }
}));

const AgentSelectionStep = function AgentSelectionStep({
    handleSearchAgentButton,
    handleOnChangeSelector,
    handleCancelSearchAgentButton,
    mainButtonText,
    agentIconAltText,
    titleText,
    subtitleText,
    labelText,
    fixedAgentTextSelection,
    deliveryAgentTextSelection,
    fixedAgentDescriptionText,
    fixedAgentRetributionAmont,
    deliveryAgentDescriptionText,
    deliveryAgentRetributionAmount,
    selectedAgentType,
    showAgentSelectionSpinner,
    spinnerButtonText,
    spinnerText,
    price,
    disableButton
    }) {

    const {
        root,
        layoutContainer,
        formAgentSelectionContainerStyles,
        formContainerStyles,
        title,
        text,
        searchAgentButton,
        agentIconStyle,
        agentDescriptionContainer,
        agentDescriptionTitleStyles,
        agentDescriptionTextStyles,
        agentRetributionTitleStyles,
        agentRetributionTextStyles,
        retributionContainer,
        descriptionContainer,
        spinnerContainerStyles
    } = useStyles();

    return(
        <section className={root}>
            {
                disableButton ?
                        (
                            <section style={{
                                display         : 'flex',
                                flexFlow        : 'column nowrap',
                                justifyContent  : 'center',
                                alignItems      : 'center',
                                alignContent    : 'center',
                                minHeight          : 640
                            }}>
                                <CircularProgress
                                    style={{
                                        color: '#06BA54'
                                    }}
                                    size={50}
                                    thickness={3}/>
                            </section>
                        )
                    :
                        (
                            <div className={layoutContainer}>
                                {
                                    showAgentSelectionSpinner
                                        ?
                                        (
                                            <section className={spinnerContainerStyles}>
                                                <CircularProgress
                                                    style={{
                                                        color: '#06BA54',
                                                        marginTop: '50%',
                                                        marginBottom: '4%'
                                                    }}
                                                    size={100}
                                                    thickness={2}
                                                />
                                                <Typography style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 16}}>
                                                    { spinnerText }
                                                </Typography>
                                                <Button
                                                    onClick={handleCancelSearchAgentButton}
                                                    //className={searchAgentButton}
                                                    style={{color: 'white', marginTop: '50%'}}
                                                    variant='contained'
                                                    color='primary'>
                                                    { spinnerButtonText }
                                                </Button>
                                            </section>
                                        )
                                        :
                                        (
                                            <section className={formContainerStyles}>
                                                <img
                                                    className={agentIconStyle}
                                                    src={agentIcon}
                                                    alt={agentIconAltText}/>
                                                <Typography
                                                    color='textPrimary'
                                                    //variant='h1'
                                                    className={title}>
                                                    { titleText }
                                                </Typography>
                                                <Typography
                                                    color='textPrimary'
                                                    //variant='body1'
                                                    className={text}>
                                                    { subtitleText }
                                                </Typography>
                                                <FormControl variant='outlined' className={formAgentSelectionContainerStyles}>
                                                    <InputLabel
                                                        htmlFor='agent-type'>
                                                        { labelText }
                                                    </InputLabel>
                                                    <Select
                                                        native
                                                        value={selectedAgentType}
                                                        onChange={handleOnChangeSelector}
                                                        input={
                                                            <OutlinedInput
                                                                name='agent'
                                                                labelWidth={110}
                                                                id='agent-type'
                                                            />
                                                        }>
                                                        <option value='default'>{fixedAgentTextSelection}</option>
                                                        <option value='delivery'>{deliveryAgentTextSelection}</option>
                                                    </Select>
                                                </FormControl>
                                                {
                                                    selectedAgentType === 'default'
                                                        ?
                                                        (
                                                            <div className={agentDescriptionContainer}>
                                                                <div className={descriptionContainer}>
                                                                    <Typography
                                                                        className={agentDescriptionTitleStyles}>
                                                                        Descripción:
                                                                    </Typography>
                                                                    <Typography
                                                                        className={agentDescriptionTextStyles}>
                                                                        { fixedAgentDescriptionText }
                                                                    </Typography>
                                                                </div>
                                                                <div className={retributionContainer}>
                                                                    <Typography
                                                                        className={agentRetributionTitleStyles}>
                                                                        Comisión:
                                                                    </Typography>
                                                                    <Typography
                                                                        className={agentRetributionTextStyles}>
                                                                        { fixedAgentRetributionAmont - price}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        )
                                                        : null
                                                }
                                                {
                                                    selectedAgentType === 'delivery'
                                                        ?
                                                        (
                                                            <div className={agentDescriptionContainer}>
                                                                <div className={descriptionContainer}>
                                                                    <Typography
                                                                        className={agentDescriptionTitleStyles}>
                                                                        Descripción:
                                                                    </Typography>
                                                                    <Typography
                                                                        className={agentDescriptionTextStyles}>
                                                                        { deliveryAgentDescriptionText }
                                                                    </Typography>
                                                                </div>
                                                                <div className={retributionContainer}>
                                                                    <Typography
                                                                        className={agentRetributionTitleStyles}>
                                                                        Comisión:
                                                                    </Typography>
                                                                    <Typography
                                                                        className={agentRetributionTextStyles}>
                                                                        { deliveryAgentRetributionAmount - price }
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        )
                                                        : null
                                                }
                                                <div style={{ width: '100%' }}>
                                                    <Button
                                                        onClick={handleSearchAgentButton}
                                                        className={searchAgentButton}
                                                        variant='contained'
                                                        color='primary'
                                                        disabled={disableButton}>
                                                        { mainButtonText }
                                                    </Button>
                                                </div>
                                            </section>
                                        )
                                }
                            </div>
                        )
            }
        </section>
    );
};

AgentSelectionStep.propTypes = {
    handleSearchAgentButton                 : PropTypes.func.isRequired,
    mainButtonText                          : PropTypes.string.isRequired,
    agentIconAltText                        : PropTypes.string.isRequired,
    titleText                               : PropTypes.string.isRequired,
    subtitleText                            : PropTypes.string.isRequired,
    labelText                               : PropTypes.string.isRequired,
    fixedAgentTextSelection                 : PropTypes.string.isRequired,
    deliveryAgentTextSelection              : PropTypes.string.isRequired,
    fixedAgentDescriptionText               : PropTypes.string.isRequired,
    fixedAgentRetributionAmont              : PropTypes.number.isRequired,
    deliveryAgentDescriptionText            : PropTypes.string.isRequired,
    deliveryAgentRetributionAmount          : PropTypes.number.isRequired,
    selectedAgentType                       : PropTypes.string.isRequired,
    price                                   : PropTypes.number.isRequired
};

export default AgentSelectionStep;
