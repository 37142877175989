import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import * as novaActions from '../ContextProviders/Nova/actions';
import { NOVA_SERVER_URL } from '../../constants'

export default function useNovaOrdersSelfpayEffect(UID) {
    const {
        state,
        dispatch
    } = useContext(NovaContext);
    const {
        requestToken,
        startNovaPostOrdersSelfpayEffect
    } = state;
    useEffect(function novaOrdersSelfpayEffect() {
        function dispatchActions(response) {
            const { status } = response;
            const actions = {
                201: function handle200UsersOrderRequestResponse() {
                    //console.log('handle200UsersOrderRequestResponse: ', response);
                    dispatch(novaActions.setSelfpayNotificationScreen());
                    dispatch(novaActions.endNovaPostOrdersSelfpayEffect());
                }
            };
            return (typeof actions[status] == 'function')
                ?   actions[status]()
                :   console.log('Unable to continue running Nova from novaOrdersSelfpayEffect(): ', response);
        }

        const authToken = function authToken(token) {
            return `Token ${token}`
        };

        const ordersSelfpayRequest = async function ordersSelfpayRequest() {
            const ordersSelfpayRequestResponse = await fetch(`${NOVA_SERVER_URL}/agents/orders/self_pay/`, {
                method  : 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': `${authToken(requestToken)}`
                },
                body: JSON.stringify({
                    order_id: UID
                })
            });
            const { status } = ordersSelfpayRequestResponse;
            return status === 200
                ?   ordersSelfpayRequestResponse.json()
                :   ordersSelfpayRequestResponse;
        };
        if (startNovaPostOrdersSelfpayEffect && requestToken) ordersSelfpayRequest()
            .then(response => {
                const { status } = response;
                return (typeof status === 'string')
                    ? dispatchActions({ status: 200, order: { ...response }})
                    : dispatchActions(response);
            });
    }, [
        UID,
        requestToken,
        startNovaPostOrdersSelfpayEffect,
        dispatch
    ]);
}