import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';

const useStyles = makeStyles(theme => createStyles({
    root: {
        width: '100%'
    },
    bullet: {
        height: 64,
        width: 64,
        color: 'white'
    },
    bulletList: {
        listStyle: 'none'
    },
    toolbar: {
        display: 'flex',
        flexFlow: 'column nowrap'
    },
    connectorActive: {
        '& $connectorLine': {
            borderColor: theme.palette.secondary.main
        }
    },
    connectorCompleted: {
        '& $connectorLine': {
            borderColor: theme.palette.primary.main,
        }
    },
    connectorDisabled: {
        '& $connectorLine': {
            borderColor: theme.palette.grey[100]
        }
    },
    connectorLine: {
        transition: theme.transitions.create('border-color')
    }
}));

const iconThemeOverride = createMuiTheme({
    palette: {
        primary: {
            main: '#06BA54'
        }
    },
    overrides: {
        MuiStepIcon: {
            text: {
                fill: 'white'
            }
        }
    }
});

const HeadStepper = function HeadStepper({ currentStep }) {

    const {
        root,
        toolbar,
        connectorActive,
        connectorCompleted,
        connectorDisabled,
        connectorLine
    } = useStyles();

    return (
        <section className={root}>
            <AppBar
                position='relative'
                color='secondary'>
                <Toolbar
                    classes={{
                        root: toolbar
                    }}>
                    <MuiThemeProvider theme={iconThemeOverride}>
                        <Stepper
                            activeStep={currentStep}
                            style={{width: '80%'}}
                            connector={<StepConnector
                                classes={{
                                    active: connectorActive,
                                    completed: connectorCompleted,
                                    disabled: connectorDisabled,
                                    line: connectorLine
                                }}
                            />}>
                            <Step>
                                <StepLabel>

                                </StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>

                                </StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>

                                </StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>

                                </StepLabel>
                            </Step>
                        </Stepper>
                    </MuiThemeProvider>
                </Toolbar>
            </AppBar>
        </section>
    );
};

HeadStepper.propTypes = {
    currentStep: PropTypes.number.isRequired,
};

export default HeadStepper;