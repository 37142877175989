import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import * as novaActions from '../ContextProviders/Nova/actions';
import { NOVA_SERVER_URL } from '../../constants'

export default function useNovaUsersRegistrationEffect() {
    const {
        state,
        dispatch
    } = useContext(NovaContext);
    const {
        email,
        startNovaPostUsersRegistrationEffect
    } = state;
    useEffect(function novaUsersRegistrationEffect() {
        function dispatchActions(response){
            const { status, statusText } = response;
            const actions = {
                201: function handle201UsersRegistionRequestResponse() {
                    dispatch(novaActions.endNovaPostRegistrationEffect());
                    dispatch(novaActions.startNovaPostUnverifiedLoginEffect(email));
                },
                400: function handle400UsersRegistrationRequestResponse() {
                    dispatch(novaActions.setLoginFormErrorState({ statusText }));
                    dispatch(novaActions.endNovaPostRegistrationEffect());
                }
            };
            return (typeof actions[status] == 'function')
                ?   actions[status]()
                :   console.log('Unable to continue running Nova from novaUsersRegistrationEffect(): ', response);
        }
        const usersRegistrationRequest = async function usersRegistrationRequest() {
            const usersRegistrationRequestResponse = await fetch(`${NOVA_SERVER_URL}/users/registration/`, {
                method  : 'POST',
                headers : {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: email , verified: false })
            });
            const { status } = usersRegistrationRequestResponse;
            return status === 200
                ?   usersRegistrationRequestResponse.json()
                :   usersRegistrationRequestResponse;
        };
        if (startNovaPostUsersRegistrationEffect) usersRegistrationRequest()
            .then(response => {
                const { status } = response;
                return status
                    ? dispatchActions(response)
                    : dispatchActions({ status: 200, ...response });
            });
    }, [email, startNovaPostUsersRegistrationEffect, dispatch]);
}