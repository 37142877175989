import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import * as novaActions from '../ContextProviders/Nova/actions';
import { NOVA_SERVER_URL } from '../../constants'

export default function useNovaPostUsersRetryOrderEffect(UID) {
    const {
        state,
        dispatch
    } = useContext(NovaContext);
    const {
        requestToken,
        startNovaPostUsersRetryOrderEffect,
        //initialUserPosition
    } = state;
    useEffect(function novaPostUsersRetryOrderEffect() {
        function dispatchActions(response) {
            const { status } = response;
            //console.log('novaPostUsersRetryOrderEffect respose final: ', response);
            const actions = {
                200: function handle200UsersRetryOrderRequestResponse() {
                    dispatch(novaActions.endNovaPostUsersRetryOrderEffect());
                    dispatch(novaActions.startNovaPostUsersOrdersEffect());
                    //console.log('201 RETRY ORDER RESPONSE: ', response);
                },
                410: function handle410UsersRetryOrderRequestResponse() {
                    //dispatch(novaActions.endNovaPostUsersOrdersEffect());
                    //console.log('RETRY ORDER 410: ', response);
                },
                400: function handle400UsersRetryOrderRequestResponse() {
                    //dispatch(novaActions.endNovaPostUsersOrdersEffect());
                    //console.log('RETRY ORDER 400: ', response);
                }
            };
            return (typeof actions[status] == 'function')
                ?   actions[status]()
                :   console.log('Unable to continue running Nova from novaPostUsersRetryOrderEffect(): ', response);
        }

        const authToken = function authToken(token) {
            return `Token ${token}`
        };

        const usersRetryOrderRequest = async function usersRetryOrderRequest() {
            const usersRetryOrderRequestResponse = await fetch(`${NOVA_SERVER_URL}/users/retry-order/`, {
                method  : 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': `${authToken(requestToken)}`
                },
                body: JSON.stringify({
                    order_id: UID
                })
            });
            const { status } = usersRetryOrderRequestResponse;
            return status === 200
                ?   usersRetryOrderRequestResponse.json()
                :   usersRetryOrderRequestResponse;
        };
        if (startNovaPostUsersRetryOrderEffect && requestToken) usersRetryOrderRequest()
            .then(response => {
                const { status } = response;
                return (typeof status === 'undefined')
                    ? dispatchActions({ status: 200, response } )
                    : dispatchActions(response);
            });
    }, [
        requestToken,
        startNovaPostUsersRetryOrderEffect,
        dispatch,
        UID
    ]);
}