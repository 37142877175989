import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import * as novaActions from '../ContextProviders/Nova/actions';
import {
    NOVA_SERVER_URL
} from '../../constants'

export default function useNovaGetUsersNearbyEffect(UID) {
    const {
        state,
        dispatch
    } = useContext(NovaContext);
    const {
        requestToken,
        startNovaGetUsersNearbyEffect,
        userSelectedAddress,
        userSelectedAddressCoords,
        initialUserPosition
    } = state;

    useEffect(function novaGetUsersNearbyEffect() {

        const RADIUS = 1;

/*        const consumerLatitude      =   userSelectedAddress && userSelectedAddressCoords
            ? userSelectedAddressCoords.latitude
            : initialUserPosition && initialUserPosition.coords
                ? initialUserPosition.coords.latitude
                : undefined;
        const consumerLongitude     =   userSelectedAddress && userSelectedAddressCoords
            ? userSelectedAddressCoords.longitude
            : initialUserPosition && initialUserPosition.coords
                ? initialUserPosition.coords.longitude
                : undefined;*/

        const consumerLatitude = function consumerLatitude(selectedAddress, selectedAddressCoords, initialPosition, fallbackLatitude) {
            return selectedAddress && selectedAddressCoords
                ? selectedAddressCoords.latitude
                : initialPosition && initialPosition.coords
                    ? initialPosition.coords.latitude
                    : fallbackLatitude;
        };

        const consumerLongitude = function consumerLongitude(selectedAddress, selectedAddressCoords, initialPosition, fallbackLongitude) {
            return selectedAddress && selectedAddressCoords
                ? selectedAddressCoords.longitude
                : initialPosition && initialPosition.coords
                    ? initialPosition.coords.longitude
                    : fallbackLongitude;
        };

        const authToken = function authToken(token) {
            return `Token ${token}`
        };

        function dispatchActions(response){
            const { status, delivery } = response;
            const actions = {
                200: function handle200novaGetUsersNearbyRequestResponse() {
                    dispatch(novaActions.setDeliveryAgentAvailability(delivery));
                    dispatch(novaActions.setStaticPointsData(response.static))
                }
            };
            return (typeof actions[status] == 'function')
                ?   actions[status]()
                :   console.log('Unable to continue running Nova from novaGetUsersNearbyEffect(): ', response);
        }

        //console.log('URL: ', `/users/nearby?latitude=${consumerLatitude}&longitude=${consumerLongitude}&radius=${RADIUS}&order_id=${UID}/`);
        const novaGetUsersNearbyRequest = async function novaGetUsersNearbyRequest() {
            const novaGetUsersNearbyRequestResponse = await fetch(`${NOVA_SERVER_URL}/users/nearby?latitude=${consumerLatitude(userSelectedAddress, userSelectedAddressCoords, initialUserPosition, -33.410525799999995)}&longitude=${consumerLongitude(userSelectedAddress, userSelectedAddressCoords, initialUserPosition, -70.541938)}&radius=${RADIUS}&order_id=${UID}`, {
                method  : 'GET',
                headers : {
                    'Authorization': `${authToken(requestToken)}`,
                    'Content-Type': 'application/json'
                },
            });
            const { status } = novaGetUsersNearbyRequestResponse;
            return (status === 200)
                ?   novaGetUsersNearbyRequestResponse.json()
                :   novaGetUsersNearbyRequestResponse;
        };
        if (startNovaGetUsersNearbyEffect && requestToken) novaGetUsersNearbyRequest()
            .then(response => {
                //console.log('novaGetUsersNearbyRequest: ', response);
                const { status } = response;
                return status
                    ? dispatchActions(response)
                    : dispatchActions({ status: 200, ...response });
            });
    }, [
        requestToken,
        startNovaGetUsersNearbyEffect,
        dispatch,
        UID,
        userSelectedAddress,
        userSelectedAddressCoords,
        initialUserPosition
    ]);
}