import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import * as novaActions from '../ContextProviders/Nova/actions';
import { NOVA_SERVER_URL } from '../../constants'

export default function useNovaAuthLoginEffect() {
    const {
        state,
        dispatch
    } = useContext(NovaContext);

    const {
        email,
        password,
        startNovaPostAuthLoginEffect
    } = state;
    useEffect(function novaAuthLoginEffect() {
        function dispatchActions(response){
            //console.log('useNovaAuthLoginEffect : ', response);
            const { status, key } = response;
            const actions = {
                200: function handle200AuthLoginRequestResponse() {
                    //console.log('useNovaAuthLoginEffect 200 RESPONSE: ', response);
                    dispatch(novaActions.setSessionCookie(key));
                    dispatch(novaActions.endNovaPostAuthLoginEffect());
                    dispatch(novaActions.startNovaGetAuthUserEffect());
                },
                400: function handle400AuthLoginRequestResponse() {
                    //console.log('useNovaAuthLoginEffect 400 RESPONSE: ', response);
                    dispatch(novaActions.setLoginFormErrorState(response));
                    dispatch(novaActions.endNovaPostAuthLoginEffect());
                }
            };
            return (typeof actions[status] == 'function')
                ?   actions[status]()
                :   console.log('Unable to continue running Nova from novaAuthLoginEffect(): ', response)
        }
        const authLoginRequest = async function authLoginRequest() {
            const authLoginRequestResponse = await fetch(`${NOVA_SERVER_URL}/auth/login/`, {
                method  : 'POST',
                headers : {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            });
            const { status }    = authLoginRequestResponse;
            return (status === 200) || (status === 400)
                ?   authLoginRequestResponse.json()
                :   authLoginRequestResponse;
        };
        if (startNovaPostAuthLoginEffect) authLoginRequest()
            .then(response => {
                const { key, non_field_errors } = response;
                //console.log('authLoginRequest response: ', response);
                return key
                    ? dispatchActions({
                        status: 200,
                        ...response
                    })
                    : non_field_errors
                        ? dispatchActions({
                            status: 400,
                            ...response
                        })
                        : dispatchActions(response);
            });
    }, [email, password, startNovaPostAuthLoginEffect, dispatch]);
}