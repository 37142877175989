import React                            from 'react';
import PropTypes                        from 'prop-types';
import Paper                            from '@material-ui/core/Paper/index';
import { makeStyles, createStyles }     from '@material-ui/core';
import { NovaContext }                  from '../../../ContextProviders/Nova/NovaContext';
import * as novaActions                 from '../../../ContextProviders/Nova/actions';

import CircularProgress                 from '@material-ui/core/CircularProgress';

import useNovaAuthLoginEffect           from '../../../Hooks/useNovaPostAuthLogin';
import useNovaUnverifiedLoginEffect     from '../../../Hooks/useNovaPostUnverifiedLogin';
import useNovaUsersRegistrationEffect   from '../../../Hooks/useNovaPostUsersRegistration';
import useNovaAuthUserEffect            from '../../../Hooks/useNovaGetAuthUser';
import useNovaOrdersSelfpayEffect       from '../../../Hooks/useNovaPostOrdersSelfpay';
import useNovaUsersOrderEffect          from '../../../Hooks/useNovaGetUsersOrder';
import useNovaHistoryEffect             from '../../../Hooks/useNovaHistory';

import logo                             from '../../../../assets/images/logo_FULL.svg';

const LoginForm             =   React.lazy(() => import('./LoginFormStep'));
const HeadStepper           =   React.lazy(() => import('../../Shared/Stepper/Stepper'));
const OrderStatusWatcher    =   React.lazy(() => import('../../Shared/OrderStatusWatcher/OrderStatusWatcher'));

const useStyles = makeStyles(() => createStyles({
    root: {
        maxWidth    : 420,
        margin      : '0 auto'
    },
    spinnerContainer: {
        display         : 'flex',
        flexFlow        : 'column nowrap',
        justifyContent  : 'center',
        alignItems      : 'center',
        alignContent    : 'center',
        minHeight       : 512
    }
}));

export const LoginFormStepContainer = function LoginFormStepContainer({history, match}) {
    const {
        root,
        spinnerContainer
    } = useStyles();

    const {
        state,
        dispatch
    }   = React.useContext(NovaContext);

    const {
        email,
        emailErrorInput,
        emailErrorMsg,
        showAccountSelector,
        showEmailInput,
        showPasswordInput,
        showGeolocationSwitch,
        isSwitchChecked,
        showSwitchSpinner,
        disableSwitch,
        showLoginButton,
        initialUserPositionSuccess,
        initialUserPositionError,
        disableAgentLoginButton,
        showLoader,
        password,
        loginFormError,
        loginFormErrorMessage,
        showLoginForm,
        showSelfpayNotificationScreen,
        order,
        firestoreOrder: {
            status,
            merchant
        }
    } = state;

    const { params: { UID } } = match;
    const { currency }        = order;

    const allowCustomerWithoutGeolocationAccess = function allowCustomerWithoutGeolocationAccess(aMerchant) {
        // return (aMerchant === 'Rappi') || (aMerchant === 'TESTNOLOCATION') || (aMerchant === 'RappiArgentina');
        return true
    };

    const setLoginButtonAvailability = function setLoginButtonAvailability(userEmail, userInitialPositionSuccess, accessWithoutGeolocation) {
        return accessWithoutGeolocation
            ?   !userEmail
            :   !(userEmail && userInitialPositionSuccess);
    };

    useNovaUnverifiedLoginEffect();
    useNovaAuthLoginEffect();
    useNovaOrdersSelfpayEffect(UID);
    useNovaUsersRegistrationEffect();
    useNovaAuthUserEffect(UID);
    useNovaUsersOrderEffect(UID);
    useNovaHistoryEffect(history, UID);

    const handleLoginButton                 = function handleLoginButton(event) {
        event.preventDefault();
        return dispatch(novaActions.startNovaPostUnverifiedLoginEffect(email));
    };
    const handleLoginAgentButton            = function handleLoginAgentButton(event) {
        event.preventDefault();
        //console.log('handleLoginAgentButton: ');
        return dispatch(novaActions.startNovaPostAuthLoginEffect(email, password));
    };
    const checkAllowedEmail = function checkAllowedEmail(email) {
        const storedOrder = JSON.parse(window.localStorage.getItem('storedOrder'));
        if(storedOrder && storedOrder.id === order.id) {
            return (storedOrder.email === email)
                ? history.push(`/${UID}/fixedAgentConnection`)
                : dispatch(novaActions.setUserEmailError('La orden de compra ya se encuentra asignada a otro correo electrónico, por favor vuelva a ingresar con el correo asignado.'));
        } else {
            return dispatch(novaActions.setUserEmail(email));
        }
    };
    const handleEmailInput                  = function handleEmailInput(event) {
        //console.log('handleEmailInput: ');
        event.preventDefault();
        const { target: {value}} = event;
        const emailRegExp = /^(([^<>()\]\\.,;:\s@']+(\.[^<>()\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailValidation = emailRegExp.test(String(value).toLocaleLowerCase());

        return (value.length === 0)
            ?   dispatch(novaActions.clearEmailErrorState())
            :   emailValidation
                ?   checkAllowedEmail(value)
                :   dispatch(novaActions.setUserEmailError('Recuerda ingresar una dirección de correo válida'));
    };
    const handleSwitchControl               = function handleSwitchControl(event) {
        event.preventDefault();
        const handleGetUserInitialPositionSuccess = function getuserInitialPositionSuccess(position) {
            dispatch(novaActions.setUserInitialPosition(position));
            dispatch(novaActions.setMapboxMapZoom(14));
            return dispatch(novaActions.startNovaGetMulticajaPointsEffect());
        };
        const handleGetUserInitialPositionError = function getuserInitialPositionError(error) {
            return dispatch(novaActions.setUserInitialPositionError(error));
        };
        dispatch(novaActions.toggleSwitchSpinner());
        return navigator.geolocation.getCurrentPosition(handleGetUserInitialPositionSuccess, handleGetUserInitialPositionError);
    };
    const handleAccountSelectorChange       = function handleAccountSelectorChange(event) {
        event.preventDefault();
        return dispatch(novaActions.setLoginScreen());
    };
    const handleLoginPasswordInputChange    = function handleLoginPasswordInputChange(event) {
        event.preventDefault();
        const {target: {value}} = event;
        return value.length > 4
            ? dispatch(novaActions.setAgentLoginPassword(value))
            : null;
    };
    const handleSelfpayButton               = function handleSelfpayButton(event) {
        event.preventDefault();
        dispatch(novaActions.startNovaPostUsersOrdersEffect());
        return dispatch(novaActions.startNovaPostAuthLoginEffect(email, password, true));
    };

    const getCurrency = function getCurrency(aCurrency) {
        return aCurrency ? aCurrency : window.sessionStorage.getItem('currency');
    };

    return(
        <Paper
            className={root}
            elevation={6}>
            <OrderStatusWatcher
                UID={UID}
                history={history}>
                {
                    (status === 'merchant_notified') || (status === 'completed' || showSelfpayNotificationScreen || !showLoginForm)
                        ? null
                        :
                        (
                            <HeadStepper
                                currentStep={0}/>
                        )
                }
                {
                    showLoader
                        ?
                        (
                            <section className={spinnerContainer}>
                                <CircularProgress
                                    style={{
                                        color: '#06BA54'
                                    }}
                                    size={50}
                                    thickness={3}/>
                            </section>
                        )
                        :   null
                }
                {

                    showLoginForm && !showLoader
                        ?
                        (
                            <React.Fragment>
                                <LoginForm
                                    handleEmailInput={handleEmailInput}
                                    handleLoginButton={handleLoginButton}
                                    handleSelfpayButton={handleSelfpayButton}
                                    handleSwitchControl={handleSwitchControl}
                                    handleAccountSelectorChange={handleAccountSelectorChange}
                                    handleLoginAgentButton={handleLoginAgentButton}
                                    handleLoginPasswordInputChange={handleLoginPasswordInputChange}
                                    switchSpinnerMessage='Obteniendo Ubicación...'
                                    switchSuccessAccessMessage='Ubicación obtenida, gracias!'
                                    switchErrorAccessMessage='No es posible acceder a tu ubicación. Por favor vuelve a darnos acceso desde tu navegador.'
                                    switchAccessMessage='Permitir acceso a mí ubicación'
                                    logoImageSource={logo}
                                    logoImageAlt='Logo Pago46'
                                    loginTitle='Bienvenido a PAGO46'
                                    loginText='Ingresa tus datos para entrar al sistema'
                                    loginButtonText='continuar'
                                    emailInputLabel='Ingresar Email'
                                    emailInputPlaceholder='Ejemplo: tucorreo@gmail.com'
                                    anotherAccountSelectionOptionText='...Cambiar Correo Electrónico'
                                    passwordInputLabel='Ingresar Contraseña'
                                    payWithAgentButtonText='Pagar con Socio46'
                                    payWithWalletButtonText='Pagar con wallet'
                                    email={email}
                                    emailErrorInput={emailErrorInput}
                                    emailErrorMsg={emailErrorMsg}
                                    showAccountSelector={showAccountSelector}
                                    showEmailInput={showEmailInput}
                                    showPasswordInput={showPasswordInput}
                                    allowCustomerWithoutGeolocationAccess={allowCustomerWithoutGeolocationAccess(merchant)}
                                    showGeolocationSwitch={allowCustomerWithoutGeolocationAccess(merchant) ? !allowCustomerWithoutGeolocationAccess(merchant) : showGeolocationSwitch}
                                    isSwitchChecked={isSwitchChecked}
                                    showSwitchSpinner={showSwitchSpinner}
                                    disableSwitch={disableSwitch}
                                    showLoginButton={showLoginButton}
                                    initialUserPositionSuccess={initialUserPositionSuccess}
                                    initialUserPositionError={initialUserPositionError}
                                    disableAgentLoginButton={disableAgentLoginButton}
                                    loginFormError={loginFormError}
                                    loginFormErrorMessage={loginFormErrorMessage}
                                    merchant={merchant}
                                    switchSelfpaymentMessage={getCurrency(currency) === 'MXN' ? 'Realizar este pago con mi app de PAGO46' : 'Realizar este pago con mi billetera de PAGO46'}
                                    setLoginButtonAvailability={setLoginButtonAvailability(email, initialUserPositionSuccess, allowCustomerWithoutGeolocationAccess(merchant))}
                                />
                            </React.Fragment>
                        )
                        : null
                }
            </OrderStatusWatcher>
        </Paper>
    );
};

LoginFormStepContainer.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

export default LoginFormStepContainer;
