import {
    SET_USER_EMAIL,
    SET_USER_EMAIL_ERROR,
    CLEAR_EMAIL_ERROR_STATE,
    SHOW_AGENT_LOGIN_SCREEN,
    TOGGLE_AGENT_LOGIN_SCREEN,
    SET_USER_INITIAL_POSITION,
    SET_USER_INITIAL_POSITION_ERROR,
    SET_GOOGLE_MAPS_API_TO_STATE,
    TOGGLE_SWITCH_SPINNER,
    SET_LOGIN_SCREEN,
    SET_AGENT_LOGIN_PASSWORD,
    SET_USER_ADDRESS,
    SET_USER_ADDRESS_ERROR,
    SET_SELECTED_AGENT_TYPE,
    TOGGLE_SPINNER,
    SET_USER_ID,
    SET_FIRESTORE_ORDER_DATA,
    SET_SELFPAY_NOTIFICATION_SCREEN,
    START_NOVA_POST_AUTH_LOGIN_EFFECT,
    SET_SUCCESSFUL_ORDER_STATE,
    SET_ERROR_ORDER_STATE,
    SET_AWAITING_USER_PAYMENT_ORDER_STATE,
    SET_AWAITING_ASSIGNMENT_ORDER_STATE,

    SET_FIXED_PROVIDER_POINTS_DATA,

    SET_MULTICAJA_DATA,
    SET_MULTICAJA_MARKERS,
    SET_STATIC_MARKERS,
    SET_CUSTOMER_MARKER,
    SET_DELIVERY_AGENT_AVAILABILITY,
    TOGGLE_AGENT_CONNECTION_DRAWER,
    OPEN_CHAT_DRAWER,
    CLOSE_CHAT_DRAWER,
    SET_CHAT_SNAPSHOTS,
    SET_USER_ADDRESS_DPTO_NUMBER,
    SET_AGENT_PROFILE,
    SET_AGENT_RATING,
    CLEAR_STATE,
    SET_ORDER_TO_STATE,
    SET_INVALID_EMAIL_ASSOCIATED_TO_THE_ORDER,
    SHOW_LOGIN_FORM,
    HIDE_LOGIN_FORM,
    SET_INCLUDED_USER_EMAIL_FLOW,
    SET_MAPBOX_MAP_ZOOM,
    SET_EMAIL_NOT_ALLOWED_TO_CONTINUE,
    SET_USER_POS_CODE,



    START_NOVA_CANCEL_ORDER_PROCESS,
    END_NOVA_CANCEL_ORDER_PROCESS,
    START_NOVA_RETRY_ORDER_PROCESS,
    END_NOVA_RETRY_ORDER_PROCESS,




    END_NOVA_POST_AUTH_LOGIN_EFFECT,
    START_NOVA_POST_UNVERIFIED_LOGIN,
    END_NOVA_POST_UNVERIFIED_LOGIN,
    SET_LOGIN_FORM_ERROR_STATE,
    START_NOVA_POST_USERS_REGISTRATION_EFFECT,
    END_NOVA_POST_USERS_REGISTRATION_EFFECT,
    SET_SESSION_COOKIE,
    START_NOVA_GET_AUTH_USER_EFFECT,
    END_NOVA_GET_AUTH_USER_EFFECT,
    START_NOVA_GET_USERS_ORDER_EFFECT,
    END_NOVA_GET_USERS_ORDER_EFFECT,
    START_NOVA_HISTORY_EFFECT,
    END_NOVA_HISTORY_EFFECT,
    START_NOVA_POST_ORDERS_SELFPAY_EFFECT,
    END_NOVA_POST_ORDERS_SELFPAY_EFFECT,
    START_NOVA_GET_MULTICAJA_POINTS_EFFECT,
    END_NOVA_GET_MULTICAJA_POINTS_EFFECT,
    START_NOVA_POST_USERS_ORDERS_EFFECT,
    END_NOVA_POST_USERS_ORDERS_EFFECT,
    START_NOVA_POST_USERS_POS_EFFECT,
    END_NOVA_POST_USERS_POS_EFFECT,
    START_NOVA_POST_USERS_CANCEL_ORDER_EFFECT,
    END_NOVA_POST_USERS_CANCEL_ORDER_EFFECT,
    START_NOVA_POST_USERS_RETRY_ORDER_EFFECT,
    END_NOVA_POST_USERS_RETRY_ORDER_EFFECT,
    START_NOVA_POST_DELIVERY_INTENT_EFFECT,
    END_NOVA_POST_DELIVERY_INTENT_EFFECT,

    START_NOVA_GET_USERS_NEARBY_EFFECT,
    END_NOVA_GET_USERS_NEARBY_EFFECT,
    START_NOVA_GET_PROVIDER_POINTS_EFFECT,
    END_NOVA_GET_PROVIDER_POINTS_EFFECT,
    START_NOVA_PATCH_USERS_ORDERS_ORDERID_RATE,
    END_NOVA_PATCH_USERS_ORDERS_ORDERID_RATE,
    START_NOVA_PATCH_ORDERS_LOCATION_EFFECT,
    END_NOVA_PATCH_ORDERS_LOCATION_EFFECT,
    START_MAPBOX_REVERSE_GEOCODER_REQUEST,
    END_MAPBOX_REVERSE_GEOCODER_REQUEST,
    START_NOVA_UPDATE_GOOGLE_AUTOCOMPLETE_VALUE,
    END_NOVA_UPDATE_GOOGLE_AUTOCOMPLETE_VALUE,
    START_NOVA_UPDATE_CUSTOMER_PIN_COORDS,
    END_NOVA_UPDATE_CUSTOMER_PIN_COORDS,
    START_GMAPS_GEOCODER_EFFECT,
    END_GMAPS_GEOCODER_EFFECT,
    TOGGLE_SEARCH_PROVIDER

} from '../actions/types';

import firebase from 'firebase/app';
import 'firebase/firestore';
import {
    FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID
} from '../../../../constants'

firebase.initializeApp({
    apiKey      : FIREBASE_API_KEY,
    authDomain  : FIREBASE_AUTH_DOMAIN,
    projectId   : FIREBASE_PROJECT_ID
});

export const state = {
    isSearchingProvider                             : false,
    currentStep                                     : 0,
    currency                                        : window.sessionStorage.getItem('currency') ? window.sessionStorage.getItem('currency') : undefined,
    gmap                                            : undefined,
    email                                           : undefined,
    emailErrorInput                                 : false,
    emailErrorMsg                                   : undefined,
    showEmailInput                                  : true,
    showAccountSelector                             : false,
    showPasswordInput                               : false,
    showGeolocationSwitch                           : true,
    geolocationAccess                               : false,
    initialUserPosition                             : window.sessionStorage.getItem('initialUserPosition') ? JSON.parse(window.sessionStorage.getItem('initialUserPosition')) : {
        coords: undefined
    },
    initialUserPositionError                        : false,
    isSwitchChecked                                 : false,
    showSwitchSpinner                               : false,
    disableSwitch                                   : false,
    initialUserPositionErrorCode                    : undefined,
    initialUserPositionErrorMessage                 : undefined,
    showLoginButton                                 : true,
    initialUserPositionSuccess                      : false,
    password                                        : undefined,
    disableAgentLoginButton                         : true,
    showLocationForm                                : false,
    showStillSearchingButton                        : false,
    userAddress                                     : window.sessionStorage.getItem('userAddress') ? window.sessionStorage.getItem('userAddress') : 'Obteniendo dirección...',
    userSelectedAddress                             : !!window.sessionStorage.getItem('userSelectedAddressCoords'),
    userSelectedAddressCoords                       : window.sessionStorage.getItem('userSelectedAddressCoords') ? JSON.parse(window.sessionStorage.getItem('userSelectedAddressCoords')) : {},
    userAddressError                                : false,
    selectedAgentType                               : 'default',
    showLoader                                      : false,
    loginFormError                                  : false,
    requestToken                                    : window.sessionStorage.getItem('requestToken') ? window.sessionStorage.getItem('requestToken') : undefined,
    userLogged                                      : false,
    order                                           : {},
    firestoreOrder                                  : {},
    historyDestination                              : undefined,
    showSelfpayNotificationScreen                   : false,
    showLoginForm                                   : false,
    fixedProviderPointsDataLoaded                   : !!window.sessionStorage.getItem('fixedProviderPointsData'),
    fixedProviderPointsData                         : window.sessionStorage.getItem('fixedProviderPointsData') ? JSON.parse(window.sessionStorage.getItem('fixedProviderPointsData')) : [],
    multicajaDataLoaded                             : !!window.sessionStorage.getItem('multicajaData'),
    multicajaData                                   : window.sessionStorage.getItem('multicajaData') ? JSON.parse(window.sessionStorage.getItem('multicajaData')) : {},
    multicajaMarkers                                : window.sessionStorage.getItem('multicajaData') ? JSON.parse(window.sessionStorage.getItem('multicajaData')) : {},
    staticPointsData                                : window.sessionStorage.getItem('staticPointsData') ? JSON.parse(window.sessionStorage.getItem('staticPointsData')) : [],
    customerMarker                                  : {},
    deliveryAgentsAmount                            : undefined,
    isDeliveryAgentAvailable                        : undefined,
    showDeliveryAgentConnection                     : false,
    showCancelOrderWarningMessage                   : false,
    showRetryOrderMessage                           : false,
    showDeliveryConnectionLoader                    : true,
    showCancelOrderWarningLoader                    : false,
    isDrawerOpen                                    : false,
    agentProfile                                    : undefined,
    agentRating                                     : undefined,
    showRating                                      : true,
    showRatingLoader                                : false,
    showRatingDone                                  : false,
    wrongEmailErrorMessage                          : undefined,
    includedUserEmailFlow                           : false,
    mapboxMapZoom                                   : 4,
    mapboxInitialMapZoomDone                        : false,
    emailNotAllowedToContinue                       : false,
    userPosCode                                     : window.sessionStorage.getItem('userPosCode') ? window.sessionStorage.getItem('userPosCode') : '',






    startNovaFirestoreDocEffect                     : true,
    startNovaGetMulticajaPointsEffect               : true,
    startNovaPostUsersRegistrationEffect            : false,
    startNovaGetAuthUserEffect                      : false,
    startNovaMerchantIntentEffect                   : false,
    startNovaPostAuthLoginEffect                    : false,
    startNovaGetUsersOrderEffect                    : true,
    startNovaHistoryEffect                          : false,
    startNovaPostUnverifiedLoginEffect              : false,
    startNovaPostOrdersSelfpayEffect                : false,
    startNovaPostUsersOrdersEffect                  : false,
    startNovaConsumerUpdatePositionProviderEffect   : true,
    startNovaFirestoreDocUpdatedValuesEffect        : true,
    startNovaPostUsersPostEffect                    : false,
    startNovaPostUsersCancelOrderEffect             : false,
    startNovaPostUsersRetryOrderEffect              : false,
    startNovaPostUsersPosEffect                     : true,
    startNovaPostDeliverIntentEffect                : false,
    startNovaGetUsersNearbyEffect                   : true,
    startNovaGetProviderPointsEffect                : true,
    startNovaPatchUsersOrdersOrderIdRate            : false,
    startNovaPatchOrdersLocationEffect              : false,
    startMapboxReverseGeocoderRequest               : true,
    startNovaUpdateGoogleAutocompleteValue          : false,
    startNovaUpdateCustomerPinCoords                : false,
    startGmapGeocoderEffect                         : true,
    firestoreDb                                     : firebase.firestore()
};

const novaReducer = (
    state, action) => {
    const {
        type,
        selfpay,
        email,
        emailErrorMsg,
        initialUserPosition,
        initialUserPositionErrorCode,
        initialUserPositionErrorMessage,
        gmap,
        password,
        userAddress,
        userSelectedAddressCoords,
        userAddressError,
        selectedAgentType,
        loginFormErrorMessage,
        requestToken,
        userId,
        firestoreOrder,
        historyDestination,
        orderStatus,
        order,
        fixedProviderPointsData,
        multicajaData,
        multicajaMarkers,
        staticPointsData,
        customerMarker,
        deliveryAgentsAmount,
        isDeliveryAgentAvailable,
        chatSnapshots,
        userDptoNumber,
        agentProfile,
        agentRating,
        mapboxMapZoom,
        emailNotAllowedToContinue,
        emailNotAllowedToContinueMessage,
        userPosCode,
        isSearchingProvider
    } = action;
    switch(type) {
        case TOGGLE_SEARCH_PROVIDER:
            return {
                ...state,
                isSearchingProvider     : isSearchingProvider
            }
        case SET_USER_EMAIL:
            return {
                ...state,
                email,
                emailErrorInput         : false,
                emailErrorMsg           : undefined
            };
        case SET_USER_EMAIL_ERROR:
            return {
                ...state,
                emailErrorMsg,
                emailErrorInput     : true,
                email               : undefined
            };
        case TOGGLE_AGENT_LOGIN_SCREEN:
            return {
                ...state,
                showEmailInput              : !state.showEmailInput,
                showGeolocationSwitch       : false,
                showPasswordInput           : !state.showPasswordInput,
                showAccountSelector         : !state.showAccountSelector,
                showLoginButton             : !state.showLoginButton
            };
        case SHOW_AGENT_LOGIN_SCREEN:
            return {
                ...state,
                showEmailInput              : false,
                showGeolocationSwitch       : false,
                showPasswordInput           : true,
                showAccountSelector         : true,
                showLoginButton             : false,
                showLoginForm               : true
            };
        case TOGGLE_SWITCH_SPINNER:
            return {
                ...state,
                showSwitchSpinner   : !state.showSwitchSpinner,
                disableSwitch       : !state.disableSwitch
            };
        case CLEAR_EMAIL_ERROR_STATE:
            return {
                ...state,
                email                       : undefined,
                emailErrorInput             : false,
                emailErrorMsg               : undefined
            };
        case SET_USER_INITIAL_POSITION:
            return {
                ...state,
                initialUserPosition,
                initialUserPositionSuccess      : true,
                showSwitchSpinner               : false,
                isSwitchChecked                 : true,
                disableSwitch                   : false

            };
        case SET_USER_INITIAL_POSITION_ERROR:
            return {
                ...state,
                initialUserPositionErrorCode,
                initialUserPositionErrorMessage,
                initialUserPositionError    : true,
                isSwitchChecked             : false,
                showSwitchSpinner           : false,
                disableSwitch               : false,
                initialUserPositionSuccess  : false
            };
        case SET_GOOGLE_MAPS_API_TO_STATE:
            return {
                ...state,
                gmap
            };
        case SET_LOGIN_SCREEN:
            return {
                ...state,
                email                               : undefined,
                emailErrorInput                     : false,
                emailErrorMsg                       : undefined,
                showEmailInput                      : true,
                showAccountSelector                 : false,
                showPasswordInput                   : false,
                showGeolocationSwitch               : true,
                initialUserPosition                 : undefined,
                initialUserPositionError            : false,
                isSwitchChecked                     : false,
                showSwitchSpinner                   : false,
                disableSwitch                       : false,
                initialUserPositionErrorCode        : undefined,
                initialUserPositionErrorMessage     : undefined,
                showLoginButton                     : true,
                initialUserPositionSuccess          : false,
                startNovaPostAuthLoginEffect        : false,
                requestToken                        : undefined,
                userLogged                          : false,
                password                            : undefined
            };
        case SET_AGENT_LOGIN_PASSWORD:
            return {
                ...state,
                password,
                disableAgentLoginButton: false
            };
        case SET_USER_ADDRESS:
            return {
                ...state,
                userAddress,
                userSelectedAddress : true,
                userSelectedAddressCoords
            };
        case SET_USER_ADDRESS_ERROR:
            return {
                ...state,
                userAddressError
            };
        case SET_SELECTED_AGENT_TYPE:
            return {
                ...state,
                selectedAgentType
            };
        case TOGGLE_SPINNER:
            //console.log('TOGGLE_SPINNER: ');
            return {
                ...state,
                showLoader: !state.showLoader
            };
        case SET_USER_ID:
            return {
                ...state,
                userId
            };
        case SET_FIRESTORE_ORDER_DATA:
            return {
                ...state,
                firestoreOrder: {
                    ...firestoreOrder
                }
            };
        case SET_SESSION_COOKIE:
            return {
                ...state,
                requestToken,
                userLogged  : true
            };
        case SET_LOGIN_FORM_ERROR_STATE:
            return {
                ...state,
                loginFormError                  : true,
                startNovaPostAuthLoginEffect    : false,
                loginFormErrorMessage
            };
        case SET_SELFPAY_NOTIFICATION_SCREEN:
            return {
                ...state,
                showSelfpayNotificationScreen   : true,
                showLoginForm: false
            };

        case SET_SUCCESSFUL_ORDER_STATE:
            return {
                ...state,
                currentStep                     : 4,
                successfulOrderState            : true,
                showLoader                      : false,
                showLoginForm                   : false
            };
        case SET_ERROR_ORDER_STATE:
            return {
                ...state,
                errorOrderState : true,
                showLoader      : false,
                showLoginForm   : false,
                orderStatus
            };
        case SET_AWAITING_USER_PAYMENT_ORDER_STATE:
            return {
                ...state,
                awaitingUserPaymentOrder    : true,
                order
            };
        case SET_AWAITING_ASSIGNMENT_ORDER_STATE:
            return {
                ...state,
                awaitingAssignmentOrder : true,
                order
            };
        case SET_FIXED_PROVIDER_POINTS_DATA:
            return {
                ...state,
                fixedProviderPointsData,
                fixedProviderPointsDataLoaded   : true
            };
        case SET_MULTICAJA_DATA:
            return {
                ...state,
                multicajaData,
                multicajaDataLoaded : true
            };
        case SET_MULTICAJA_MARKERS:
            return {
                ...state,
                multicajaMarkers
            };
        case SET_STATIC_MARKERS:
            return {
                ...state,
                staticPointsData
            };
        case SET_CUSTOMER_MARKER:
            return {
                ...state,
                customerMarker
            };
        case SET_DELIVERY_AGENT_AVAILABILITY:
            return {
                ...state,
                deliveryAgentsAmount,
                isDeliveryAgentAvailable
            };
        case TOGGLE_AGENT_CONNECTION_DRAWER:
            return {
                ...state,
                isDrawerOpen: !state.isDrawerOpen
            };
        case OPEN_CHAT_DRAWER:
            return {
                ...state,
                isDrawerOpen    : true
            };
        case CLOSE_CHAT_DRAWER:
            return {
                ...state,
                isDrawerOpen    : false
            };
        case SET_CHAT_SNAPSHOTS:
            return {
                ...state,
                chatSnapshots
            };
        case SET_USER_ADDRESS_DPTO_NUMBER:
            return {
                ...state,
                userDptoNumber
            };
        case SET_AGENT_PROFILE:
            return {
                ...state,
                agentProfile
            };
        case SET_AGENT_RATING:
            return {
                ...state,
                agentRating
            };
        case CLEAR_STATE:
            return {
                currentStep                                     : 0,
                gmap                                            : undefined,
                email                                           : undefined,
                emailErrorInput                                 : false,
                emailErrorMsg                                   : undefined,
                showEmailInput                                  : true,
                showAccountSelector                             : false,
                showPasswordInput                               : false,
                showGeolocationSwitch                           : true,
                geolocationAccess                               : false,
                initialUserPosition                             : {},
                initialUserPositionError                        : false,
                isSwitchChecked                                 : false,
                showSwitchSpinner                               : false,
                disableSwitch                                   : false,
                initialUserPositionErrorCode                    : undefined,
                initialUserPositionErrorMessage                 : undefined,
                showLoginButton                                 : true,
                initialUserPositionSuccess                      : false,
                password                                        : undefined,
                disableAgentLoginButton                         : true,
                showLocationForm                                : false,
                showStillSearchingButton                        : false,
                userAddress                                     : 'Obteniendo dirección...',
                userSelectedAddress                             : false,
                userSelectedAddressCoords                       : {},
                userAddressError                                : false,
                selectedAgentType                               : 'default',
                showLoader                                      : false,
                loginFormError                                  : false,
                requestToken                                    : undefined,
                userLogged                                      : false,
                order                                           : {},
                firestoreOrder                                  : {},
                historyDestination                              : undefined,
                showSelfpayNotificationScreen                   : false,
                showLoginForm                                   : false,
                fixedProviderPointsDataLoaded                   : false,
                fixedProviderPointsData                         : [],
                multicajaDataLoaded                             : false,
                multicajaData                                   : {},
                multicajaMarkers                                : [],
                staticPointsData                                   : [],
                customerMarker                                  : {},
                deliveryAgentsAmount                            : undefined,
                isDeliveryAgentAvailable                        : undefined,
                showDeliveryAgentConnection                     : false,
                showCancelOrderWarningMessage                   : false,
                showRetryOrderMessage                           : false,
                showDeliveryConnectionLoader                    : true,
                showCancelOrderWarningLoader                    : false,
                isDrawerOpen                                    : false,
                agentProfile                                    : undefined,
                agentRating                                     : undefined,
                showRating                                      : true,
                showRatingLoader                                : false,
                showRatingDone                                  : false,
                includedUserEmailFlow                           : false,
                mapboxMapZoom                                   : 4,
                mapboxInitialMapZoomDone                        : false,
                userPosCode                                     : '',
                startNovaFirestoreDocEffect                     : true,
                startNovaGetMulticajaPointsEffect               : true,
                startNovaPostUsersRegistrationEffect            : false,
                startNovaGetAuthUserEffect                      : false,
                startNovaMerchantIntentEffect                   : false,
                startNovaPostAuthLoginEffect                    : false,
                startNovaGetUsersOrderEffect                    : true,
                startNovaHistoryEffect                          : false,
                startNovaPostUnverifiedLoginEffect              : false,
                startNovaPostOrdersSelfpayEffect                : false,
                startNovaPostUsersOrdersEffect                  : false,
                startNovaConsumerUpdatePositionProviderEffect   : true,
                startNovaFirestoreDocUpdatedValuesEffect        : true,
                startNovaPostUsersPostEffect                    : false,
                startNovaPostUsersCancelOrderEffect             : false,
                startNovaPostUsersRetryOrderEffect              : false,
                startNovaPostUsersPosEffect                     : true,
                startNovaPostDeliveryIntentEffect               : false,
                startNovaGetUsersNearbyEffect                   : true,
                startNovaGetProviderPointsEffect                : true,
                startNovaPatchUsersOrdersOrderIdRate            : false,
                startNovaPatchOrdersLocationEffect              : false,
                startMapboxReverseGeocoderRequest               : true,
                startNovaUpdateGoogleAutocompleteValue          : false,
                startNovaUpdateCustomerPinCoords                : false,
                startGmapGeocoderEffect                         : true,
                firestoreDb                                     : firebase.firestore()
            };
        case SET_ORDER_TO_STATE:
            return {
                ...state,
                order
            };
        case SET_INVALID_EMAIL_ASSOCIATED_TO_THE_ORDER:
            return {
                ...state,
                showLoader              : false,
                showLoginForm           : true,
                emailErrorInput         : true,
                email                   : undefined,
                showGeolocationSwitch   : false,
                emailErrorMsg
            };
        case SHOW_LOGIN_FORM:
            return {
                ...state,
                showLoginForm: true
            };
        case HIDE_LOGIN_FORM:
            return {
                ...state,
                showLoginForm: false
            };
        case SET_INCLUDED_USER_EMAIL_FLOW:
            return {
                ...state,
                includedUserEmailFlow: true
            };
        case SET_MAPBOX_MAP_ZOOM:
            return {
                ...state,
                mapboxMapZoom
            };
        case SET_EMAIL_NOT_ALLOWED_TO_CONTINUE:
            return {
                ...state,
                email,
                emailNotAllowedToContinue,
                emailNotAllowedToContinueMessage
            };
        case SET_USER_POS_CODE:
            return {
                ...state,
                userPosCode
            };





        case START_NOVA_POST_AUTH_LOGIN_EFFECT:
            return {
                ...state,
                email,
                password,
                selfpay,
                startNovaPostAuthLoginEffect    : true,
                showLoader                      : true
            };
        case END_NOVA_POST_AUTH_LOGIN_EFFECT:
            return {
                ...state,
                startNovaPostAuthLoginEffect    : false,
                showLoader                      : false
            };
        case START_NOVA_POST_UNVERIFIED_LOGIN:
            return {
                ...state,
                startNovaPostUnverifiedLoginEffect  : true,
                showLoader                          : true
            };
        case END_NOVA_POST_UNVERIFIED_LOGIN:
            return {
                ...state,
                startNovaPostUnverifiedLoginEffect  : false,
                showLoader                          : false
            };
        case START_NOVA_POST_USERS_REGISTRATION_EFFECT: {
            return {
                ...state,
                startNovaPostUsersRegistrationEffect    : true,
                showLoader                              : true
            };
        }
        case END_NOVA_POST_USERS_REGISTRATION_EFFECT:
            return {
                ...state,
                startNovaPostUsersRegistrationEffect    : false,
                showLoader                              : false
            };
        case START_NOVA_POST_DELIVERY_INTENT_EFFECT:
            return {
                ...state,
                startNovaPostDeliveryIntentEffect: true
            };
        case END_NOVA_POST_DELIVERY_INTENT_EFFECT:
            return {
                ...state,
                startNovaPostDeliveryIntentEffect: false
            };
        case START_NOVA_GET_AUTH_USER_EFFECT:
            return {
                ...state,
                startNovaGetAuthUserEffect      : true,
                showLoader                      : true,
            };
        case END_NOVA_GET_AUTH_USER_EFFECT:
            return {
                ...state,
                startNovaGetAuthUserEffect      : false,
                showLoader                      : false
            };
        case START_NOVA_GET_USERS_ORDER_EFFECT:
            return {
                ...state,
                startNovaGetUsersOrderEffect    : true,
                showLoader                      : true
            };
        case END_NOVA_GET_USERS_ORDER_EFFECT:
            return {
                ...state,
                startNovaGetUsersOrderEffect    : false,
                showLoader                      : false
            };
        case START_NOVA_HISTORY_EFFECT:
            return {
                ...state,
                historyDestination,
                startNovaHistoryEffect      : true
            };
        case END_NOVA_HISTORY_EFFECT:
            return {
                ...state,
                startNovaHistoryEffect      : false,
                historyDestination          : undefined
            };
        case START_NOVA_POST_ORDERS_SELFPAY_EFFECT:
            return {
                ...state,
                startNovaPostOrdersSelfpayEffect    : true,
                showLoader                          : true
            };
        case END_NOVA_POST_ORDERS_SELFPAY_EFFECT:
            return {
                ...state,
                startNovaPostOrdersSelfpayEffect    : false,
                showLoader                          : false
            };
        case START_NOVA_GET_MULTICAJA_POINTS_EFFECT:
            return {
                ...state,
                startNovaGetMulticajaPointsEffect   : true
            };
        case END_NOVA_GET_MULTICAJA_POINTS_EFFECT:
            return {
                ...state,
                startNovaGetMulticajaPointsEffect   : false
            };
        case START_NOVA_POST_USERS_ORDERS_EFFECT:
            return {
                ...state,
                startNovaPostUsersOrdersEffect  : true,
                showDeliveryConnectionLoader    : true,
                showDeliveryAgentConnection     : false
            };
        case END_NOVA_POST_USERS_ORDERS_EFFECT:
            return {
                ...state,
                startNovaPostUsersOrdersEffect  : false,
                showDeliveryConnectionLoader    : false,
                showDeliveryAgentConnection     : true
            };
        case START_NOVA_POST_USERS_POS_EFFECT:
            return {
                ...state,
                startNovaPostUsersPosEffect : true
            };
        case END_NOVA_POST_USERS_POS_EFFECT:
            return {
                ...state,
                startNovaPostUsersPosEffect : false
            };
        case START_NOVA_POST_USERS_CANCEL_ORDER_EFFECT:
            return {
                ...state,
                startNovaPostUsersCancelOrderEffect : true,
            };
        case END_NOVA_POST_USERS_CANCEL_ORDER_EFFECT:
            return {
                ...state,
                startNovaPostUsersCancelOrderEffect : false,
            };
        case START_NOVA_POST_USERS_RETRY_ORDER_EFFECT:
            return {
                ...state,
                startNovaPostUsersRetryOrderEffect  : true,
                showDeliveryConnectionLoader        : true,
                showRetryOrderMessage               : false,

            };
        case END_NOVA_POST_USERS_RETRY_ORDER_EFFECT:
            return {
                ...state,
                startNovaPostUsersRetryOrderEffect  : false,

            };
        case START_NOVA_GET_USERS_NEARBY_EFFECT:
            return {
                ...state,
                startNovaGetUsersNearbyEffect: true
            };
        case END_NOVA_GET_USERS_NEARBY_EFFECT:
            return {
                ...state,
                startNovaGetUsersNearbyEffect: false
            };
        case START_NOVA_GET_PROVIDER_POINTS_EFFECT:
            return {
                ...state,
                startNovaGetProviderPointsEffect: true
            };
        case END_NOVA_GET_PROVIDER_POINTS_EFFECT:
            return {
                ...state,
                startNovaGetProviderPointsEffect: false
            };
        case START_NOVA_PATCH_USERS_ORDERS_ORDERID_RATE:
            return {
                ...state,
                startNovaPatchUsersOrdersOrderIdRateEffect      : true,
                showRating                                      : false,
                showRatingLoader                                : true
            };
        case END_NOVA_PATCH_USERS_ORDERS_ORDERID_RATE:
            return {
                ...state,
                startNovaPatchUsersOrdersOrderIdRateEffect      : false,
                showRatingLoader                                : false,
                showRatingDone                                  : true
            };
        case START_NOVA_PATCH_ORDERS_LOCATION_EFFECT:
            return {
                ...state,
                startNovaPatchOrdersLocationEffect: true
            };
        case END_NOVA_PATCH_ORDERS_LOCATION_EFFECT:
            return {
                ...state,
                startNovaPatchOrdersLocationEffect: false
            };








        case START_NOVA_CANCEL_ORDER_PROCESS:
            return {
                ...state,
                showDeliveryAgentConnection     : false,
                showCancelOrderWarningMessage   : true
            };
        case END_NOVA_CANCEL_ORDER_PROCESS:
            return {
                ...state,
                showDeliveryAgentConnection     : true,
                showCancelOrderWarningMessage   : false
            };
        case START_NOVA_RETRY_ORDER_PROCESS:
            return {
                ...state,
                showDeliveryAgentConnection     : false,
                showDeliveryConnectionLoader    : false,
                showRetryOrderMessage           : true
            };
        case END_NOVA_RETRY_ORDER_PROCESS:
            return {
                ...state,
                showDeliveryAgentConnection : true,
                showRetryOrderMessage       : false
            };
        case START_MAPBOX_REVERSE_GEOCODER_REQUEST:
            return {
                ...state,
                startMapboxReverseGeocoderRequest: true
            };
        case END_MAPBOX_REVERSE_GEOCODER_REQUEST:
            return {
                ...state,
                startMapboxReverseGeocoderRequest: false
            };
        case START_NOVA_UPDATE_GOOGLE_AUTOCOMPLETE_VALUE:
            return {
                ...state,
                startNovaUpdateGoogleAutocompleteValue: true
            };
        case END_NOVA_UPDATE_GOOGLE_AUTOCOMPLETE_VALUE:
            return {
                ...state,
                startNovaUpdateGoogleAutocompleteValue: false
            };
        case START_GMAPS_GEOCODER_EFFECT:
            return {
                ...state,
                startGmapGeocoderEffect: true
            };
        case END_GMAPS_GEOCODER_EFFECT:
            return {
                ...state,
                startGmapGeocoderEffect: false
            };
        case START_NOVA_UPDATE_CUSTOMER_PIN_COORDS:
            return {
                ...state,
                startNovaUpdateCustomerPinCoords: true
            };
        case END_NOVA_UPDATE_CUSTOMER_PIN_COORDS:
            return {
                ...state,
                startNovaUpdateCustomerPinCoords: false
            };
        default:
            return state;
    }
};

export default novaReducer;
