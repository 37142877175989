import React                            from 'react';
import { createStyles, makeStyles }     from '@material-ui/core';

import PropTypes                        from 'prop-types';
import Typography                       from '@material-ui/core/Typography';

import useNovaPostUsersCancelOrderEffect from '../../../Hooks/useNovaPostUsersCancelOrder';
import { NovaContext }                   from '../../../ContextProviders/Nova/NovaContext';

const useStyles = makeStyles(theme => createStyles({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignContent: 'center',
        alignItems: 'center'
    },
    spinnerContainer: {
        display         : 'flex',
        flexFlow        : 'column nowrap',
        justifyContent  : 'center',
        alignItems      : 'center',
        alignContent    : 'center',
        height          : '100%'
    },
    layoutContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyItems: 'center',
        alignItems: 'center',
        [theme.breakpoints.up(800)]: {
            flexFlow: 'row nowrap',
        }
    },
    fixedAgentMapContainer: {
        height: 260,
        width: '100%',
        [theme.breakpoints.up(800)]: {
            order: 2,
            height: 460
        }
    },
    fixedAgentContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyItems: 'center',
        alignItems: 'center',
        padding: '10px',
        //paddingBottom: 26,
        [theme.breakpoints.up(800)]: {
            height: 460,
            width: 500,
            justifyContent: 'center',
        }
    },
    subtitleCss: {
        fontSize        : 14,
        fontWeight      : 400,
        marginBottom    : 6,
        width           : 312,
        color           : 'dimgray',
        textAlign       : 'center',
        [theme.breakpoints.up(800)]: {
            width: 340,
        }
    }
}));

const QrCode = function QrCode({ match }) {

    const {
        root,
        fixedAgentContent,
        subtitleCss
    } = useStyles();

    const {
        state
    }   = React.useContext(NovaContext);

    const { params: { UID } } = match;
    const {
        order
    } = state;

    const { channel_prices, currency, currency_symbol } = order;
    const txStaticPrice      = window.sessionStorage.getItem('txStaticPrice');

    useNovaPostUsersCancelOrderEffect(UID);

    return(
        <section
            className={root}>
            <section className={fixedAgentContent}>
                <img
                    style={{
                        textAlign   : 'center',
                        height      : 100,
                        width       : 100
                    }}
                    src={`https://play-lh.googleusercontent.com/nVfbbZGjMnDXbh_X7VrstqtJK7y2Nh97WASxg4uAXLXywaUnn3VhwaRS5V0Nh7d9sntY`}
                    alt='goamiles logo'/>
{/*                <Typography style={{
                    fontSize        : 22,
                    fontWeight      : 400,
                    color           : 'dimgray'
                }}>
                    Para completar la Transacción
                </Typography>*/}
                <Typography className={subtitleCss} style={{ marginTop: 16 }}>
                    Please show this QR code to our Agent at the GoaMiles PrePaid Counter
                </Typography>
                <Typography style={{
                    textTransform   : 'uppercase',
                    fontSize        : 24,
                    color           : 'dimgray'
                }}>
                    {`${currency ? currency : ''} ${currency_symbol ? currency_symbol : '$'}${channel_prices ? channel_prices.static : Number(txStaticPrice)}`}
                </Typography>
                <img
                    style={{
                        textAlign   : 'center',
                        height      : 200,
                        width       : 200
                    }}
                    src={`https://us-central1-degrees-46.cloudfunctions.net/qr-generator?UID=${UID}`}
                    alt='código qr'/>
{/*                <section>
                    <Button
                        onClick={handleCancelFixedOrderButton}
                        style={{
                            background: 'crimson',
                            color: 'white',
                            textTransform: 'uppercase',
                            width: '100%',
                            height: 36
                        }}
                        variant='contained'
                        fullWidth>
                        cancelar esta orden
                    </Button>
                </section>*/}
            </section>
        </section>
    );
};

QrCode.propTypes = {
    match: PropTypes.object.isRequired
};

export default QrCode;
