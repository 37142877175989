import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import * as novaActions from '../ContextProviders/Nova/actions';
import { NOVA_SERVER_URL } from '../../constants'

export default function useNovaDeliveryIntentEffect(UID) {
    const {
        state,
        dispatch
    } = useContext(NovaContext);
    const {
        requestToken,
        userAddress,
        userSelectedAddressCoords,
        deliveryAgentsAmount,
        startNovaPostDeliveryIntentEffect
    } = state;
    useEffect(function novaDeliveryIntentEffect() {
        function dispatchActions(response) {
            const { status } = response;
            const actions = {
                201: function handle201DeliveryIntentResponse() {
                    dispatch(novaActions.endNovaPostDeliveryIntentEffect());
                }
            };
            return (typeof actions[status] == 'function')
                ?   actions[status]()
                :   console.log('Unable to continue running Nova from novaDeliveryIntentEffect(): ', response);
        }
        const authToken = function authToken(token) {
            return `Token ${token}`
        };
        const deliveryIntentRequest = async function deliveryIntentRequest() {
            const deliveryIntentRequestResponse = await fetch(`${NOVA_SERVER_URL}/users/order/delivery/intent/`, {
                method  : 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': `${authToken(requestToken)}`
                },
                body: JSON.stringify({
                    order_id                    : UID,
                    address                     : userAddress,
                    latitude                    : String(userSelectedAddressCoords.latitude),
                    longitude                   : String(userSelectedAddressCoords.longitude),
                    near_delivery_agents_count  : deliveryAgentsAmount
                })
            });
            const { status } = deliveryIntentRequestResponse;
            return status === 200
                ?   await deliveryIntentRequestResponse.json()
                :   deliveryIntentRequestResponse;
        };
        if (startNovaPostDeliveryIntentEffect && requestToken) deliveryIntentRequest()
            .then(response => {
                const { status } = response;
                return (typeof status === 'string')
                    ? dispatchActions({ status: 200, response })
                    : dispatchActions(response);
            });
    }, [
        UID,
        requestToken,
        userAddress,
        userSelectedAddressCoords,
        deliveryAgentsAmount,
        startNovaPostDeliveryIntentEffect,
        dispatch
    ]);
}