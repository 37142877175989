import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import * as novaActions from '../ContextProviders/Nova/actions';
import { NOVA_SERVER_URL } from '../../constants'

export default function useNovaUnverifiedLoginEffect() {
    const {
        state,
        dispatch
    } = useContext(NovaContext);
    const {
        email,
        password,
        startNovaPostUnverifiedLoginEffect
    } = state;
    useEffect(function c() {
        function dispatchActions(response){
            //console.log('useNovaUnverifiedLoginEffect : ', response);
            const { status, key } = response;
            const actions = {
                200: function handle200UnverifiedLoginRequestResponse() {
                    //console.log('useNovaUnverifiedLoginEffect 200 RESPONSE: ', response);
                    dispatch(novaActions.setSessionCookie(key));
                    dispatch(novaActions.endNovaPostUnverifiedLoginEffect());
                    dispatch(novaActions.startNovaGetAuthUserEffect());
                },
                400: function handle400UnverifiedLoginRequestResponse() {
                    //console.log('useNovaUnverifiedLoginEffect 400 RESPONSE: ', response);
                    //dispatch(novaActions.setLoginFormErrorState({ ...non_field_errors[0] }));
                    dispatch(novaActions.endNovaPostUnverifiedLoginEffect());
                },
                401: function handle401UnverifiedLoginRequestResponse() {
                    //console.log('useNovaUnverifiedLoginEffect 401 RESPONSE: ', response);
                    dispatch(novaActions.showAgentLoginScreen());
                    dispatch(novaActions.endNovaPostUnverifiedLoginEffect());
                },
                404: function handle404UnverifiedLoginRequestResponse() {
                    //console.log('useNovaUnverifiedLoginEffect 404 RESPONSE: ', response);
                    dispatch(novaActions.endNovaPostUnverifiedLoginEffect());
                    dispatch(novaActions.startNovaPostUsersRegistrationEffect());
                }
            };
            return (typeof actions[status] == 'function')
                ?   actions[status]()
                :   console.log('Unable to continue running Nova from novaUnverifiedLoginEffect(): ', response)
        }
        const unverifiedLoginRequest = async function unverifiedLoginRequest() {
            const unverifiedLoginRequestResponse = await fetch(`${NOVA_SERVER_URL}/users/unverified/login/`, {
                method  : 'POST',
                headers : {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            });

            const { status } = unverifiedLoginRequestResponse;
            return status === 200
                ?   unverifiedLoginRequestResponse.json()
                :   unverifiedLoginRequestResponse;
        };
        if (startNovaPostUnverifiedLoginEffect) unverifiedLoginRequest()
            .then(response => {
                const { status } = response;
                return status
                    ? dispatchActions(response)
                    : dispatchActions({ status: 200, ...response });
            });
    }, [email, password, startNovaPostUnverifiedLoginEffect, dispatch]);
}