import React, { useState } from 'react';
import ProptTypes                       from 'prop-types';
import { NovaContext }                  from '../../../ContextProviders/Nova/NovaContext';
import { makeStyles, createStyles }     from '@material-ui/core';
import Paper                            from '@material-ui/core/Paper';
import cancelOrderImage from "../../../../assets/images/cancelOrder.svg";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Ratings from "react-ratings-declarative";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as novaActions from "../../../ContextProviders/Nova/actions";

const OrderStatusWatcher =   React.lazy(() => import('../../Shared/OrderStatusWatcher/OrderStatusWatcher'));

const useStyles = makeStyles(() => createStyles({
    root: {
        margin      : '0 auto',
        maxWidth    : 360
    },
    transactionStatusView: {
        textAlign       : 'center',
        paddingTop      : 2,
        paddingBottom   : 24
    },
    agentRatingView: {
        textAlign   : 'center',
        //bottom      : 200,
        borderTop   : '1px solid rgba(0, 0, 0, 0.1)',
        width       : '100%',
        paddingTop  : 24,
        marginTop   : 16,
        position    : 'relative',
        height      :190
    },
    spinnerContainer: {
        display         : 'flex',
        flexFlow        : 'column nowrap',
        justifyContent  : 'center',
        alignItems      : 'center',
        alignContent    : 'center',
        height          : 420 //470
    },
    statusContainer: {
        //height: 470
    },
    ratingButtonCss: {
        width: '50%',
        margin: '0 auto',
        marginTop: 24,
        marginBottom: 24,
        color: 'white'
    }
}));

const FinalOrderStatus = function FinalOrderStatus({ history, match }) {

    const {
        root,
        transactionStatusView,
        agentRatingView,
        statusContainer,
        ratingButtonCss,
    } = useStyles();

    const {
        state,
        dispatch
    }   = React.useContext(NovaContext);

    const {
        agentProfile,
        showRating,
        showRatingDone,
        showRatingLoader,
        order,
        selfpay
    } = state;

    const [disableRatingButton, setDisableRatingButton] = useState(true);
    const [ratingValue, setRatingValue] = useState(1);

    const { return_url } = order;
    const returnUrl = order && order.return_url ? return_url : window.sessionStorage.getItem('returnUrl') ? window.sessionStorage.getItem('returnUrl') : 'https://www.pago46.com/';
    const agentType = match ? match.path.split(/\/:UID\//)[1] : undefined;

    const handleRatingStars = function handleRatingStars(rating) {
        setDisableRatingButton(false);
        setRatingValue(rating);
        return dispatch(novaActions.setAgentRating(rating));
    };

    const handleRatingButtonClick = function handleRatingButtonClick() {
        return dispatch(novaActions.startNovaPatchUsersOrdersOrderIdRate());
    };

    return(
        <Paper className={root} elevation={6}>
            {
                !match && (
                    <OrderStatusWatcher UID={match && match.params && match.params.UID} history={history}/>
                )
            }
            {
                match && match.params && match.params.status === 'complete' && (
                    <section className={statusContainer}>
                        <section className={transactionStatusView}>
                            <DoneAllIcon
                                style={{
                                    fontSize: 128,
                                    color: '#06BA54'
                                }}
                            />
                            <Typography variant='h5' style={{ color: 'black' }}>
                                Transacción Exitosa
                            </Typography>
                        </section>
                        {
                            agentProfile
                                ?
                                (
                                    <section className={agentRatingView}>
                                        <section
                                            style={{
                                                display         : 'flex',
                                                flexFlow        : 'column nowrap',
                                                justifyContent  : 'center',
                                                height          : '100%'
                                            }}>
                                            {
                                                showRating && !selfpay
                                                    ?
                                                    (
                                                        <React.Fragment>
                                                            {
                                                                agentType === 'deliveryAgentConnection'
                                                                    ?
                                                                    (
                                                                        <Typography variant='subtitle2' style={{ color: 'rgba(0, 0, 0, 0.5)', marginBottom: 16}}>
                                                                            Califica al Socio46
                                                                        </Typography>
                                                                    )
                                                                    : null
                                                            }
                                                            <Typography variant='h6' style={{ marginBottom: agentType === 'fixedAgentConnection' ? 32 : 8}}>
                                                                {
                                                                    agentType === 'fixedAgentConnection'
                                                                        ? 'Califica la experiencia de pago'
                                                                        : `${agentProfile ? agentProfile.name : 'Agente'} ${agentProfile ? agentProfile.surname : 'Desconocido'}`
                                                                }
                                                            </Typography>
                                                            <Ratings
                                                                rating={ratingValue}
                                                                widgetRatedColors='#06BA54'
                                                                widgetEmptyColors='gray'
                                                                widgetHoverColors='#06BA54'
                                                                changeRating={handleRatingStars}>
                                                                <Ratings.Widget widgetDimension={'32'}/>
                                                                <Ratings.Widget widgetDimension={'32'}/>
                                                                <Ratings.Widget widgetDimension={'32'}/>
                                                                <Ratings.Widget widgetDimension={'32'}/>
                                                                <Ratings.Widget widgetDimension={'32'}/>
                                                            </Ratings>
                                                            <Button
                                                                className={ratingButtonCss}
                                                                disabled={disableRatingButton}
                                                                color='primary'
                                                                variant='contained'
                                                                onClick={event => {
                                                                    event.preventDefault();
                                                                    return handleRatingButtonClick();
                                                                }}>
                                                                Calificar
                                                            </Button>
                                                        </React.Fragment>
                                                    )
                                                    :   null
                                            }
                                            {
                                                showRatingDone || selfpay
                                                    ?
                                                    (
                                                        <section style={{
                                                            textAlign: 'center',
                                                            padding: '0 16px',
                                                            display: 'flex',
                                                            flexFlow: 'column nowrap',
                                                            alignItems: 'center' }}>
                                                            <Typography variant='body1' style={{ color: 'rgba(0, 0, 0, 0.5)', marginTop: 28 }}>
                                                                {`${selfpay ? 'El pago se ha realizado con éxito' : 'Tu calificación ya fue enviada'}`}
                                                            </Typography>
                                                            <Button
                                                                href={returnUrl}
                                                                variant='contained'
                                                                color='primary'
                                                                style={{ color: 'white', width: '60%', marginTop: 56 }}>
                                                                Volver a la tienda
                                                            </Button>
                                                        </section>
                                                    )
                                                    :   null
                                            }
                                            {
                                                showRatingLoader
                                                    ?
                                                    (
                                                        <section style={{
                                                            textAlign: 'center',
                                                            padding: '0 16px',
                                                            display: 'flex',
                                                            flexFlow: 'column nowrap',
                                                            alignItems: 'center'
                                                        }}>
                                                            <Typography style={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: 16 }}>
                                                                Enviando tu calificación...
                                                            </Typography>
                                                            <LinearProgress color='primary' style={{ marginTop: 16, width: '50%' }}/>
                                                        </section>
                                                    )
                                                    :   null
                                            }
                                        </section>
                                    </section>
                                )
                                : null
                        }
                    </section>
                )
            }
            {
                match && match.params && match.params.status === 'cancelled' && (
                    <section
                        style={{
                            minHeight       : 420,
                            display         : 'flex',
                            flexFlow        : 'column nowrap',
                            alignItems      : 'center',
                            alignContent    : 'center',
                            justifyContent  : 'center'
                        }}>
                        <img
                            style={{
                                marginTop: 40,
                                width: 160,
                                height: 160
                            }}
                            src={cancelOrderImage}
                            alt='Orden Cancelada'/>
                        <Typography
                            style={{
                                marginTop: 32,
                                fontSize: 16,
                                color: 'rgba(0, 0, 0, 0.5)'
                            }}>
                            Esta orden ha sido cancelada
                        </Typography>
                    </section>
                )
            }
        </Paper>
    );
};

FinalOrderStatus.propTypes = {
    history: ProptTypes.object.isRequired,
    match: ProptTypes.object.isRequired
};

export default FinalOrderStatus;
