import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import * as novaActions from '../ContextProviders/Nova/actions';
import { NOVA_SERVER_URL } from '../../constants'
//import payloadTest from '../../Nova/payloadTest2';

export default function useNovaGetProviderPointsEffect(UID) {
    const {
        state,
        dispatch
    } = useContext(NovaContext);
    const {
        requestToken,
        startNovaGetProviderPointsEffect,
        userSelectedAddress,
        userSelectedAddressCoords,
        initialUserPosition,
        order,
    } = state;

    const { currency } = order;

    useEffect(function novaGetProviderPointsEffect() {

        const DEFAULT_RADIUS = 1;

/*        const consumerLatitude      =   userSelectedAddress && userSelectedAddressCoords
            ? userSelectedAddressCoords.latitude
            : initialUserPosition && initialUserPosition.coords
                ? initialUserPosition.coords.latitude
                : undefined;
        const consumerLongitude     =   userSelectedAddress && userSelectedAddressCoords
            ? userSelectedAddressCoords.longitude
            : initialUserPosition && initialUserPosition.coords
                ? initialUserPosition.coords.longitude
                : undefined;*/

        const getCurrency = function getCurrency(aCurrency) {
            return aCurrency ? aCurrency : window.sessionStorage.getItem('currency');
        };

        const consumerLatitude = function consumerLatitude(selectedAddress, selectedAddressCoords, initialPosition, fallbackLatitude) {
            return selectedAddress && selectedAddressCoords
                ? selectedAddressCoords.latitude
                : initialPosition && initialPosition.coords
                    ? initialPosition.coords.latitude
                    : fallbackLatitude;
        };

        const consumerLongitude = function consumerLongitude(selectedAddress, selectedAddressCoords, initialPosition, fallbackLongitude) {
            return selectedAddress && selectedAddressCoords
                ? selectedAddressCoords.longitude
                : initialPosition && initialPosition.coords
                    ? initialPosition.coords.longitude
                    : fallbackLongitude;
        };

        const authToken = function authToken(token) {
            return `Token ${token}`
        };

        function dispatchActions(response){
            const { status, payload } = response;
            const actions = {
                200: function handle200novaGetProviderPointsRequestResponse() {
                    dispatch(novaActions.setFixedProviderPointsData({ id: order.id, payload }));
                    //dispatch(novaActions.setStaticPointsData(response.static))
                }
            };
            return (typeof actions[status] == 'function')
                ?   actions[status]()
                :   console.log('Unable to continue running Nova from novaGetProviderPointsEffect(): ', response);
        }

        const novaGetProviderPointsRequest = async function novaGetProviderPointsRequest(repetition = 0) {
            // When no points are found, this function will be repeated
            // If that is the case, the radius will be 5 or lately 10. Otherwise it will be 1 at first
            const radius = repetition > 0 ? repetition * 5 : DEFAULT_RADIUS

            const novaGetProviderPointsRequestResponse = await fetch(`${NOVA_SERVER_URL}/payments/provider/points/?currency=${getCurrency(currency)}&latitude=${consumerLatitude(userSelectedAddress, userSelectedAddressCoords, initialUserPosition, -33.410525799999995)}&longitude=${consumerLongitude(userSelectedAddress, userSelectedAddressCoords, initialUserPosition, -70.541938)}&radius=${radius || 1}&order_id=${UID}`, {
                method  : 'GET',
                headers : {
                    'Authorization': `${authToken(requestToken)}`,
                    'Content-Type': 'application/json'
                },
            });
            const { status } = novaGetProviderPointsRequestResponse;
            if (status === 200) {
                const response = await novaGetProviderPointsRequestResponse.json()
                const hasPoints = !!response.filter(provider => provider.points).length;

                if (!hasPoints && repetition < 2) {
                   return novaGetProviderPointsRequest(repetition + 1);
                }

                return response;
            }

            return novaGetProviderPointsRequestResponse;
        };
        if (startNovaGetProviderPointsEffect && requestToken) {
            // Enable the searching which affects the search button
            dispatch(novaActions.toggleSearchProvider(true))

            novaGetProviderPointsRequest().then(response => {
                const { status } = response;
                return status
                    ? dispatchActions(response)
                    : dispatchActions({ status: 200, payload: [...response] });
            });

            dispatch(novaActions.toggleSearchProvider(false))
        }
    }, [
        requestToken,
        startNovaGetProviderPointsEffect,
        dispatch,
        UID,
        userSelectedAddress,
        userSelectedAddressCoords,
        initialUserPosition,
        currency,
        order
    ]);
}
