import React, { useRef, useEffect }             from 'react';
import PropTypes                                from 'prop-types';
import * as novaActions                         from '../../../ContextProviders/Nova/actions';
import { NovaContext }                          from '../../../ContextProviders/Nova/NovaContext';
import { makeStyles, createStyles }             from '@material-ui/core';
import FormControl                              from '@material-ui/core/FormControl';
import Switch                                   from '@material-ui/core/Switch';
import TextField                                from '@material-ui/core/TextField';
import Button                                   from '@material-ui/core/Button';
import Typography                               from '@material-ui/core/Typography';
import MyLocationIcon                           from '@material-ui/icons/MyLocation';
import useGoogleMapsApiLoadEffect               from '../../../Hooks/useGoogleMapsApiLoad';
import useGoogleMapsAutocompleteEffect          from '../../../Hooks/useGoogleMapsAutocomplete';
import CircularProgress                         from '@material-ui/core/CircularProgress';
import FormControlLabel                         from '@material-ui/core/FormControlLabel';
const Mapbox    = React.lazy(() => import('../../Shared/Mapbox/Mapbox'));
const useStyles = makeStyles(theme => createStyles({
    layoutContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyItems: 'center',
        alignItems: 'center',
        [theme.breakpoints.up(800)]: {
            flexFlow: 'row nowrap',
        }
    },
    formLocationContainerStyles: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyItems: 'center',
        alignItems: 'center',
        [theme.breakpoints.up(800)]: {
            height: 540,
            width: 500,
        }
    },
    askUserPositionLabel: {
        fontSize: 18,
        textAlign: 'left',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    title: {
        paddingTop: '2%',
        fontSize: 16
    },
    textInputs: {
        margin: '8px 0',
        width: '100%'
    },
    continueButton: {
        color: 'white',
        margin: '8px 0'
    },
    locationStepButtonContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '80%',
        margin: '24px 0',
        [theme.breakpoints.up(800)]: {
            marginTop: 36,
        }
    },
    textContainerStyles: {
        placeSelf: 'center' ,
        textAlign: 'center',
        padding: '16px 0 8px',
        width: '90%'
    },
    addressTextStyles: {
        fontSize: 16,
        color: '#06BA54',
        padding: '12px 0',
        maxWidth: '90%',
        margin: '0 auto',
        [theme.breakpoints.up(800)]: {
            //padding: '36px 0',
        }
    },
    mapStyles: {
        width: '100%',
        height: '100%'
    },
    userLocationMapContainer: {
        height: 260,
        width: '100%',
        [theme.breakpoints.up(800)]: {
            order: 2,
            height: 540
        }
    },
    locationIcon: {
        fontSize: 36,
        color: '#06BA54',
        marginTop: 16,
        [theme.breakpoints.up(800)]: {
            fontSize: 48,
        }
    },
    switchSpinnerContainer: {
        minHeight: 64,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignContent: 'center',
        alignItems: 'center'
    },
    formControlLabelStyles: {
        background: '#ebfef4',
        padding: '8px',
        marginLeft: -12,
        borderRadius: 10,
        width: '84%',
        minHeight: 46,
        [theme.breakpoints.up(800)]: {
            marginBottom: 25
        }
    },
    labelStyles: {
        color: 'rgba(0, 0, 0, 0.54)',
        width: '100%',
        textAlign: 'justify',
        marginRight: 8
    },
    switchRoot: {
        height: 40,
        width: 60,
    },
    searchButtonLabel: {
        // fontSize : 14,
        // color : 'rgba(0, 0, 0, 0.80)',
        // width: '88%',
        // margin: '0 auto',
        marginTop: 12,
        // marginBottom : -14,
        // textAlign: 'left',
        // [theme.breakpoints.up(800)]: {
        //     marginBottom: -50
        // }
    }
}));
const UserLocationStep = function UserLocationStep({
    handleLocationButton,
    userAddress,
    showSwitchSpinner,
    switchSpinnerMessage,
    switchSuccessAccessMessage,
    switchErrorAccessMessage,
    switchAccessMessage,
    initialUserPositionSuccess,
    initialUserPositionError,
    handleSwitchControl,
    isSwitchChecked,
    disableSwitch,
}) {
    const {
        title,
        textInputs,
        continueButton,
        layoutContainer,
        formLocationContainerStyles,
        locationStepButtonContainer,
        textContainerStyles,
        addressTextStyles,
        userLocationMapContainer,
        locationIcon,
        switchSpinnerContainer,
        askUserPositionLabel,
        formControlLabelStyles,
        labelStyles,
        switchRoot,
        searchButtonLabel
    } = useStyles();
    const {
        state,
        dispatch
    } = React.useContext(NovaContext);
    const {
        initialUserPosition,
        userSelectedAddress,
        userSelectedAddressCoords,
        startNovaUpdateGoogleAutocompleteValue,
        order,
        firestoreOrder: {
            merchant
        }
    } = state;

    const userLocationStepInputRef = useRef(undefined);
    const autocompleteRef = React.createRef();
    const countryCode = order && order.country_code ? order.country_code : (window.sessionStorage.getItem('countryCode') || '')
    const countryCoords = function countryCoords(countryCode) {
        const countries = {
            'CHL': {
                lat: -29.974431,
                lng: -70.204330
            },
            'ARG': {
                lat: -36.297846,
                lng: -64.754694
            },
            'MEX': {
                lat: 23.4224403,
                lng: -102.374756
            },
            'ECU': {
                lat: -1.64731535,
                lng: -78.3782477
            },
            fallback: {
                lat: 67.865384,
                lng: -73.808737
            }
        };
        return countries[countryCode]
            ? countries[countryCode]
            : countries.fallback;
    };

    const fallbackLat = countryCoords(countryCode).lat;
    const fallbackLng = countryCoords(countryCode).lng;
    const consumerLatitude = function consumerLatitude(firstUserPosition, selectedAddress, selectedAddressCoords, aMerchant, fallbackLatitude) {
        return firstUserPosition && firstUserPosition.coords
            ? firstUserPosition.coords.latitude
            : selectedAddress && selectedAddressCoords
                ? selectedAddressCoords.latitude
                : fallbackLatitude;
    };
    const consumerLongitude = function consumerLongitude(firstUserPosition, selectedAddress, selectedAddressCoords, aMerchant, fallbackLongitude) {
        return firstUserPosition && firstUserPosition.coords
            ? firstUserPosition.coords.longitude
            : selectedAddress && selectedAddressCoords
                ? selectedAddressCoords.longitude
                : fallbackLongitude;
    };
    const handleAddressDptoNumberInputChange = function handleAddressDptoNumberInputChange(event) {
        event.preventDefault();
        dispatch(novaActions.setUserAddressDptoNumber(event.target.value));
    };
    useGoogleMapsApiLoadEffect();
    useGoogleMapsAutocompleteEffect(autocompleteRef);
    useEffect(function updateGoogleAutocompleteValue() {
        if (startNovaUpdateGoogleAutocompleteValue && autocompleteRef.current) {
            autocompleteRef.current.value = userAddress;
        }
    }, [
        startNovaUpdateGoogleAutocompleteValue,
        autocompleteRef,
        userAddress
    ]);

    const switchLabelText = showSwitchSpinner
        ?
            (
                <div className={switchSpinnerContainer}>
                    <CircularProgress style={{ color: '#06BA54'}} size={50} />
                        <Typography style={{ marginLeft: 12}} className={askUserPositionLabel}>
                            { switchSpinnerMessage }
                        </Typography>
                </div>
            )
        : initialUserPositionSuccess
            ?
                (
                    <div className={switchSpinnerContainer}>
                        <Typography className={askUserPositionLabel}>
                            { switchSuccessAccessMessage }
                        </Typography>
                    </div>
                )
            : initialUserPositionError
                ?
                    (
                        <div className={switchSpinnerContainer}>
                            <Typography className={askUserPositionLabel}>
                                { switchErrorAccessMessage }
                            </Typography>
                        </div>
                    )
                :
                    (
                        <div className={switchSpinnerContainer}>
                            <Typography className={askUserPositionLabel}>
                                { switchAccessMessage }
                            </Typography>
                        </div>
                    );
    return(
        <section className={layoutContainer}>
            <section className={userLocationMapContainer}>
                <Mapbox
                    consumerLatitude={consumerLatitude(initialUserPosition, userSelectedAddress, userSelectedAddressCoords, merchant, fallbackLat)}
                    consumerLongitude={consumerLongitude(initialUserPosition, userSelectedAddress, userSelectedAddressCoords, merchant, fallbackLng)}
                    userLocationStepInputRef={userLocationStepInputRef}
                    userLocationStep/>
            </section>
            <FormControl className={formLocationContainerStyles}>
                <div className={textContainerStyles}>
                    {
                        !initialUserPositionSuccess
                            ?
                                (
                                    <React.Fragment>
                                        <FormControlLabel
                                            className={formControlLabelStyles}
                                            control={
                                                <Switch
                                                    size='medium'
                                                    color='primary'
                                                    checked={isSwitchChecked}
                                                    onChange={handleSwitchControl}
                                                    disabled={disableSwitch}
                                                    classes={{
                                                        root: switchRoot
                                                    }}/>
                                            }
                                            classes={{ label: labelStyles }}
                                            label={switchLabelText}
                                            labelPlacement='start'
                                        />
                                        <Typography
                                            variant='h6'
                                            color='textPrimary'
                                            classes={{
                                                root: searchButtonLabel
                                            }}>
                                            {'Busca tu dirección a continuación'}
                                        </Typography>
                                    </React.Fragment>
                                )
                            :
                                (
                                    <React.Fragment>
                                        <React.Fragment>
                                            <MyLocationIcon className={locationIcon}/>
                                            <Typography
                                                color='textPrimary'
                                                className={addressTextStyles}>
                                                {userAddress}
                                            </Typography>
                                        </React.Fragment>
                                        <Typography
                                            color='textPrimary'
                                            className={title}>
                                            ¿Esta es tu dirección? Si no lo es, búscala aquí
                                        </Typography>
                                    </React.Fragment>
                                )
                    }
                </div>
                <div style={{ placeSelf: 'center', padding: 0, width: '80%' }}>
                    {
                        countryCode && (countryCode === 'ARG' || countryCode === 'ECU') && (
                            <TextField
                                className={textInputs}
                                placeholder='Buscar Dirección'
                                type='text'
                                margin='normal'
                                variant='outlined'
                                inputRef={autocompleteRef}/>
                        )
                    }
                    <div
                        ref={userLocationStepInputRef}
                        className={textInputs}
                        style={{
                            display: countryCode && (countryCode === 'ARG' || countryCode === 'ECU')
                                    ? 'none'
                                    : 'block'
                        }}>
                    </div>
                    <TextField
                        onChange={handleAddressDptoNumberInputChange}
                        className={textInputs}
                        type='text'
                        placeholder={'N° de departamento - opcional'}
                        margin='normal'
                        variant='outlined'/>
                </div>
                <div className={locationStepButtonContainer}>
                    <Button
                        onClick={handleLocationButton}
                        className={continueButton}
                        variant='contained'
                        color='primary'
                        disabled={!userSelectedAddress}>
                        Continuar
                    </Button>
                </div>
            </FormControl>
        </section>
    );
};

UserLocationStep.propTypes = {
    handleLocationButton: PropTypes.func.isRequired,
    userAddress: PropTypes.string.isRequired
};

export default UserLocationStep;