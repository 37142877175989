import React from 'react';
import ProptTypes               from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core';
import DeliveryAgentConnectionStep from './DeliveryAgentConnectionStep';
import Paper from '@material-ui/core/Paper';
import HeadStepper              from '../../Shared/Stepper/Stepper';
import * as novaActions from "../../../ContextProviders/Nova/actions";
import { NovaContext }      from '../../../ContextProviders/Nova/NovaContext';

const OrderStatusWatcher =   React.lazy(() => import('../../Shared/OrderStatusWatcher/OrderStatusWatcher'));

const useStyles = makeStyles(theme => createStyles({
    root: {
        maxWidth: 420,
        margin: '0 auto',
        [theme.breakpoints.up(800)]: {
            maxWidth: 800,
        }
    }
}));

const DeliveryAgentConnectionStepContainer = function DeliveryAgentConnectionStepContainer({ history, match }) {

    const {
        dispatch
    }   = React.useContext(NovaContext);

    const { root } = useStyles();
    const { params: { UID } } = match;

    const handleSwitchControl               = function handleSwitchControl(event) {
        event.preventDefault();
        dispatch(novaActions.toggleSwitchSpinner());
    };

    return(
        <Paper
            className={root}
            elevation={6}>
            <OrderStatusWatcher
                UID={UID}
                history={history}>
                <HeadStepper
                    currentStep={4}/>
                <DeliveryAgentConnectionStep
                    history={history}
                    match={match}
                    handleSwitchControl={handleSwitchControl}
                />
            </OrderStatusWatcher>
        </Paper>
    );
};

DeliveryAgentConnectionStepContainer.propTypes = {
    history: ProptTypes.object.isRequired,
    match: ProptTypes.object.isRequired
};

export default DeliveryAgentConnectionStepContainer;