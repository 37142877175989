import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import * as novaActions from '../ContextProviders/Nova/actions';
import { NOVA_SERVER_URL } from '../../constants'

export default function useNovaPostUsersOrdersEffect(UID) {
    const {
        state,
        dispatch
    } = useContext(NovaContext);
    const {
        requestToken,
        startNovaPostUsersOrdersEffect,
        userAddress,
        userDptoNumber,
        userSelectedAddress,
        userSelectedAddressCoords,
        initialUserPosition,
        firestoreOrder: {
            status
        }
    } = state;

    useEffect(function novaGetUsersOrderEffect() {
        function dispatchActions(response) {
            const { status } = response;

            //console.log('respose final: ', response);
            const actions = {
                201: function handle200UsersOrdersRequestResponse() {
                    dispatch(novaActions.endNovaPostUsersOrdersEffect());
                    //console.log('201 RESPONSE: ', response);
                },
                410: function handle410UsersOrdersRequestResponse() {
                    dispatch(novaActions.endNovaPostUsersOrdersEffect());
                    //console.log('useNovaPostUsersOrders 410: ', response);

                },
                400: function handle400UsersOrdersRequestResponse() {
                    dispatch(novaActions.endNovaPostUsersOrdersEffect());
                    //console.log('useNovaPostUsersOrders 400: ', response);
                }
            };
            return (typeof actions[status] == 'function')
                ?   actions[status]()
                :   console.log('Unable to continue running Nova from useNovaPostUsersOrdersEffect(): ', response);
        }

/*        const consumerLatitude      =   userSelectedAddress && userSelectedAddressCoords
            ? userSelectedAddressCoords.latitude
            : initialUserPosition && initialUserPosition.coords
                ? initialUserPosition.coords.latitude
                : -33.410525799999995;
        const consumerLongitude     =   userSelectedAddress && userSelectedAddressCoords
            ? userSelectedAddressCoords.longitude
            : initialUserPosition && initialUserPosition.coords
                ? initialUserPosition.coords.longitude
                : -70.541938;*/

        const consumerLatitude = function consumerLatitude(selectedAddress, selectedAddressCoords, initialPosition, fallbackLatitude) {
            return selectedAddress && selectedAddressCoords
                ? selectedAddressCoords.latitude
                : initialPosition && initialPosition.coords
                    ? initialPosition.coords.latitude
                    : fallbackLatitude;
        };

        const consumerLongitude = function consumerLongitude(selectedAddress, selectedAddressCoords, initialPosition, fallbackLongitude) {
            return selectedAddress && selectedAddressCoords
                ? selectedAddressCoords.longitude
                : initialPosition && initialPosition.coords
                    ? initialPosition.coords.longitude
                    : fallbackLongitude;
        };

        const authToken = function authToken(token) {
            return `Token ${token}`
        };

        const usersOrdersRequest = async function usersOrdersRequest() {
            const usersOrderRequestResponse = await fetch(`${NOVA_SERVER_URL}/users/orders/`, {
                method  : 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': `${authToken(requestToken)}`
                },
                body: JSON.stringify({
                    agent_type: 'delivery',
                    order_id: UID,
                    latitude: consumerLatitude(userSelectedAddress, userSelectedAddressCoords, initialUserPosition, -33.410525799999995),
                    longitude: consumerLongitude(userSelectedAddress, userSelectedAddressCoords, initialUserPosition, -70.541938),
                    delivery_data: `${userAddress} ${userDptoNumber ? `- Dpto N°: ${userDptoNumber}` : ''}`
                })
            });
            const { status } = usersOrderRequestResponse;
            return status === 201
                ?   usersOrderRequestResponse.json()
                :   usersOrderRequestResponse;
        };
        if (startNovaPostUsersOrdersEffect && requestToken) {
            usersOrdersRequest()
                .then(response => {
                    const { status } = response;
                    return (typeof status === 'undefined')
                        ? dispatchActions({ status: 201, response } )
                        : dispatchActions(response);
                });
        }
    }, [
        requestToken,
        startNovaPostUsersOrdersEffect,
        dispatch,
        UID,
        status,
        userAddress,
        userDptoNumber,
        userSelectedAddress,
        userSelectedAddressCoords,
        initialUserPosition,
    ]);
}