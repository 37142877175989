import {
    TOGGLE_SEARCH_PROVIDER,
    SET_USER_EMAIL,
    SET_USER_EMAIL_ERROR,
    CLEAR_EMAIL_ERROR_STATE,
    TOGGLE_AGENT_LOGIN_SCREEN,
    SHOW_AGENT_LOGIN_SCREEN,
    SET_USER_INITIAL_POSITION,
    SET_USER_INITIAL_POSITION_ERROR,
    SET_GOOGLE_MAPS_API_TO_STATE,
    TOGGLE_SWITCH_SPINNER,
    SET_LOGIN_SCREEN,
    SET_AGENT_LOGIN_PASSWORD,
    SET_USER_ADDRESS,
    SET_USER_ADDRESS_ERROR,
    SET_SELECTED_AGENT_TYPE,
    TOGGLE_SPINNER,
    SET_LOGIN_FORM_ERROR_STATE,
    SET_SESSION_COOKIE,
    SET_USER_ID,
    SET_FIRESTORE_ORDER_DATA,
    SET_SELFPAY_NOTIFICATION_SCREEN,

    SET_FIXED_PROVIDER_POINTS_DATA,

    SET_MULTICAJA_DATA,
    SET_MULTICAJA_MARKERS,
    SET_STATIC_MARKERS,
    SET_CUSTOMER_MARKER,
    SET_DELIVERY_AGENT_AVAILABILITY,
    TOGGLE_AGENT_CONNECTION_DRAWER,
    OPEN_CHAT_DRAWER,
    CLOSE_CHAT_DRAWER,
    SET_CHAT_SNAPSHOTS,
    SET_USER_ADDRESS_DPTO_NUMBER,
    SET_AGENT_PROFILE,
    SET_AGENT_RATING,
    CLEAR_STATE,
    SET_ORDER_TO_STATE,
    SET_INVALID_EMAIL_ASSOCIATED_TO_THE_ORDER,
    SET_INCLUDED_USER_EMAIL_FLOW,
    SET_MAPBOX_MAP_ZOOM,
    SET_USER_POS_CODE,


    START_NOVA_CANCEL_ORDER_PROCESS,
    END_NOVA_CANCEL_ORDER_PROCESS,
    START_NOVA_RETRY_ORDER_PROCESS,
    END_NOVA_RETRY_ORDER_PROCESS,


    START_NOVA_POST_AUTH_LOGIN_EFFECT,
    END_NOVA_POST_AUTH_LOGIN_EFFECT,
    START_NOVA_POST_UNVERIFIED_LOGIN,
    END_NOVA_POST_UNVERIFIED_LOGIN,
    START_NOVA_POST_USERS_REGISTRATION_EFFECT,
    END_NOVA_POST_USERS_REGISTRATION_EFFECT,
    START_NOVA_GET_AUTH_USER_EFFECT,
    END_NOVA_GET_AUTH_USER_EFFECT,
    START_NOVA_GET_USERS_ORDER_EFFECT,
    END_NOVA_GET_USERS_ORDER_EFFECT,
    START_NOVA_HISTORY_EFFECT,
    END_NOVA_HISTORY_EFFECT,
    START_NOVA_POST_ORDERS_SELFPAY_EFFECT,
    END_NOVA_POST_ORDERS_SELFPAY_EFFECT,
    SET_SUCCESSFUL_ORDER_STATE,
    SET_ERROR_ORDER_STATE,
    SET_AWAITING_USER_PAYMENT_ORDER_STATE,
    SET_AWAITING_ASSIGNMENT_ORDER_STATE,
    START_NOVA_GET_MULTICAJA_POINTS_EFFECT,
    END_NOVA_GET_MULTICAJA_POINTS_EFFECT,
    START_NOVA_POST_USERS_ORDERS_EFFECT,
    END_NOVA_POST_USERS_ORDERS_EFFECT,
    START_NOVA_POST_USERS_POS_EFFECT,
    END_NOVA_POST_USERS_POS_EFFECT,
    START_NOVA_POST_USERS_CANCEL_ORDER_EFFECT,
    END_NOVA_POST_USERS_CANCEL_ORDER_EFFECT,
    START_NOVA_POST_USERS_RETRY_ORDER_EFFECT,
    END_NOVA_POST_USERS_RETRY_ORDER_EFFECT,
    START_NOVA_POST_DELIVERY_INTENT_EFFECT,
    END_NOVA_POST_DELIVERY_INTENT_EFFECT,
    START_NOVA_GET_USERS_NEARBY_EFFECT,
    END_NOVA_GET_USERS_NEARBY_EFFECT,
    START_NOVA_GET_PROVIDER_POINTS_EFFECT,
    END_NOVA_GET_PROVIDER_POINTS_EFFECT,
    START_NOVA_PATCH_USERS_ORDERS_ORDERID_RATE,
    END_NOVA_PATCH_USERS_ORDERS_ORDERID_RATE,
    START_NOVA_PATCH_ORDERS_LOCATION_EFFECT,
    END_NOVA_PATCH_ORDERS_LOCATION_EFFECT,
    START_MAPBOX_REVERSE_GEOCODER_REQUEST,
    END_MAPBOX_REVERSE_GEOCODER_REQUEST,
    START_NOVA_UPDATE_GOOGLE_AUTOCOMPLETE_VALUE,
    END_NOVA_UPDATE_GOOGLE_AUTOCOMPLETE_VALUE,
    START_NOVA_UPDATE_CUSTOMER_PIN_COORDS,
    END_NOVA_UPDATE_CUSTOMER_PIN_COORDS,
    START_GMAPS_GEOCODER_EFFECT,
    END_GMAPS_GEOCODER_EFFECT,
    SHOW_LOGIN_FORM,
    HIDE_LOGIN_FORM, SET_EMAIL_NOT_ALLOWED_TO_CONTINUE
} from './types';

export function toggleSearchProvider(isSearchingProvider) {
    return {
        type: TOGGLE_SEARCH_PROVIDER,
        isSearchingProvider
    }
}

export function setUserEmail(email) {
    return {
        type: SET_USER_EMAIL,
        email
    };
}

export function setUserEmailError(errorMsg) {
    return {
        type: SET_USER_EMAIL_ERROR,
        emailErrorMsg: errorMsg
    };
}

export function clearEmailErrorState() {
    return {
        type: CLEAR_EMAIL_ERROR_STATE
    };
}

export function toggleAgentLoginScreen() {
    return {
        type: TOGGLE_AGENT_LOGIN_SCREEN
    }
}

export function showAgentLoginScreen() {
    return {
        type: SHOW_AGENT_LOGIN_SCREEN
    }
}

export function toggleSwitchSpinner() {
    return {
        type: TOGGLE_SWITCH_SPINNER
    }
}

export function setUserInitialPosition(position) {
    //console.log('setUserInitialPosition: ', position);
    window.sessionStorage.setItem('setUserInitialPosition', JSON.stringify(position));
    return {
        type: SET_USER_INITIAL_POSITION,
        initialUserPosition: position,
        geolocationAccess: true
    }
}

export function setUserInitialPositionError({code, message}) {
    return {
        type: SET_USER_INITIAL_POSITION_ERROR,
        initialUserPositionErrorCode: code,
        initialUserPositionErrorMessage: message
    };
}

export function setGoogleMapsApiToState(mapsApi) {
    return {
        type: SET_GOOGLE_MAPS_API_TO_STATE,
        gmap: mapsApi
    };
}

export function setLoginScreen() {
    return {
        type: SET_LOGIN_SCREEN
    };
}

export function setAgentLoginPassword(password) {
    return {
        type: SET_AGENT_LOGIN_PASSWORD,
        password: password
    };
}

export function setGmapUserAddress(address, location) {
    const coords = {
        latitude: location.lat(),
        longitude: location.lng()
    };
    window.sessionStorage.setItem('userSelectedAddressCoords', JSON.stringify(coords));
    return {
        type: SET_USER_ADDRESS,
        userAddress                 : address,
        userSelectedAddressCoords   : coords
    };
}

export function setUserAddress(address, {latitude, longitude}) {
    const parseAddress = function parseAddress(userAddress) {
        const parsedAddress = userAddress.split(',');
        return parsedAddress[0] && parsedAddress[1] ? `${parsedAddress[0]}, ${parsedAddress[1]}` : userAddress;
    };
    const coords = {
        latitude,
        longitude
    };
    window.sessionStorage.setItem('userAddress', parseAddress(address));
    window.sessionStorage.setItem('userSelectedAddressCoords', JSON.stringify(coords));
    return {
        type: SET_USER_ADDRESS,
        userAddress                 : parseAddress(address),
        userSelectedAddressCoords   : coords
    };
}

export function setUserAddressError() {
    return {
        type: SET_USER_ADDRESS_ERROR,
        userAddressError: true
    };
}

export function setSelectedAgentType(agentType) {
    return {
        type: SET_SELECTED_AGENT_TYPE,
        selectedAgentType: agentType
    };
}

export function toggleSpinner() {
    //console.log('toggleSpinner: ');
    return {
        type: TOGGLE_SPINNER
    }
}

export function setLoginFormErrorState(error) {
    //console.log('setLoginFormErrorState: ', error);
    const { email, non_field_errors, statusText } = error;
    const errorMessage = non_field_errors
        ? 'Correo o contraseña incorrecta.'
        : email
            ? 'Esta dirección de correo ya está en uso.'
            : statusText;
    //console.log('errorMessage: ', errorMessage);
    return {
        type: SET_LOGIN_FORM_ERROR_STATE,
        loginFormError: true,
        loginFormErrorMessage: errorMessage,
        startNovaPostAuthLoginEffect: false
    };
}

export function setSessionCookie(cookieValue) {
    //console.log('setSessionCookie: ', String(cookieValue));
    window.sessionStorage.setItem('requestToken', String(cookieValue));
    return {
        type: SET_SESSION_COOKIE,
        requestToken: cookieValue,
        userLogged: true
    };
}

export function setUserId(id) {
    //console.log('setUserId: ', id);
    return {
        type: SET_USER_ID,
        userId: id
    };
}

export function setFirestoreOrderData(orderData) {
    //console.log('setFirestoreOrderData: ', orderData);
    return {
        type: SET_FIRESTORE_ORDER_DATA,
        firestoreOrder: {
            ...orderData
        }
    };
}

export function setSelfpayNotificationScreen() {
    return {
        type: SET_SELFPAY_NOTIFICATION_SCREEN
    };
}

export function setSuccessfulOrderState() {
    //console.log('setSuccessfulOrderStatus: ');
    return {
        type: SET_SUCCESSFUL_ORDER_STATE
    };
}

export function setErrorOrderState(orderStatus) {
    //console.log('setErrorOrderState: ', orderStatus);
    return {
        type: SET_ERROR_ORDER_STATE,
        orderStatus
    };
}

export function setAwaitingUserPaymentOrderState(order) {
    const prevCurrencyOrder =  window.sessionStorage.getItem('currency');
    const { currency } = order;
    if(prevCurrencyOrder !== currency) {
        window.sessionStorage.setItem('currency', String(order.currency));
    }

    if(order && order.channel_prices) {
        window.sessionStorage.setItem('returnUrl', String(order.return_url));
        window.sessionStorage.setItem('txPrice', String(Number(order.price).toFixed(0)));
        window.sessionStorage.setItem('txStaticPrice', String(order.channel_prices.static));
        window.sessionStorage.setItem('txDeliveryPrice', String(order.channel_prices.delivery));
    }
    return {
        type: SET_AWAITING_USER_PAYMENT_ORDER_STATE,
        order
    };
}

export function setAwaitingAssignmentOrderState(order) {
    //console.log('setAwaitingAssignmentOrderState: ');
    const prevCurrencyOrder =  window.sessionStorage.getItem('currency');
    const { currency } = order;
    if(prevCurrencyOrder !== currency) {
        window.sessionStorage.setItem('currency', String(order.currency));
    }

    if(order && order.channel_prices) {
        window.sessionStorage.setItem('returnUrl', String(order.return_url));
        window.sessionStorage.setItem('txPrice', String(Number(order.price).toFixed(0)));
        window.sessionStorage.setItem('txStaticPrice', String(order.channel_prices.static));
        window.sessionStorage.setItem('txDeliveryPrice', String(order.channel_prices.delivery));
    }
    return {
        type: SET_AWAITING_ASSIGNMENT_ORDER_STATE,
        order
    };
}

export function setFixedProviderPointsData({ id, payload }) {
    const formattedProviders = payload.map((provider) => ({
        ...provider,
        name: provider.display_name || ""
    }))
    
    window.localStorage.setItem('fixedProviderPointsData', JSON.stringify({ providersId: id, providersData: formattedProviders }));
    return {
        type: SET_FIXED_PROVIDER_POINTS_DATA,
        fixedProviderPointsData: formattedProviders
    };
}

export function setMulticajaPointsData(multicajaData) {
    //console.log('setMulticajaPointsData: ', multicajaData);
    window.sessionStorage.setItem('multicajaData', JSON.stringify(multicajaData));
    return {
        type: SET_MULTICAJA_DATA,
        multicajaData
    };
}

export function setMulticajaMarkers(multicajaMarkers) {
    //console.log('setMulticajaMarkers: ', multicajaMarkers);

    //sessionStorage.setItem('multicajaMarkers', JSON.stringify(multicajaMarkers));
    return {
        type: SET_MULTICAJA_MARKERS,
        multicajaMarkers
    };
}

export function setStaticPointsData(staticPointsData) {
    //console.log('setStaticPointsData: ', staticPointsData);

    window.sessionStorage.setItem('staticPointsData', JSON.stringify(staticPointsData));
    return {
        type: SET_STATIC_MARKERS,
        staticPointsData
    };
}

export function setCustomerMarker(customerMarker){
    return {
        type: SET_CUSTOMER_MARKER,
        customerMarker
    };
}

export function setDeliveryAgentAvailability(deliveryAgentsAmount) {
    return {
        type: SET_DELIVERY_AGENT_AVAILABILITY,
        deliveryAgentsAmount,
        isDeliveryAgentAvailable: deliveryAgentsAmount > 0
    };
}

export function toggleAgentConnectionDrawer() {
    return {
        type: TOGGLE_AGENT_CONNECTION_DRAWER
    };
}

export function openChatDrawer() {
    return {
        type: OPEN_CHAT_DRAWER
    };
}

export function closeChatDrawer() {
    return {
        type: CLOSE_CHAT_DRAWER
    };
}

export function setChatSnapshots(chatSnapshots) {

    return {
        type: SET_CHAT_SNAPSHOTS,
        chatSnapshots
    };
}

export function setUserAddressDptoNumber(userDptoNumber) {
    return {
        type: SET_USER_ADDRESS_DPTO_NUMBER,
        userDptoNumber
    }
}

export function setAgentProfile(agentProfile) {
    return {
        type: SET_AGENT_PROFILE,
        agentProfile
    }
}

export function setAgentRating(agentRating) {
    return {
        type: SET_AGENT_RATING,
        agentRating
    };
}

export function clearState() {
    return {
        type: CLEAR_STATE
    };
}

export function setOrderToState(order) {
    const prevCurrencyOrder =  window.sessionStorage.getItem('currency');
    const prevCurrencySymbol = window.sessionStorage.getItem('currencySymbol');
    const prevCountryCode = window.sessionStorage.getItem('countryCode');

    const { currency, currency_symbol } = order;
    const { country_code } = order;

    if(prevCurrencyOrder !== currency) {
        window.sessionStorage.setItem('currency', String(order.currency));
    }
    if(prevCurrencySymbol !== currency_symbol) {
        window.sessionStorage.setItem('currencySymbol', String(order.currency_symbol));
    }

    if(prevCountryCode !== country_code) {
        window.sessionStorage.setItem('countryCode', String(order.country_code));
    }
    return {
        type: SET_ORDER_TO_STATE,
        order
    };
}

export function setInvalidEmailAssociatedToTheOrder(emailErrorMsg) {
    return {
        type: SET_INVALID_EMAIL_ASSOCIATED_TO_THE_ORDER,
        emailErrorMsg
    };
}

export function showLoginForm() {
    return {
        type: SHOW_LOGIN_FORM
    };
}

export function hideLoginForm() {
    return {
        type: HIDE_LOGIN_FORM
    };
}

export function setIncludedUserEmailFlow() {
    return {
        type: SET_INCLUDED_USER_EMAIL_FLOW
    };
}

export function setMapboxMapZoom(mapboxMapZoom) {
    //console.log('setZoom: ', mapboxMapZoom);
    return {
        type: SET_MAPBOX_MAP_ZOOM,
        mapboxMapZoom
    };
}

export function setEmailNotAllowedToContinue() {
    return {
        type: SET_EMAIL_NOT_ALLOWED_TO_CONTINUE,
        email: undefined,
        emailNotAllowedToContinue: true,
        emailNotAllowedToContinueMessage: 'La orden de compra ya se encuentra asignada a otro correo electrónico, por favor vuelva a ingresar con el correo asignado.'
    };
}

export function setUserPosCode(code) {
    window.sessionStorage.setItem('userPosCode', code);
    return {
        type: SET_USER_POS_CODE,
        userPosCode: code
    }
}










export function startNovaPostAuthLoginEffect(email, password, selfpay) {
    return {
        type: START_NOVA_POST_AUTH_LOGIN_EFFECT,
        email,
        password,
        selfpay
    }
}

export function endNovaPostAuthLoginEffect() {
    return {
        type: END_NOVA_POST_AUTH_LOGIN_EFFECT
    };
}

export function startNovaPostUnverifiedLoginEffect(email) {
    //console.log('startNovaPostUnverifiedLoginEffect: ', email);
    return {
        type: START_NOVA_POST_UNVERIFIED_LOGIN,
        email
    };
}

export function endNovaPostUnverifiedLoginEffect() {
    return {
        type: END_NOVA_POST_UNVERIFIED_LOGIN
    };
}


export function startNovaPostUsersRegistrationEffect() {
    return {
        type: START_NOVA_POST_USERS_REGISTRATION_EFFECT,
    };
}

export function endNovaPostRegistrationEffect() {
    return {
        type: END_NOVA_POST_USERS_REGISTRATION_EFFECT
    };
}

export function startNovaPostDeliveryIntentEffect() {
    return {
        type: START_NOVA_POST_DELIVERY_INTENT_EFFECT
    };
}

export function endNovaPostDeliveryIntentEffect() {
    return {
        type: END_NOVA_POST_DELIVERY_INTENT_EFFECT
    };
}

export function startNovaGetAuthUserEffect() {
    //console.log('startNovaGetAuthUserEffect: ', );
    return {
        type: START_NOVA_GET_AUTH_USER_EFFECT
    };
}

export function endNovaGetAuthUserEffect() {
    return {
        type: END_NOVA_GET_AUTH_USER_EFFECT
    };
}

export function startNovaGetUsersOrderEffect() {
    return {
        type: START_NOVA_GET_USERS_ORDER_EFFECT
    };
}

export function endNovaGetUsersOrderEffect() {
    return {
        type: END_NOVA_GET_USERS_ORDER_EFFECT
    };
}

export function startNovaHistoryEffect(destination) {
    return {
        type: START_NOVA_HISTORY_EFFECT,
        historyDestination: destination
    };
}

export function endNovaHistoryEffect() {
    return {
        type: END_NOVA_HISTORY_EFFECT
    };
}

export function startNovaPostOrdersSelfpayEffect() {
    return {
        type: START_NOVA_POST_ORDERS_SELFPAY_EFFECT
    };
}

export function endNovaPostOrdersSelfpayEffect() {
    return {
        type: END_NOVA_POST_ORDERS_SELFPAY_EFFECT
    };
}

export function startNovaGetMulticajaPointsEffect() {
    return {
        type: START_NOVA_GET_MULTICAJA_POINTS_EFFECT
    };
}

export function endNovaGetMulticajaPointsEffect() {
    return {
        type: END_NOVA_GET_MULTICAJA_POINTS_EFFECT
    };
}

export function startNovaPostUsersOrdersEffect() {
    return {
        type: START_NOVA_POST_USERS_ORDERS_EFFECT
    };
}

export function endNovaPostUsersOrdersEffect() {
    //console.log('endNovaPostUsersOrdersEffect: !!!!');
    return {
        type: END_NOVA_POST_USERS_ORDERS_EFFECT
    };
}

export function startNovaPostUsersPosEffect() {
    //console.log('startNovaPostUsersPosEffect ACTION: ');
    return {
        type: START_NOVA_POST_USERS_POS_EFFECT
    };
}

export function endNovaPostUsersPosEffect() {
    //console.log('endNovaPostUsersPosEffect ACTION: ');
    return {
        type: END_NOVA_POST_USERS_POS_EFFECT
    };
}

export function startNovaPostUsersCancelOrderEffect() {
    return {
        type: START_NOVA_POST_USERS_CANCEL_ORDER_EFFECT
    };
}

export function endNovaPostUsersCancelOrderEffect() {
    return {
        type: END_NOVA_POST_USERS_CANCEL_ORDER_EFFECT
    };
}

export function startNovaPostUsersRetryOrderEffect() {
    return {
        type: START_NOVA_POST_USERS_RETRY_ORDER_EFFECT
    };
}

export function endNovaPostUsersRetryOrderEffect() {
    return {
        type: END_NOVA_POST_USERS_RETRY_ORDER_EFFECT
    };
}

export function startNovaGetUsersNearbyEffect() {
    return {
        type: START_NOVA_GET_USERS_NEARBY_EFFECT
    };
}

export function endNovaGetUsersNearbyEffect() {
    return {
        type: END_NOVA_GET_USERS_NEARBY_EFFECT
    };
}

export function startNovaGetProviderPointsEffect() {
    return {
        type: START_NOVA_GET_PROVIDER_POINTS_EFFECT
    };
}

export function endNovaGetProviderPointsEffect() {
    return {
        type: END_NOVA_GET_PROVIDER_POINTS_EFFECT
    };
}

export function startNovaPatchUsersOrdersOrderIdRate() {
    return {
        type: START_NOVA_PATCH_USERS_ORDERS_ORDERID_RATE
    };
}

export function endNovaPatchUsersOrdersOrderIdRate() {
    return {
        type: END_NOVA_PATCH_USERS_ORDERS_ORDERID_RATE
    };
}

export function startNovaPatchOrdersLocationEffect() {
    return {
        type: START_NOVA_PATCH_ORDERS_LOCATION_EFFECT
    };
}

export function endNovaPatchOrdersLocationEffect() {
    return {
        type: END_NOVA_PATCH_ORDERS_LOCATION_EFFECT
    };
}






export function startNovaCancelOrderProcess() {
    return {
        type: START_NOVA_CANCEL_ORDER_PROCESS
    };
}

export function endNovaCancelOrderProcess() {
    return {
        type: END_NOVA_CANCEL_ORDER_PROCESS
    };
}

export function startNovaRetryOrderProcess() {
    return {
        type: START_NOVA_RETRY_ORDER_PROCESS
    };
}

export function endNovaRetryOrderProcess() {
    return {
        type: END_NOVA_RETRY_ORDER_PROCESS
    };
}

export function startMapboxReverseGeocoderRequest(mapboxRef) {
    return {
        type: START_MAPBOX_REVERSE_GEOCODER_REQUEST,
        mapboxRef
    };
}


export function endMapboxReverseGeocoderRequest() {
    return {
        type: END_MAPBOX_REVERSE_GEOCODER_REQUEST
    };
}

export function startNovaUpdateGoogleAutocompleteValue() {
    return {
        type: START_NOVA_UPDATE_GOOGLE_AUTOCOMPLETE_VALUE,
    };
}

export function endNovaUpdateGoogleAutocompleteValue() {
    return {
        type: END_NOVA_UPDATE_GOOGLE_AUTOCOMPLETE_VALUE
    };
}

export function startGmapGeocoderEffect() {
    return {
        type: START_GMAPS_GEOCODER_EFFECT
    };
}

export function endGmapGeocoderEffect() {
    return {
        type: END_GMAPS_GEOCODER_EFFECT
    };
}

export function startNovaUpdateCustomerPinCoords() {
    return {
        type: START_NOVA_UPDATE_CUSTOMER_PIN_COORDS
    };
}

export function endNovaUpdateCustomerPinCoords() {
    return {
        type: END_NOVA_UPDATE_CUSTOMER_PIN_COORDS
    };
}
