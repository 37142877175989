import { useContext, useEffect, useState }      from 'react';
import { NovaContext}                           from '../ContextProviders/Nova/NovaContext';
import * as novaActions                         from '../ContextProviders/Nova/actions';
export default function useGoogleMapsAutocompleteEffect(autocompleteRef) {
    const {
        dispatch
    } = useContext(NovaContext);
    const [loaded, setLoaded] = useState(false);
    useEffect(function searchBoxEffect() {
        if (autocompleteRef.current && window.google && !loaded) {
            const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current, {
                types: ['address'],
                fields: ['formatted_address', 'geometry']
            });
            autocomplete.addListener('place_changed', () => {
                const places = autocomplete.getPlace();
                if (places.geometry) {
                    dispatch(novaActions.setUserAddress(places.formatted_address, { latitude: places.geometry.location.lat(), longitude: places.geometry.location.lng() }));
                    dispatch(novaActions.setMapboxMapZoom(14));
                    dispatch(novaActions.startNovaUpdateCustomerPinCoords());
                } else {
                    dispatch(novaActions.setUserAddressError());
                }
            });
            setLoaded(true);
        }
    }, [
        loaded,
        autocompleteRef,
        dispatch
    ]);
};
