import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import * as novaActions from '../ContextProviders/Nova/actions';
import { NOVA_SERVER_URL } from '../../constants'

export default function useNovaAuthUserEffect(uid) {
    const {
        state,
        dispatch
    } = useContext(NovaContext);
    const {
        requestToken,
        startNovaGetAuthUserEffect,
        order,
        firestoreOrder,
        selfpay
    } = state;
    useEffect(function novaAuthUserEffect() {
        function dispatchActions(response){
            const { status, id, statusText } = response;
            const actions = {
                200: function handle200novaAuthUserRequestResponse() {
                    dispatch(novaActions.setUserId(id));
                    dispatch(novaActions.endNovaGetAuthUserEffect());
                    //dispatch(novaActions.startNovaGetUsersOrderEffect());
                    return selfpay
                        ? dispatch(novaActions.startNovaPostOrdersSelfpayEffect())
                        : order.status === 'successful'
                            ? dispatch(novaActions.setSuccessfulOrderState())
                            : order.status === 'awaiting_assignment'
                                ?
                                (
                                    dispatch(novaActions.setAwaitingAssignmentOrderState(order)),
                                    dispatch(novaActions.startNovaHistoryEffect('userLocation'))
                                )
                                : order.status === 'awaiting_user_payment'
                                    ?
                                    (
                                        dispatch(novaActions.setAwaitingUserPaymentOrderState(order)),
                                        dispatch(novaActions.startNovaHistoryEffect('userLocation'))
                                    )
                                    : dispatch(novaActions.setErrorOrderState(order.status));
                },
                400: function handle400novaAuthUserRequestResponse() {
                    dispatch(novaActions.setLoginFormErrorState({ statusText }));
                    dispatch(novaActions.endNovaGetAuthUserEffect());
                }
            };
            return (typeof actions[status] == 'function')
                ?   actions[status]()
                :   console.log('Unable to continue running Nova from novaAuthUserEffect(): ', response);
        }

        const authToken = function authToken(token) {
            return `Token ${token}`
        };

        const novaAuthUserRequest = async function novaAuthUserRequest() {
            const novaAuthUserRequestResponse = await fetch(`${NOVA_SERVER_URL}/auth/user/`, {
                method  : 'GET',
                headers : {
                    'Authorization': `${authToken(requestToken)}`,
                    'Content-Type': 'application/json'
                },
            });
            const { status } = novaAuthUserRequestResponse;
            return (status === 200)
                ?   novaAuthUserRequestResponse.json()
                :   novaAuthUserRequestResponse;
        };
        if (startNovaGetAuthUserEffect && requestToken) novaAuthUserRequest()
            .then(response => {
                //console.log('novaAuthUserRequest: ', response);
                const { status } = response;
                return status
                    ? dispatchActions(response)
                    : dispatchActions({ status: 200, ...response });
            });
    }, [
        requestToken,
        startNovaGetAuthUserEffect,
        dispatch,
        uid,
        order,
        selfpay,
        firestoreOrder
    ]);
}
