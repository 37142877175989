import React                                from 'react';
import { BrowserRouter as Router, Route }   from 'react-router-dom';
import LoginFormStepContainer               from '../Components/Steps/LoginFormStep/LoginFormStepContainer';
import UserLocationStepContainer            from '../Components/Steps/UserLocationStep/UserLocationStepContainer';
import AgentSelectionStepContainer          from '../Components/Steps/AgentSelectionStep/AgentSelectionStepContainer';
import FixedAgentConnectionStepContainer    from '../Components/Steps/FixedAgentConnectionStep/FixedAgentConnectionStepContainer';
import DeliveryAgentConnectionStepContainer from '../Components/Steps/DeliveryAgentConnectionStep/DeliveryAgentConnectionStepContainer';
import NoUID                                from '../Components/Routes/NoUID/NoUID';
import FinalOrderStatus                     from '../Components/Routes/FinalOrderStatus/FinalOrderStatus';
import QrCodeContainer                      from "../Components/Steps/QrCode/QrCodeContainer";

const Routes = () => (
    <Router>
        <Route exact path='/' component={NoUID}/>
        <Route exact path='/:UID' component={LoginFormStepContainer}/>
        <Route exact path='/:UID/userLocation' component={UserLocationStepContainer}/>
        <Route exact path='/:UID/agentSelection' component={AgentSelectionStepContainer}/>
        <Route exact path='/:UID/fixedAgentConnection' component={FixedAgentConnectionStepContainer}/>
        <Route exact path='/:UID/deliveryAgentConnection' component={DeliveryAgentConnectionStepContainer}/>
        <Route exact path='/:UID/:status' component={FinalOrderStatus}/>
        <Route exact path='/:UID/qr-code' component={QrCodeContainer}/>
    </Router>
);

export default Routes;
