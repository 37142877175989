import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import * as novaActions from '../ContextProviders/Nova/actions';

export default function useNovaHistoryEffect(history, UID) {
    const {
        state,
        dispatch
    } = useContext(NovaContext);
    const {
        startNovaHistoryEffect,
        historyDestination,
    } = state;
    useEffect(function novaHistoryEffect() {
        if(startNovaHistoryEffect) {
            history.push(`/${UID}/${historyDestination}`);
            dispatch(novaActions.endNovaHistoryEffect());
        }
    }, [history, startNovaHistoryEffect, historyDestination, UID, dispatch]);
}