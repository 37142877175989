// REACT BASE
import React, { useState, useEffect }                   from 'react';
import PropTypes                                        from 'prop-types';
import {  makeStyles, createStyles }                    from '@material-ui/core';
// REACT CONTEXT
import * as novaActions                                 from '../../../ContextProviders/Nova/actions';
import { NovaContext }                                  from '../../../ContextProviders/Nova/NovaContext';

// MATERIAL UI
import Typography                                       from '@material-ui/core/Typography';
import CircularProgress                                 from '@material-ui/core/CircularProgress';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button                                           from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChatIcon from '@material-ui/icons/Chat';

import Ratings from 'react-ratings-declarative';
import Badge from '@material-ui/core/Badge';

import chime from '../../../../assets/sounds/chime.mp3';

// HOOKS
import useNovaConsumerUpdateFirestorePositionEffect     from '../../../Hooks/useNovaConsumerUpdateFirestorePosition';
import useNovaPostUsersOrdersEffect                     from '../../../Hooks/useNovaPostUsersOrders';
import useNovaFirestoreDocEffect                        from '../../../Hooks/useNovaFirestoreDoc';
import useNovaPostUsersCancelOrderEffect                from '../../../Hooks/useNovaPostUsersCancelOrder';
import useNovaPostUsersRetryOrderEffect                 from '../../../Hooks/useNovaPostUsersRetryOrder';
import useNovaGetUsersOrderOrderIdAgentEffect           from '../../../Hooks/useNovaGetUsersOrderOrderIdAgent';
import useNovaPostDeliveryIntentEffect                  from '../../../Hooks/useNovaPostDeliveryIntent';


// ASSETS
import cancelOrderImage from '../../../../assets/images/cancelOrder.png';
import retryOrderImage from '../../../../assets/images/retryOrder.png';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { FIRESTORE_COLLECTION } from '../../../../constants'

// LAZY COMPONENTS
const Chat                          =   React.lazy(() => import('../../Shared/Chat/Chat'));
const Mapbox                        =   React.lazy(() => import('../../Shared/Mapbox/Mapbox'));

const chimeSound = new Audio(chime);

const useStyles = makeStyles(theme => createStyles({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignContent: 'center',
        alignItems: 'center'
    },
    spinnerContainer: {
        display         : 'flex',
        flexFlow        : 'column nowrap',
        justifyContent  : 'center',
        alignItems      : 'center',
        alignContent    : 'center',
        height          : '100%'
    },
    layoutContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyItems: 'center',
        alignItems: 'center',
        [theme.breakpoints.up(800)]: {
            flexFlow: 'row nowrap',
            height: 600
        }
    },
    deliveryAgentMapContainer: {
        position: 'relative',
        height: 320,
        width: '100%',
        [theme.breakpoints.up(800)]: {
            order: 2,
            height: '100%',
        }
    },
    deliveryAgentContent: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        [theme.breakpoints.up(800)]: {
            width: 500,
            justifyContent: 'center'
        }
    },
    titleCss: {
        marginTop       : 20,
        fontSize        : 22,
        fontWeight      : 400,
        color           : 'dimgray',
        [theme.breakpoints.up(800)]: {
            marginTop: 0
        }
    },
    subtitleCss : {
        fontSize        : 14,
        fontWeight      : 400,
        marginBottom    : 18,
        marginTop       : 8,
        color           : 'dimgray',
        textAlign       : 'center',
        width           : 300,
        [theme.breakpoints.up(800)]: {
            marginTop: 16,
            width: 262,
        }
    },
    chatButtonCss: {
        background: '#06BA54',
        height: 81,
        minWidth: '100%',
        [theme.breakpoints.up(800)]: {
            position: 'absolute',
            bottom: -112
        }
    },
    formControlLabelStyles: {
        background: '#ebfef4',
        padding: '16px 0 16px 16px',
        marginLeft: 0,
        borderRadius: 10
    },
    labelStyles: {
        color: 'rgba(0, 0, 0, 0.54)',
        width: '100%',
        textAlign: 'justify',
        fontSize: 14,
        marginRight: 8,
    },
    switchContainer: {
        width: '80%',
        marginTop: 48,
        [theme.breakpoints.up(800)]: {
            width: '60%',
        }
    },
    cancelButtonInstructionMessage: {
        fontSize        : 14,
        marginTop       : 58,
        marginLeft      : 12,
        marginBottom    : 8,
        width           : '75%',
        color           : 'crimson',
        background      : 'mistyrose',
        padding         : '16px 16px',
        borderRadius    : 6,
        textAlign       : 'justify',
        [theme.breakpoints.up(800)]: {
            width           : '57%',
        }
    },
    backButtonInstructionMessage: {
        fontSize        : 14,
        marginBottom    : 16,
        marginLeft      : 12,
        width           : '81%',
        color           : '#06BA54',
        //background      : 'mistyrose',
        padding         : '8px 16px',
        borderRadius    : 6,
        textAlign       : 'justify',
        [theme.breakpoints.up(800)]: {
            width           : '62%',
        }
    },
    loadingActionButtons: {
        display: 'flex',
        flexFlow: 'row nowrap',
        width: '82%',
        padding: '0 0 16px 16px',
        justifyContent: 'flex-end',
        [theme.breakpoints.up(800)]: {
            width: '63%',
        }
    },
    badgeCss: {
        background: 'crimson',
        color: 'white'
    }
}));

const DeliveryAgentConnectionStep = function DeliveryAgentConnectionStep({
    history,
    match
}) {

    const {
        root,
        spinnerContainer,
        layoutContainer,
        deliveryAgentMapContainer,
        deliveryAgentContent,
        titleCss,
        subtitleCss,
        chatButtonCss,
        formControlLabelStyles,
        labelStyles,
        switchContainer,
        cancelButtonInstructionMessage,
        loadingActionButtons,
        badgeCss
    } = useStyles();

    const {
        state,
        dispatch
    }   = React.useContext(NovaContext);

    const [enableFixedAgentWhenNoMobileAvailable, setEnableFixedAgentWhenNoMobileAvailable] = useState(false);
    const [disableSwitch, setDisableSwitch]                                                 = useState(false);
    const [goToFixedAgentSwitch, setGoToFixedAgentSwitch]                                   = useState(false);

    const { params: { UID } } = match;
    const {
        order,
        firestoreOrder,
        showDeliveryConnectionLoader,
        initialUserPosition,
        userSelectedAddress,
        userSelectedAddressCoords,
        showCancelOrderWarningMessage,
        showDeliveryAgentConnection,
        showRetryOrderMessage,
        agentProfile,
        firestoreDb,
        deliveryAgentsAmount
    } = state;

    const { channel_prices } = order;
    const { status } = firestoreOrder;

    const txDeliveryPrice = window.sessionStorage.getItem('txDeliveryPrice');

    const agentLatitude = function agentLatitude(firestoreOrderData) {
        return firestoreOrderData && firestoreOrderData.agent_latitude
            ? Number(firestoreOrderData.agent_latitude)
            : undefined; //-33.4073725;
    };

    const agentLongitude = function agentLongitude(firestoreOrderData) {
        return firestoreOrderData && firestoreOrderData.agent_longitude
            ? Number(firestoreOrderData.agent_longitude)
            : undefined; //-70.5379947;
    };

    const consumerLatitude = function (selectedAddress, selectedAddressCoords, initialPosition, fallbackLatitude) {
        return selectedAddress && selectedAddressCoords
            ? selectedAddressCoords.latitude
            : initialPosition && initialPosition.coords
                ? initialPosition.coords.latitude
                : fallbackLatitude;
    };

    const consumerLongitude = function(selectedAddress, selectedAddressCoords, initialPosition, fallbackLongitude) {
        return selectedAddress && selectedAddressCoords
            ? selectedAddressCoords.longitude
            : initialPosition && initialPosition.coords
                ? initialPosition.coords.longitude
                : fallbackLongitude;
    };

    const handleRetryButtonClick                =   function handleRetryButtonClick(event) {
        event.preventDefault();
        return dispatch(novaActions.startNovaRetryOrderProcess());
    };

    const handleCancelButtonClick               =   function handleCancelButtonClick(event) {
        event.preventDefault();
        return dispatch(novaActions.startNovaCancelOrderProcess());
    };

    const handleCancelOrderButtonBackClick      =   function handleCancelOrderButtonBackClick(event) {
        event.preventDefault();
        return dispatch(novaActions.endNovaCancelOrderProcess());
    };

    const handleCancelOrderButtonCancelClick    =   function handleCancelOrderButtonCancelClick(event) {
        event.preventDefault();
        return dispatch(novaActions.startNovaPostUsersCancelOrderEffect());
    };

    const handleRetryOrderButtonBackClick       =   function handleCancelOrderButtonBackClick(event) {
        event.preventDefault();
        return dispatch(novaActions.endNovaRetryOrderProcess());
    };

    const handleRetryOrderButtonRetryClick      =   function handleCancelOrderButtonRetryClick(event) {
        event.preventDefault();
        return dispatch(novaActions.startNovaPostUsersRetryOrderEffect());
    };

    const handleChatButtonClick                 =   function handleChatButtonClick(event) {
        event.preventDefault();
        dispatch(novaActions.openChatDrawer());
    };

    const handleSwitchControl               = function handleSwitchControl(event) {
        event.preventDefault();
        setEnableFixedAgentWhenNoMobileAvailable(!enableFixedAgentWhenNoMobileAvailable);
        setDisableSwitch(!disableSwitch);
        history.push(`/${UID}/fixedAgentConnection`);
    };

    const TIMEOUT = deliveryAgentsAmount === 0 ? 5000 : 60000;

    useEffect(function timeoutEffect() {
        setTimeout(function timeout() {
            setGoToFixedAgentSwitch(true);
        }, TIMEOUT);
    });

    useNovaFirestoreDocEffect(UID);
    useNovaConsumerUpdateFirestorePositionEffect(UID);
    useNovaPostUsersOrdersEffect(UID);
    useNovaPostUsersCancelOrderEffect(UID);
    useNovaPostUsersRetryOrderEffect(UID);
    useNovaGetUsersOrderOrderIdAgentEffect(UID, status);
    useNovaPostDeliveryIntentEffect(UID);

    useEffect(function useChatEffect() {
        const chatSnapshots = [];
        const chatIds = [];
        const chatEffect    =   firestoreDb
            .collection(FIRESTORE_COLLECTION)
            .doc(UID)
            .collection('chat')
            .orderBy('date', 'asc')
            .onSnapshot(snapshot => {
                snapshot.docChanges().forEach(change => {
                    if(change.type === 'added') {
                        if(chatIds.indexOf(change.doc.id) === -1) {
                            if(change.doc.data().sender === 'agent') {
                                const soundPromise = chimeSound.play();
                                if (soundPromise !== undefined) {
                                    soundPromise.then(() => {
                                        chimeSound.play();
                                    }).catch(error => {
                                        console.log('No access to play chime sound: ', error);
                                    });
                                }
                                dispatch(novaActions.openChatDrawer());
                            }
                            chatIds.push(change.doc.id);
                            chatSnapshots.push(change.doc.data());
                        }

                    }
                });
                dispatch(novaActions.setChatSnapshots(chatSnapshots));
            });
        return () => chatEffect();
    }, [UID, dispatch, firestoreDb]);

    return(
        <section className={root}>
            {
                showDeliveryConnectionLoader || !(agentLatitude(firestoreOrder) && agentLongitude(firestoreOrder)) || (status === 'awaiting_assignment')
                    ?
                    (
                        <section style={{
                            marginTop         : 32,
                            marginBottom      : 12,
                            display           : 'flex',
                            flexFlow          : 'column nowrap',
                            background        : 'white',
                            alignItems        : 'center'
                        }}>
                            <Typography
                                style={{
                                    fontSize        : 16,
                                    marginTop       : 12,
                                    marginBottom    : 64,
                                    color           : 'black'
                                }}>
                                Buscando Socio46 delivery de PAGO46
                            </Typography>
                            <section className={spinnerContainer}>
                                <CircularProgress
                                    style={{
                                        color: '#06BA54',
                                        marginBottom: '4%'
                                    }}
                                    size={50}
                                    thickness={3}/>
                            </section>
                            {
                                goToFixedAgentSwitch
                                    ?
                                    (
                                        <section className={switchContainer}>
                                            <FormControlLabel
                                                className={formControlLabelStyles}
                                                control={
                                                    <Switch
                                                        color='primary'
                                                        checked={enableFixedAgentWhenNoMobileAvailable}
                                                        onChange={handleSwitchControl}/>
                                                }
                                                classes={{ label: labelStyles }}
                                                label={`Activa esta opción si prefieres ir a un lugar cercano a realizar el pago`}
                                                labelPlacement='start'
                                            />
                                        </section>
                                    )
                                    :   null
                            }
                            {
                                !goToFixedAgentSwitch
                                    ?
                                    (
                                        <Typography
                                            className={cancelButtonInstructionMessage}>
                                            Utiliza el botón <strong>cancelar</strong> si necesitas <strong>volver a la tienda sin terminar</strong> el pago
                                        </Typography>
                                    )
                                    :   null
                            }
                            <section className={loadingActionButtons}>
                                <Button
                                    onClick={handleCancelOrderButtonCancelClick}
                                    style={{
                                        background      : 'crimson',
                                        color           : 'white',
                                        textTransform   : 'uppercase',
                                        width: '36%',
                                        height: 36,
                                        margin: `${goToFixedAgentSwitch ? '36px 4px 0 4px' : '20px 4px 0 4px'}`
                                        //fontSize: 2
                                    }}
                                    variant='contained'
                                    fullWidth>
                                    cancelar
                                </Button>
                            </section>
                        </section>
                    )
                    :   null
            }
            {
                showCancelOrderWarningMessage
                    ?
                        (
                            <section style={{
                                display         : 'flex',
                                flexFlow        : 'column nowrap',
                                justifyContent  : 'space-between',
                                height          : '100%',
                                padding         : '32px 32px 28px 32px'
                            }}>
                                <section
                                    style={{
                                        display     : 'flex',
                                        flexFlow    : 'column nowrap',
                                        alignItems  : 'center'
                                    }}>
                                    <img
                                        style={{
                                            width   : 200,
                                            height  : 200
                                        }}
                                        src={cancelOrderImage}
                                        alt='Cancelar Pago'/>
                                    <Typography
                                        style={{
                                            marginTop   : 32
                                        }}>
                                        Ya no quieres pagar?
                                    </Typography>
                                    <Typography
                                        style={{
                                            marginTop   : 16,
                                            color       : 'rgba(0, 0, 0, 0.5)',
                                            fontSize    : 16
                                        }}>
                                        Utiliza el botón <strong>cancelar</strong> si necesitas <strong>volver a la tienda sin terminar</strong> el pago
                                    </Typography>
                                </section>
                                <section style={{
                                    display         : 'flex',
                                    flexFlow        : 'row nowrap',
                                    justifyContent  : 'flex-end',
                                    marginTop       : 24
                                }}>
                                    <Button
                                        style={{
                                            width: 100
                                        }}
                                        variant='outlined'
                                        onClick={handleCancelOrderButtonBackClick}>
                                        volver
                                    </Button>
                                    <Button
                                        style={{
                                            color: 'white',
                                            background: 'crimson',
                                            marginLeft: 8
                                        }}
                                        color='primary'
                                        variant='contained'
                                        onClick={handleCancelOrderButtonCancelClick}>
                                        cancelar
                                    </Button>
                                </section>
                            </section>
                        )
                    :   null
            }
            {
                showRetryOrderMessage
                    ?
                    (
                        <section style={{
                            display         : 'flex',
                            flexFlow        : 'column nowrap',
                            justifyContent  : 'space-between',
                            height          : '100%',
                            padding         : 32
                        }}>
                            <section
                                style={{
                                    display     : 'flex',
                                    flexFlow    : 'column nowrap',
                                    alignItems  : 'center'
                                }}>
                                <img
                                    style={{
                                        width   : 200,
                                        height  :  200
                                    }}
                                    src={retryOrderImage}
                                    alt='Cancelar Pago'/>
                                <Typography
                                    style={{
                                        marginTop: 32
                                    }}>
                                    ¿Te gustaría cambiar de Socio46?
                                </Typography>
                                <Typography
                                    style={{
                                        marginTop: 16,
                                        color       : 'rgba(0, 0, 0, 0.5)'
                                    }}>
                                    Si presionas <strong>cambiar</strong>, buscaremos un nuevo Socio46 para ti
                                </Typography>
                            </section>
                            <section style={{
                                display         : 'flex',
                                flexFlow        : 'row nowrap',
                                justifyContent  : 'flex-end',
                                marginTop       : 26
                            }}>
                                <Button
                                    variant='outlined'
                                    onClick={handleRetryOrderButtonBackClick}>
                                    volver
                                </Button>
                                <Button
                                    style={{
                                        marginLeft: 8,
                                        color: 'white',
                                        background: 'darkorange'
                                    }}
                                    variant='contained'
                                    color='primary'
                                    onClick={handleRetryOrderButtonRetryClick}>
                                    cambiar
                                </Button>
                            </section>
                        </section>
                    )
                    :   null
            }
            {
                showDeliveryAgentConnection && !(status === 'awaiting_assignment') && (agentLatitude(firestoreOrder) && agentLongitude(firestoreOrder))
                    ?
                    (
                        <section className={layoutContainer}>
                            <section className={deliveryAgentMapContainer}>
                                <Mapbox
                                    consumerLatitude={consumerLatitude(userSelectedAddress, userSelectedAddressCoords, initialUserPosition, 67.8653844)}
                                    consumerLongitude={consumerLongitude(userSelectedAddress, userSelectedAddressCoords, initialUserPosition, -73.808737)}
                                    agentLatitude={agentLatitude(firestoreOrder)}
                                    agentLongitude={agentLongitude(firestoreOrder)}
                                    deliveryAgentConnectionStep/>
                                <section
                                    style={{
                                        position        : 'absolute',
                                        bottom          : 0,
                                        height          : 64,
                                        background      : 'rgba(0, 0, 0, 0.6)',
                                        width           : '100%',
                                        color           : 'black',
                                        display         : 'flex',
                                        flexFlow        : 'row nowrap',
                                        justifyContent  : 'space-between',
                                        alignItems      : 'center',
                                        padding         : '8px 0'
                                    }}>
                                    <Typography
                                        style={{
                                            marginLeft: 16,
                                            width: 230,
                                            textAlign: 'left',
                                            color: 'white',
                                            fontSize: 14
                                        }}>
                                        Utiliza este botón para <strong style={{ fontWeight: 500 }}>RECHAZAR</strong> el pago de esta compra
                                    </Typography>
                                    <Button
                                        style={{
                                            background      : 'crimson',
                                            color           : 'white',
                                            textTransform   : 'uppercase',
                                            height          : 48,
                                            marginRight     : 16
                                        }}
                                        variant={'contained'}
                                        onClick={handleCancelButtonClick}>
                                        Rechazar
                                    </Button>
                                </section>
                            </section>
                            <section className={deliveryAgentContent}>
                                <Typography className={titleCss}>
                                    Completa tu transacción
                                </Typography>
                                <Typography className={subtitleCss}>
                                    Reuniéndote con tu Socio46 y muestra este código QR:
                                </Typography>
                                <Typography style={{
                                    textTransform   : 'uppercase',
                                    fontSize        : 24,
                                    color           : 'dimgray'
                                }}>
                                    {`${channel_prices ? channel_prices.currency : ''} $${channel_prices ? channel_prices.delivery : Number(txDeliveryPrice)}`}
                                </Typography>
                                <img
                                    style={{
                                        textAlign       : 'center',
                                        height          : 200,
                                        width           : 200,
                                        paddingBottom   : 32
                                    }}
                                    src={`https://us-central1-degrees-46.cloudfunctions.net/qr-generator?UID=${UID}`}
                                    alt='código qr'/>
                                <Chat
                                    UID={UID}
                                    handleRetryButtonClick={handleRetryButtonClick}>
                                    <Paper
                                        elevation={6}
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                        }}>
                                        <ButtonBase
                                            className={chatButtonCss}
                                            type='button'
                                            onClick={handleChatButtonClick}
                                            style={{
                                                display: 'flex',
                                                flexFlow: 'row column',
                                                justifyContent: 'space-between',
                                                padding: '0 16px'
                                            }}>
                                            {
                                                agentProfile
                                                    ?
                                                        (
                                                            <img
                                                                src={agentProfile.image}
                                                                alt='Imágen de perfil'
                                                                style={{
                                                                    width: 64,
                                                                    height: 64,
                                                                    borderRadius: '50%'
                                                                }}/>
                                                        )
                                                    :
                                                        (
                                                            <AccountCircleIcon
                                                                style={{
                                                                    width: 64,
                                                                    height: 64,
                                                                    color: 'white'
                                                                }}/>
                                                        )
                                            }
                                            {
                                                agentProfile
                                                    ?
                                                        (
                                                            <section>
                                                                <Typography
                                                                    variant='body1'
                                                                    style={{
                                                                        color: 'white'
                                                                    }}>
                                                                    {`${agentProfile.name} ${agentProfile.surname}`}
                                                                </Typography>
                                                                <Ratings
                                                                    rating={agentProfile ? agentProfile.rating : 0}
                                                                    widgetRatedColors='gold'
                                                                    widgetEmptyColors='white'
                                                                    widgetHoverColors='blue'>
                                                                    <Ratings.Widget
                                                                        widgetDimension='20px'
                                                                        widgetSpacing='2px'/>
                                                                    <Ratings.Widget
                                                                        widgetDimension='20px'
                                                                        widgetSpacing='2px'/>
                                                                    <Ratings.Widget
                                                                        widgetDimension='20px'
                                                                        widgetSpacing='2px'/>
                                                                    <Ratings.Widget
                                                                        widgetDimension='20px'
                                                                        widgetSpacing='2px'/>
                                                                    <Ratings.Widget
                                                                        widgetDimension='20px'
                                                                        widgetSpacing='2px'/>
                                                                </Ratings>
                                                            </section>
                                                        )
                                                    :   null
                                            }
                                            <section>
                                                <Badge
                                                    classes={{
                                                        badge: badgeCss
                                                    }}>
                                                    <ChatIcon
                                                        style={{
                                                            width: 36,
                                                            height: 36,
                                                            color: 'white'
                                                        }}
                                                    />
                                                </Badge>
                                            </section>
                                        </ButtonBase>
                                    </Paper>
                                </Chat>
                            </section>
                        </section>
                    )
                    :   null
            }
        </section>
    );
};

DeliveryAgentConnectionStep.propType = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    switchSpinnerMessage: PropTypes.string.isRequired,
    switchSuccessAccessMessage: PropTypes.string.isRequired,
    switchErrorAccessMessage: PropTypes.string.isRequired,
    switchAccessMessage: PropTypes.string.isRequired
};

export default DeliveryAgentConnectionStep;