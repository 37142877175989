import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import * as novaActions from '../ContextProviders/Nova/actions';
import { NOVA_SERVER_URL } from '../../constants'

export default function useNovaGetUsersOrderOrderIdAgentEffect(UID, status) {
    const {
        state,
        dispatch
    } = useContext(NovaContext);
    const {
        requestToken,
        firestoreDb
    } = state;

    useEffect(function novaGetUsersOrderOrderIdAgentEffect() {
        function dispatchActions(response) {
            const { status, profile } = response;
            const actions = {
                200: function handle200UsersOrderOrderIdAgentRequestResponse() {
                    dispatch(novaActions.setAgentProfile(profile))
                }
            };
            return (typeof actions[status] == 'function')
                ?   actions[status]()
                :   console.log('Unable to continue running Nova from useNovaGetUsersOrderOrderIdAgentEffect(): ', response);
        }

        const authToken = function authToken(token) {
            return `Token ${token}`
        };

        const usersOrderOrderIdAgentRequest = async function usersOrderOrderIdAgentRequest() {
            const usersOrderOrderIdAgentRequestResponse = await fetch(`${NOVA_SERVER_URL}/users/order/${UID}/agent`, {
                method  : 'GET',
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': `${authToken(requestToken)}`
                }
            });
            const { status } = usersOrderOrderIdAgentRequestResponse;
            return status === 200
                ?   usersOrderOrderIdAgentRequestResponse.json()
                :   usersOrderOrderIdAgentRequestResponse;
        };
        if (status === 'awaiting_user_payment' && requestToken) usersOrderOrderIdAgentRequest()
            .then(response => {
                const { status } = response;
                return !status
                    ? dispatchActions({ status: 200, profile: { ...response }})
                    : dispatchActions(response);
            });
    }, [
        requestToken,
        dispatch,
        firestoreDb,
        UID,
        status
    ]);
}