import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true
    },
    palette: {
        primary: {
            main: '#06BA54'
        },
        secondary: {
            main: '#FFFFFF'
        },
        error: red,
        text: {
            primary: '#777777'
        }
    }
});

theme.typography.h6 = {
    fontSize: '1em'
}

export default theme