import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import * as novaActions from '../ContextProviders/Nova/actions';
import { NOVA_SERVER_URL } from '../../constants'

export default function useNovaPatchOrdersLocationEffect(UID) {
    const {
        state,
        dispatch
    } = useContext(NovaContext);
    const {
        requestToken,
        startNovaPatchOrdersLocationEffect,
        userSelectedAddress,
        userSelectedAddressCoords,
        initialUserPosition
    } = state;

    useEffect(function novaPatchOrdersLocationEffect() {
        function dispatchActions(response) {
            const { status } = response;
            const actions = {
                200: function handle200OrdersLocationResponse() {
                    //console.log('200 handle200UsersOrdersLocationResponse: ', response);
                    return dispatch(novaActions.endNovaPatchOrdersLocationEffect())
                }
            };
            return (typeof actions[status] == 'function')
                ?   actions[status]()
                :   console.log('Unable to continue running Nova from novaPatchOrdersLocationEffect(): ', response);
        }

/*        const consumerLatitude      =   userSelectedAddress && userSelectedAddressCoords
            ? userSelectedAddressCoords.latitude
            : initialUserPosition && initialUserPosition.coords
                ? initialUserPosition.coords.latitude
                : -33.410525799999995;
        const consumerLongitude     =   userSelectedAddress && userSelectedAddressCoords
            ? userSelectedAddressCoords.longitude
            : initialUserPosition && initialUserPosition.coords
                ? initialUserPosition.coords.longitude
                : -70.541938;*/

        const type = userSelectedAddress && userSelectedAddressCoords ? 'confirmed' : 'detected';

        const consumerLatitude = function consumerLatitude(selectedAddress, selectedAddressCoords, initialPosition, fallbackLatitude) {
            return selectedAddress && selectedAddressCoords
                ? selectedAddressCoords.latitude
                : initialPosition && initialPosition.coords
                    ? initialPosition.coords.latitude
                    : fallbackLatitude;
        };

        const consumerLongitude = function consumerLongitude(selectedAddress, selectedAddressCoords, initialPosition, fallbackLongitude) {
            return selectedAddress && selectedAddressCoords
                ? selectedAddressCoords.longitude
                : initialPosition && initialPosition.coords
                    ? initialPosition.coords.longitude
                    : fallbackLongitude;
        };

        const authToken = function authToken(token) {
            return `Token ${token}`
        };

        const ordersLocationRequest = async function ordersLocationRequest() {
            return await fetch(`${NOVA_SERVER_URL}/users/orders/location/`, {
                method  : 'PATCH',
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': `${authToken(requestToken)}`
                },
                body: JSON.stringify({
                    order_id: UID,
                    latitude:  consumerLatitude(userSelectedAddress, userSelectedAddressCoords, initialUserPosition, -33.410525799999995),
                    longitude: consumerLongitude(userSelectedAddress, userSelectedAddressCoords, initialUserPosition, -70.541938),
                    type
                })
            });
        };
        if (startNovaPatchOrdersLocationEffect && requestToken) ordersLocationRequest()
            .then(response => dispatchActions(response));
    }, [
        requestToken,
        startNovaPatchOrdersLocationEffect,
        dispatch,
        UID,
        userSelectedAddress,
        userSelectedAddressCoords,
        initialUserPosition
    ]);
}