import React, { useEffect, useContext } from 'react';
import ProptTypes                       from 'prop-types';
import { NovaContext }                  from '../../../ContextProviders/Nova/NovaContext';
import { makeStyles, createStyles }     from '@material-ui/core';
import Paper                            from '@material-ui/core/Paper';
import QrCode                           from './QrCode';
import useNovaPostUsersPosEffect        from '../../../Hooks/useNovaPostUsersPos';

const OrderStatusWatcher =   React.lazy(() => import('../../Shared/OrderStatusWatcher/OrderStatusWatcher'));

const useStyles = makeStyles(theme => createStyles({
    root: {
        maxWidth: 480,
        margin: '0 auto',
        [theme.breakpoints.up(800)]: {
            maxWidth: 800,
        }
    }
}));

const QrCodeContainer = function QrCodeContainer({ history, match }) {

    const { root } = useStyles();
    const { params: { UID } } = match;
    const {
        state
    } = useContext(NovaContext);

    const {
        emailNotAllowedToContinue
    } = state;

    useNovaPostUsersPosEffect(UID);
    useEffect(function emailNotAllowedEffect(){
        if(emailNotAllowedToContinue) {
            window.localStorage.clear();
            history.push(`/${UID}/`);
        }
    }, [
        emailNotAllowedToContinue,
        UID,
        history
    ]);

    return(
        <Paper
            className={root}
            elevation={6}>
            <OrderStatusWatcher
                UID={UID}
                history={history}
                match={match}>
                <QrCode match={match}/>
            </OrderStatusWatcher>
        </Paper>
    );
};

QrCodeContainer.propTypes = {
    history: ProptTypes.object.isRequired,
    match: ProptTypes.object.isRequired
};

export default QrCodeContainer;
