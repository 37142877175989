import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import * as novaActions from '../ContextProviders/Nova/actions';
import { FIRESTORE_COLLECTION } from '../../constants'

export default function useNovaFirestoreDocEffect(UID) {
    const {
        state,
        dispatch
    } = useContext(NovaContext);
    const {
        firestoreDb,
        startNovaFirestoreDocEffect
    } = state;
    useEffect(function novaFirestoreDoc() {
        const unsuscribeToFirestore = firestoreDb
            .collection(FIRESTORE_COLLECTION)
            .doc(UID)
            .onSnapshot(doc => {
                dispatch(novaActions.setFirestoreOrderData({
                    ...doc.data(),
                    dataLoaded: true
                }))
            });
        return () => unsuscribeToFirestore();
    }, [startNovaFirestoreDocEffect, dispatch, firestoreDb, UID]);
}