import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import * as novaActions from '../ContextProviders/Nova/actions';
import { NOVA_SERVER_URL } from '../../constants'

export default function useNovaPostUsersPosEffect(UID) {
    const {
        state,
        dispatch
    } = useContext(NovaContext);
    const {
        requestToken,
        startNovaPostUsersPosEffect,
        initialUserPosition,
        userSelectedAddress,
        userSelectedAddressCoords
    } = state;

    useEffect(function novaPostUsersPosEffect() {
        function dispatchActions(response) {
            const { status, code } = response;
            const actions = {
                200: function handle200UsersPosRequestResponse() {
                    dispatch(novaActions.setUserPosCode(code));
                    dispatch(novaActions.endNovaPostUsersOrdersEffect());
                },
                400: function handle400UserPosRequestResponse() {
                    dispatch(novaActions.endNovaPostUsersOrdersEffect());
                    dispatch(novaActions.setEmailNotAllowedToContinue());
                }
            };
            return (typeof actions[status] == 'function')
                ?   actions[status]()
                :   console.log('Unable to continue running Nova from novaPostUsersPosEffect(): ', response);
        }

        const consumerLatitude = function consumerLatitude(selectedAddress, selectedAddressCoords, initialPosition, fallbackLatitude) {
            return selectedAddress && selectedAddressCoords
                ? selectedAddressCoords.latitude
                : initialPosition && initialPosition.coords
                    ? initialPosition.coords.latitude
                    : fallbackLatitude;
        };

        const consumerLongitude = function consumerLongitude(selectedAddress, selectedAddressCoords, initialPosition, fallbackLongitude) {
            return selectedAddress && selectedAddressCoords
                ? selectedAddressCoords.longitude
                : initialPosition && initialPosition.coords
                    ? initialPosition.coords.longitude
                    : fallbackLongitude;
        };

        const authToken = function authToken(token) {
            return `Token ${token}`
        };

        const usersPosRequest = async function usersPosRequest() {
            const usersPosRequestResponse = await fetch(`${NOVA_SERVER_URL}/users/pos/`, {
                method  : 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': `${authToken(requestToken)}`
                },
                body: JSON.stringify({
                    order_id: UID,
                    latitude: consumerLatitude(userSelectedAddress, userSelectedAddressCoords, initialUserPosition, -33.410525799999995),
                    longitude: consumerLongitude(userSelectedAddress, userSelectedAddressCoords, initialUserPosition, -70.541938)
                })
            });
            const { status } = usersPosRequestResponse;
            return status === 200
                ?   usersPosRequestResponse.json()
                :   usersPosRequestResponse;
        };
        if (startNovaPostUsersPosEffect && requestToken) usersPosRequest()
            .then(response => {
                const { status } = response;
                return (typeof status === 'undefined')
                    ? dispatchActions({ status: 200, ...response } )
                    : dispatchActions(response);
            });
    }, [
        requestToken,
        startNovaPostUsersPosEffect,
        dispatch,
        UID,
        userSelectedAddress,
        userSelectedAddressCoords,
        initialUserPosition
    ]);
}
