import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import { FIRESTORE_COLLECTION } from '../../constants'

export default function useNovaConsumerUpdatePositionProviderEffect(UID) {
    const {
        state,
    } = useContext(NovaContext);
    const {
        firestoreDb,
        startNovaConsumerUpdatePositionProviderEffect,
        userAddress,
        userDptoNumber,
        initialUserPosition,
        userSelectedAddress,
        userSelectedAddressCoords
    } = state;

    useEffect(function novaFirestoreDoc() {
        /*    const consumerLatitude      =   userSelectedAddress && userSelectedAddressCoords
        ? userSelectedAddressCoords.latitude
        : initialUserPosition && initialUserPosition.coords
            ? initialUserPosition.coords.latitude
            : -33.410525799999995;
    const consumerLongitude     =   userSelectedAddress && userSelectedAddressCoords
        ? userSelectedAddressCoords.longitude
        : initialUserPosition && initialUserPosition.coords
            ? initialUserPosition.coords.longitude
            : -70.541938;*/

        const consumerLatitude = function consumerLatitude(selectedAddress, selectedAddressCoords, initialPosition, fallbackLatitude) {
            return selectedAddress && selectedAddressCoords
                ? selectedAddressCoords.latitude
                : initialPosition && initialPosition.coords
                    ? initialPosition.coords.latitude
                    : fallbackLatitude;
        };

        const consumerLongitude = function consumerLongitude(selectedAddress, selectedAddressCoords, initialPosition, fallbackLongitude) {
            return selectedAddress && selectedAddressCoords
                ? selectedAddressCoords.longitude
                : initialPosition && initialPosition.coords
                    ? initialPosition.coords.longitude
                    : fallbackLongitude;
        };

        if (startNovaConsumerUpdatePositionProviderEffect) {
            firestoreDb
                .collection(FIRESTORE_COLLECTION)
                .doc(UID)
                .update({
                    user_connected: true,
                    userAddress: `${userAddress} ${userDptoNumber ? `- Dpto N°: ${userDptoNumber}` : ''}`,
                    confirmed_latitude: consumerLatitude(userSelectedAddress, userSelectedAddressCoords, initialUserPosition, -33.410525799999995),
                    confirmed_longitude: consumerLongitude(userSelectedAddress, userSelectedAddressCoords, initialUserPosition, -70.541938)
                });
            const stopConsumerUpdates = function stopConsumerUpdates(event) {
                event.preventDefault();
                return firestoreDb
                    .collection(FIRESTORE_COLLECTION)
                    .doc(UID)
                    .update({
                        user_connected  : null,
                        userAddress     : null,
                        confirmed_latitude: null,
                        confirmed_longitude: null
                    });
            };
            window.addEventListener('beforeunload', stopConsumerUpdates);
            return () => window.removeEventListener('beforeunload', stopConsumerUpdates);
        }
    }, [
        startNovaConsumerUpdatePositionProviderEffect,
        firestoreDb,
        UID,
        userDptoNumber,
        userAddress,
        userSelectedAddress,
        userSelectedAddressCoords,
        initialUserPosition
    ]);
}