import { useEffect } from 'react';
import { GOOGLE_MAPS_API_KEY } from '../../constants'

export default function useGoogleMapsApiLoadEffect() {
    useEffect(function googleMapsApiLoadEffect() {
        const loadGoogleMaps = function loadGoogleMaps() {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://maps.google.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=geometry,places`;
            const headScript = document.getElementsByTagName('script')[0];
            headScript.parentNode.insertBefore(script, headScript);
        };
        if(!window.google) loadGoogleMaps();
    }, []);
}