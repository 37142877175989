export const TOGGLE_SEARCH_PROVIDER                         =   'TOGGLE_SEARCH_PROVIDER'
export const SET_USER_EMAIL                                 =   'SET_USER_EMAIL';
export const SET_USER_EMAIL_ERROR                           =   'SET_USER_EMAIL_ERROR';
export const CLEAR_EMAIL_ERROR_STATE                        =   'CLEAR_EMAIL_ERROR_STATE';
export const TOGGLE_AGENT_LOGIN_SCREEN                      =   'TOGGLE_AGENT_LOGIN_SCREEN';
export const SHOW_AGENT_LOGIN_SCREEN                        =   'SHOW_AGENT_LOGIN_SCREEN';
export const TOGGLE_SWITCH_SPINNER                          =   'TOGGLE_SWITCH_SPINNER';
export const SET_USER_INITIAL_POSITION                      =   'SET_USER_INITIAL_POSITION';
export const SET_USER_INITIAL_POSITION_ERROR                =   'SET_USER_INITIAL_POSITION_ERROR';
export const SET_GOOGLE_MAPS_API_TO_STATE                   =   'SET_GOOGLE_MPS_API_TO_STATE';
export const SET_LOGIN_SCREEN                               =   'SET_LOGIN_SCREEN';
export const SET_AGENT_LOGIN_PASSWORD                       =   'SET_AGENT_LOGIN_PASSWORD';
export const SET_USER_ADDRESS                               =   'SET_USER_ADDRESS';
export const SET_USER_ADDRESS_ERROR                         =   'SET_USER_ADDRESS_ERROR';
export const SET_SELECTED_AGENT_TYPE                        =   'SET_SELECTED_AGENT_TYPE';
export const TOGGLE_SPINNER                                 =   'TOGGLE_SPINNER';
export const SET_LOGIN_FORM_ERROR_STATE                     =   'SET_LOGIN_FORM_ERROR_STATE';
export const SET_SESSION_COOKIE                             =   'SET_SESSION_COOKIE';
export const SET_USER_ID                                    =   'SET_USER_ID';
export const SET_FIRESTORE_ORDER_DATA                       =   'SET_FIRESTORE_ORDER_DATA';
export const SET_SELFPAY_NOTIFICATION_SCREEN                =   'SET_SELFPAY_NOTIFICATION_SCREEN';
export const SET_SUCCESSFUL_ORDER_STATE                     =   'SET_SUCCESSFUL_TRANSACTION_ORDER_STATE';
export const SET_ERROR_ORDER_STATE                          =   'SET_ERROR_ORDER_STATE';
export const SET_AWAITING_USER_PAYMENT_ORDER_STATE          =   'SET_AWAITING_USER_PAYMENT_ORDER_STATE';
export const SET_AWAITING_ASSIGNMENT_ORDER_STATE            =   'SET_AWAITING_ASSIGNMENT_ORDER_STATE';

export const SET_FIXED_PROVIDER_POINTS_DATA                 =   'SET_FIXED_PROVIDER_POINTS_DATA';

export const SET_MULTICAJA_DATA                             =   'SET_MULTICAJA_DATA';
export const SET_MULTICAJA_MARKERS                          =   'SET_MULTICAJA_MARKERS';
export const SET_STATIC_MARKERS                             =   'SET_STATIC_MARKERS';
export const SET_CUSTOMER_MARKER                            =   'SET_CUSTOMER_MARKER';
export const SET_DELIVERY_AGENT_AVAILABILITY                =   'SET_DELIVERY_AGENT_AVAILABILITY';
export const TOGGLE_AGENT_CONNECTION_DRAWER                 =   'TOGGLE_AGENT_CONNECTION_DRAWER';
export const OPEN_CHAT_DRAWER                               =   'OPEN_CHAT_DRAWER';
export const CLOSE_CHAT_DRAWER                              =   'CLOSE_CHAT_DRAWER';
export const SET_CHAT_SNAPSHOTS                             =   'SET_CHAT_SNAPSHOTS';
export const SET_USER_ADDRESS_DPTO_NUMBER                   =   'SET_USER_ADDRESS_DPTO_NUMBER';
export const SET_AGENT_PROFILE                              =   'SET_AGENT_PROFILE';
export const SET_AGENT_RATING                               =   'SET_AGENT_RATING';
export const CLEAR_STATE                                    =   'CLEAR_STATE';
export const SET_ORDER_TO_STATE                             =   'SET_ORDER_TO_STATE';
export const SET_INVALID_EMAIL_ASSOCIATED_TO_THE_ORDER      =   'SET_INVALID_EMAIL_ASSOCIATED_TO_THE_ORDER';
export const SHOW_LOGIN_FORM                                =   'SHOW_LOGIN_FORM';
export const HIDE_LOGIN_FORM                                =   'HIDE_LOGIN_FORM';
export const SET_INCLUDED_USER_EMAIL_FLOW                   =   'SET_INCLUDED_USER_EMAIL_FLOW';
export const SET_MAPBOX_MAP_ZOOM                            =   'SET_MAPBOX_MAP_ZOOM';
export const SET_EMAIL_NOT_ALLOWED_TO_CONTINUE              =   'SET_EMAIL_NOT_ALLOWED_TO_CONTINUE';
export const SET_USER_POS_CODE                              =   'SET_USER_POS_CODE';













export const START_NOVA_POST_AUTH_LOGIN_EFFECT              =   'START_NOVA_POST_AUTH_LOGIN_EFFECT';
export const END_NOVA_POST_AUTH_LOGIN_EFFECT                =   'END_NOVA_POST_AUTH_LOGIN_EFFECT';
export const START_NOVA_POST_UNVERIFIED_LOGIN               =   'START_NOVA_POST_UNVERIFIED_LOGIN';
export const END_NOVA_POST_UNVERIFIED_LOGIN                 =   'END_NOVA_POST_UNVERIFIED_LOGIN';
export const START_NOVA_POST_USERS_REGISTRATION_EFFECT      =   'START_NOVA_POST_USERS_REGISTRATION_EFFECT';
export const END_NOVA_POST_USERS_REGISTRATION_EFFECT        =   'END_NOVA_POST_USERS_REGISTRATION_EFFECT';
export const START_NOVA_GET_AUTH_USER_EFFECT                =   'START_NOVA_GET_AUTH_USER_EFFECT';
export const END_NOVA_GET_AUTH_USER_EFFECT                  =   'END_NOVA_GET_AUTH_USER_EFFECT';
export const START_NOVA_GET_USERS_ORDER_EFFECT              =   'START_NOVA_GET_USERS_ORDER_EFFECT';
export const END_NOVA_GET_USERS_ORDER_EFFECT                =   'END_NOVA_GET_USERS_ORDER_EFFECT';
export const START_NOVA_HISTORY_EFFECT                      =   'START_NOVA_HISTORY_EFFECT';
export const END_NOVA_HISTORY_EFFECT                        =   'END_NOVA_HISTORY_EFFECT';
export const START_NOVA_POST_ORDERS_SELFPAY_EFFECT          =   'START_NOVA_POST_ORDERS_SELFPAY_EFFECT';
export const END_NOVA_POST_ORDERS_SELFPAY_EFFECT            =   'END_NOVA_POST_ORDERS_SELFPAY_EFFECT';
export const START_NOVA_GET_MULTICAJA_POINTS_EFFECT         =   'START_NOVA_GET_MULTICAJA_POINTS_EFFECT';
export const END_NOVA_GET_MULTICAJA_POINTS_EFFECT           =   'END_NOVA_GET_MULTICAJA_POINTS_EFFECT';
export const START_NOVA_POST_USERS_ORDERS_EFFECT            =   'START_NOVA_POST_USERS_ORDERS_EFFECT';
export const END_NOVA_POST_USERS_ORDERS_EFFECT              =   'END_NOVA_POST_USERS_ORDERS_EFFECT';
export const START_NOVA_POST_USERS_POS_EFFECT               =   'START_NOVA_POST_USERS_POS_EFFECT';
export const END_NOVA_POST_USERS_POS_EFFECT                 =   'END_NOVA_POST_USERS_POS_EFFECT';
export const START_NOVA_POST_USERS_CANCEL_ORDER_EFFECT      =   'START_NOVA_POST_USERS_CANCEL_ORDER_EFFECT';
export const END_NOVA_POST_USERS_CANCEL_ORDER_EFFECT        =   'END_NOVA_POST_USERS_CANCEL_ORDER_EFFECT';
export const START_NOVA_POST_USERS_RETRY_ORDER_EFFECT       =   'START_NOVA_POST_USERS_RETRY_ORDER_EFFECT';
export const END_NOVA_POST_USERS_RETRY_ORDER_EFFECT         =   'END_NOVA_POST_USERS_RETRY_ORDER_EFFECT';
export const START_NOVA_POST_DELIVERY_INTENT_EFFECT         =   'START_NOVA_POST_DELIVERY_INTENT_EFFECT';
export const END_NOVA_POST_DELIVERY_INTENT_EFFECT           =   'END_NOVA_POST_DELIVERY_INTENT_EFFECT';

export const START_NOVA_GET_USERS_NEARBY_EFFECT             =   'START_NOVA_GET_USERS_NEARBY_EFFECT';
export const END_NOVA_GET_USERS_NEARBY_EFFECT               =   'END_NOVA_GET_USERS_NEARBY_EFFECT';
export const START_NOVA_GET_PROVIDER_POINTS_EFFECT          =   'START_NOVA_GET_PROVIDER_POINTS_EFFECT';
export const END_NOVA_GET_PROVIDER_POINTS_EFFECT            =   'END_NOVA_GET_PROVIDER_POINTS_EFFECT';

export const START_NOVA_PATCH_USERS_ORDERS_ORDERID_RATE     =   'START_NOVA_PATCH_USERS_ORDERS_ORDERID_RATE';
export const END_NOVA_PATCH_USERS_ORDERS_ORDERID_RATE       =   'END_NOVA_PATCH_USERS_ORDERS_ORDERID_RATE';
export const START_NOVA_PATCH_ORDERS_LOCATION_EFFECT        =   'START_NOVA_PATCH_ORDERS_LOCATION_EFFECT';
export const END_NOVA_PATCH_ORDERS_LOCATION_EFFECT          =   'END_NOVA_PATCH_ORDERS_LOCATION_EFFECT';




export const START_NOVA_CANCEL_ORDER_PROCESS                =   'START_NOVA_CANCEL_ORDER_PROCESS';
export const END_NOVA_CANCEL_ORDER_PROCESS                  =   'END_NOVA_CANCEL_ORDER_PROCESS';
export const START_NOVA_RETRY_ORDER_PROCESS                 =   'START_NOVA_RETRY_ORDER_PROCESS';
export const END_NOVA_RETRY_ORDER_PROCESS                   =   'END_NOVA_RETRY_ORDER_PROCESS';

export const START_MAPBOX_REVERSE_GEOCODER_REQUEST          =   'START_MAPBOX_REVERSE_GEOCODER_REQUEST';
export const END_MAPBOX_REVERSE_GEOCODER_REQUEST            =   'END_MAPBOX_REVERSE_GEOCODER_REQUEST';

export const START_NOVA_UPDATE_GOOGLE_AUTOCOMPLETE_VALUE    =   'START_NOVA_UPDATE_GOOGLE_AUTOCOMPLETE_VALUE';
export const END_NOVA_UPDATE_GOOGLE_AUTOCOMPLETE_VALUE      =   'END_NOVA_UPDATE_GOOGLE_AUTOCOMPLETE_VALUE';

export const START_NOVA_UPDATE_CUSTOMER_PIN_COORDS          =   'START_NOVA_UPDATE_CUSTOMER_PIN_COORDS';
export const END_NOVA_UPDATE_CUSTOMER_PIN_COORDS            =   'END_NOVA_UPDATE_CUSTOMER_PIN_COORDS';

export const START_GMAPS_GEOCODER_EFFECT                    =   'START_GMAPS_GEOCODER_EFFECT';
export const END_GMAPS_GEOCODER_EFFECT                      =   'END_GMAPS_GEOCODER_EFFECT';
