import { useContext, useEffect } from 'react';
import { NovaContext } from '../ContextProviders/Nova/NovaContext';
import * as novaActions from '../ContextProviders/Nova/actions';
import { NOVA_SERVER_URL } from '../../constants'

export default function useNovaPostUsersCancelOrderEffect(UID) {
    const {
        state,
        dispatch
    } = useContext(NovaContext);
    const {
        order,
        requestToken,
        startNovaPostUsersCancelOrderEffect,
        //initialUserPosition
    } = state;

    const { return_url } = order;

    useEffect(function novaPostUsersCancelOrderEffect() {
        function dispatchActions(response) {
            const { status } = response;

            //console.log('novaPostUsersCancelOrderEffect respose final: ', response);
            const actions = {
                200: function handle200UsersCancelOrderRequestResponse() {
                    dispatch(novaActions.endNovaPostUsersCancelOrderEffect());
                    //console.log('201 CANCELORDER RESPONSE: ', response);
                },
                410: function handle410UsersCancelOrderRequestResponse() {
                    //dispatch(novaActions.endNovaPostUsersOrdersEffect());
                    //console.log('CANCELORDER 410: ', response);

                },
                400: function handle400UsersCancelOrderRequestResponse() {
                    //dispatch(novaActions.endNovaPostUsersOrdersEffect());
                    //console.log('CANCELORDER 400: ', response);
                }
            };
            return (typeof actions[status] == 'function')
                ?   actions[status]()
                :   console.log('Unable to continue running Nova from novaPostUsersCancelOrderEffect(): ', response);
        }

        const authToken = function authToken(token) {
            return `Token ${token}`
        };

        const usersCancelOrderRequest = async function usersCancelOrderRequest() {
            const usersCancelOrderRequestResponse = await fetch(`${NOVA_SERVER_URL}/users/cancel-order/`, {
                method  : 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Authorization': `${authToken(requestToken)}`
                },
                body: JSON.stringify({
                    order_id: UID
                })
            });
            const { status } = usersCancelOrderRequestResponse;
            return status === 200
                ?   usersCancelOrderRequestResponse.json()
                :   usersCancelOrderRequestResponse;
        };
        if (startNovaPostUsersCancelOrderEffect && requestToken) usersCancelOrderRequest()
            .then(response => {
                const { status } = response;
                return (typeof status === 'undefined')
                    ? dispatchActions({ status: 200, response } )
                    : dispatchActions(response);
            });
    }, [
        requestToken,
        startNovaPostUsersCancelOrderEffect,
        dispatch,
        UID,
        return_url
    ]);
}