import React from 'react';
import PropTypes from 'prop-types';
import AgentSelectionStep from './AgentSelectionStep';
import { makeStyles, createStyles } from '@material-ui/core';
import * as novaActions from '../../../ContextProviders/Nova/actions';
import { NovaContext } from '../../../ContextProviders/Nova/NovaContext';
import Paper from '@material-ui/core/Paper';

import useNovaGetUsersNearbyEffect      from '../../../Hooks/useNovaGetUsersNearby';
import useNovaGetProviderPointsEffect   from '../../../Hooks/useNovaGetProviderPoints';
import useNovaPatchOrdersLocation       from '../../../Hooks/useNovaPatchOrdersLocation';

import CircularProgress from "@material-ui/core/CircularProgress";

const HeadStepper           =   React.lazy(() => import('../../Shared/Stepper/Stepper'));
const OrderStatusWatcher    =   React.lazy(() => import('../../Shared/OrderStatusWatcher/OrderStatusWatcher'));

const useStyles = makeStyles(() => createStyles({
    root: {
        maxWidth: 420,
        margin: '0 auto'
    },
    spinnerContainer: {
        display         : 'flex',
        flexFlow        : 'column nowrap',
        justifyContent  : 'center',
        alignItems      : 'center',
        alignContent    : 'center',
        minHeight          : 640
    }
}));

const AgentSelectionStepContainer = function AgentSelectionStepContainer({ history, match }) {

    const {
        root,
        spinnerContainer
    } = useStyles();

    const {
        state,
        dispatch
    } = React.useContext(NovaContext);

    const {
        selectedAgentType,
        isDeliveryAgentAvailable,
        order: {
            channel_prices,
            price
        },
        staticPointsData,
        //fixedProviderPointsData,
        fixedProviderPointsDataLoaded,
        isSearchingProvider
    } = state;

    const {params: {UID}} = match;
    const txPrice = window.sessionStorage.getItem('txPrice');
    const txStaticPrice = window.sessionStorage.getItem('txStaticPrice');
    const txDeliveryPrice = window.sessionStorage.getItem('txDeliveryPrice');
    const disableButton = !(fixedProviderPointsDataLoaded && (isDeliveryAgentAvailable || staticPointsData)) && !isSearchingProvider;

    const handleSearchAgentButton = function handleSearchAgentButton(event) {
        event.preventDefault();
        return (selectedAgentType === 'default')
            ?
                (
                    history.push(`/${UID}/fixedAgentConnection`)
                )
            :
                (
                    dispatch(novaActions.startNovaPostUsersOrdersEffect()),
                    dispatch(novaActions.startNovaPostDeliveryIntentEffect()),
                    history.push(`/${UID}/deliveryAgentConnection`)
                )
    };
    const handleOnChangeSelector = function handleOnChangeSelector(event) {
        event.preventDefault();
        return dispatch(novaActions.setSelectedAgentType(event.target.value));
    };
    const handleCancelSearchAgentButton = function handleCancelSearchAgentButton(event) {
        event.preventDefault();
        return history.goBack();
    };

    useNovaGetUsersNearbyEffect(UID);
    useNovaGetProviderPointsEffect(UID);
    useNovaPatchOrdersLocation(UID);

    return(
        <Paper
            className={root}
            elevation={6}>
            <OrderStatusWatcher
                UID={UID}
                history={history}>
                <HeadStepper currentStep={2}/>
                {
                    (typeof isDeliveryAgentAvailable !== 'undefined')
                        ?
                            (
                                <AgentSelectionStep
                                    handleSearchAgentButton={handleSearchAgentButton}
                                    handleOnChangeSelector={handleOnChangeSelector}
                                    handleCancelSearchAgentButton={handleCancelSearchAgentButton}
                                    agentIconAltText='Logo Pago46'
                                    mainButtonText='Buscar Socio46'
                                    titleText='Elige un tipo de Socio46'
                                    subtitleText='El Socio46 con el cual conectes recibirá personalmente el dinero del pago.'
                                    labelText='Tipo de Socio46'
                                    fixedAgentTextSelection='Socio46 Fijo'
                                    deliveryAgentTextSelection='Socio46 Delivery'
                                    fixedAgentDescriptionText='Te asignaremos el lugar más cercano donde debes ir a efectuar tu pago.'
                                    fixedAgentRetributionAmont={channel_prices ? channel_prices.static : Number(txStaticPrice)}
                                    deliveryAgentDescriptionText='El Socio46 irá a recibir personalmente el pago a tu dirección.'
                                    deliveryAgentRetributionAmount={channel_prices ? channel_prices.delivery : Number(txDeliveryPrice)}
                                    selectedAgentType={selectedAgentType}
                                    showAgentSelectionSpinner={false}
                                    isDeliveryAgentAvailable={isDeliveryAgentAvailable}
                                    spinnerButtonText='Cancelar búsqueda'
                                    spinnerText='Buscando Socio46...'
                                    price={price ? Number(price) : Number(txPrice)}
                                    disableButton={disableButton}
                                />
                            )
                        :
                            (
                                <section className={spinnerContainer}>
                                    <CircularProgress
                                        style={{
                                            color: '#06BA54'
                                        }}
                                        size={50}
                                        thickness={3}/>
                                </section>
                            )
                }
            </OrderStatusWatcher>
        </Paper>
    );
};

AgentSelectionStepContainer.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};

export default AgentSelectionStepContainer;
